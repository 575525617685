import { Progress as ChakraProgress, ProgressProps, theme } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';

interface ProgressInterface extends ProgressProps {
  currentStep: number;
  totalSteps?: number;
}

const ProgressBar = styled(ChakraProgress)<{ progressColor?: string }>`
  ${({ progressColor }) => css`
    div[role='progressbar'] {
      background-color: ${progressColor ?? 'inherit'};
      transition: 500ms;
    }
  `}
`;

const Progress = (props: ProgressInterface) => {
  const { currentStep, totalSteps = 6, ...restProps } = props;
  const steps = currentStep * (100 / totalSteps);

  return (
    <ProgressBar value={steps} size="sm" progressColor={theme.colors.orange[300]} {...restProps} />
  );
};

export default Progress;
