import { ReactChild } from 'react';
import Slider, { Settings } from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import ArrowButton from './ArrowButton';

interface HorizontalScrollInterface extends Settings {
  children: ReactChild[];
}

const HorizontalScroll = (props: HorizontalScrollInterface) => {
  const { children, ...restProps } = props;
  const settings = {
    className: 'center',
    centerMode: true,
    infinite: false,
    centerPadding: '30px',
    slidesToShow: 1,
    speed: 500,
    nextArrow: <ArrowButton position={15} />,
    prevArrow: <ArrowButton isNext={false} position={15} />,
    responsive: [
      {
        breakpoint: 992, // This setting allow in device size below 992px
        settings: {
          arrows: false,
        },
      },
    ],
  };

  return (
    <Slider {...settings} {...restProps}>
      {children}
    </Slider>
  );
};

export default HorizontalScroll;
