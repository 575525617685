import { Box, Button, Image } from '@chakra-ui/react';

import { Heading3 } from '../../../../Components/Base';
import LandingWrapper from '../../../../Components/LandingWrapper';
import { prefixUrl } from '../../../../Utils/mapAssetUrl';

const DocumentSection = () => {
  return (
    <Box bg={'primary.300'} position={'relative'} overflow={'hidden'} px={8}>
      <LandingWrapper
        display={'flex'}
        flex={1}
        width={'100%'}
        mx={'auto'}
        py={8}
        position={'relative'}
        flexDirection={{ base: 'column', md: 'row' }}
        justifyContent={{ base: 'center', md: 'space-evenly' }}
        alignItems={{ base: 'center', md: 'stretch' }}
      >
        <Box position={'relative'}>
          <Box
            position={'absolute'}
            top={0}
            left={0}
            width={'100%'}
            height={'100%'}
            bg={
              'radial-gradient(84.11% 64.4% at 0% 1.55%, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)'
            }
          />
          <Image
            src={prefixUrl('/images/ebook.jpg')}
            alt={'my-hero-ebook'}
            filter={'drop-shadow(10px 10px 16px rgba(0, 0, 0, 0.24));'}
          />
        </Box>
        <Box width={'100%'} maxWidth={{ md: '400px', lg: '520px' }} mt={8}>
          <Heading3 color={'white'}>
            คู่มือสร้างสุขภาพใจด้วยฮีโร่ในตัวคุณ ภายใต้สถานการณ์โควิด-19
          </Heading3>
          <Box pt={9} />
          <Button
            as={'a'}
            href={prefixUrl('/ebook/hero-book.pdf')}
            target={'_blank'}
            colorScheme={'secondary'}
            color={'white'}
            px={8}
            py={2}
            borderRadius={'100px'}
          >
            Download PDF
          </Button>
        </Box>
      </LandingWrapper>
    </Box>
  );
};

export default DocumentSection;
