import { Button, FormControl, Text } from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { SurveyFormLayout } from '../../../Components/Layout';
import Selection from '../../../Components/Selection';
import { BackgroundStep5 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

import { MY_IDENTITY_CHOICES } from './Utils';

type PersonalInformationType = {
  name?: string;
};

type SelfGoodThingsValueType = {
  myGoodThings: string | string[];
};

const SelfGoodThingsForm = () => {
  const { currentStep, goToNextPage, goToPreviousPage, addMyIdentity, myIdentity } =
    useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const personalInformation = useSurveyAnswerContext()
    .personalInformation as PersonalInformationType;
  const { name } = personalInformation;

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm<SelfGoodThingsValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: myIdentity,
  });

  const handleFormSubmit: SubmitHandler<SelfGoodThingsValueType> = (data: any) => {
    addMyIdentity(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={'สิ่งดีๆ ที่มีในตัวฉัน'}
      subtitle={`อยากให้คุณ${name} เลือกข้อดีของตัวเอง ที่จะช่วยให้คุณทำเป้าหมายที่วางไว้สำเร็จมา 3 ข้อ`}
      navbarProps={{
        title: 'ตัวตนของฉัน',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep5 />}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControl>
          <Controller
            name="myGoodThings"
            control={control}
            rules={{ required: 'กรุณาเลือกสิ่งดีๆ ที่มีในตัวคุณ' }}
            render={({ field: { onChange, value } }) => (
              <Selection
                choices={MY_IDENTITY_CHOICES}
                onAnswerChange={(value) => onChange(value)}
                value={value}
                answerableCount={3}
                selectProps={{ flex: '0 0 auto', py: 1, mr: 3, mb: 4 }}
              />
            )}
          />
        </FormControl>
        {errors.myGoodThings && (
          <Text color={'red.500'} fontSize={'xs'} mt={2}>
            {errors.myGoodThings.message}
          </Text>
        )}

        <Button
          mt={6}
          colorScheme={'primary'}
          isLoading={isSubmitting}
          type="submit"
          width={'100%'}
          borderRadius={'100px'}
          disabled={!isValid}
        >
          ถัดไป
        </Button>
      </form>
    </SurveyFormLayout>
  );
};

export default SelfGoodThingsForm;
