import { Flex, Image, Text } from '@chakra-ui/react';

import { SurveyTopicLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const Topic = () => {
  const { goToNextPage } = useSurveyAnswerContext();
  return (
    <SurveyTopicLayout
      title={`มาเช็คสุขภาพใจ
      กันเถอะ`}
      background={'linear-gradient(0deg, #FFFFFF 16.34%, #DFEEFF 100%)'}
      onStart={goToNextPage}
    >
      <Flex flexDirection={'column'} alignItems={'center'} mt={2}>
        <Image src={prefixUrl('/images/step2-topic.svg')} width={'100%'} />
        <Text
          mt={3}
          fontSize={'lg'}
          fontFamily={'content'}
          textAlign={'center'}
          whiteSpace={'pre-line'}
        >
          {`มาวัดสุขภาพใจของเราก่อนว่า
          เรารู้สึกอย่างไรในช่วงนี้
          เพื่อทำความรู้จักตัวตนเรามากขึ้น`}
        </Text>
      </Flex>
    </SurveyTopicLayout>
  );
};

export default Topic;
