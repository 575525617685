import { useState } from 'react';
import constate from 'constate';

const PAGE_NAMES = {
  // Gather survey info
  P1_1_START: 'P1_1_START',
  P1_2_CONSENT_FORM: 'P1_2_CONSENT_FORM',
  P1_3_FORM_PERSONAL_INFO: 'P1_3_FORM_PERSONAL_INFO',
  P1_4_FORM_OCCUPATION: 'P1_4_FORM_OCCUPATION',
  P1_5_FORM_ROLE: 'P1_5_FORM_ROLE',
  P1_6_FORM_FINANCIAL: 'P1_6_FORM_FINANCIAL',

  // Survey
  P2_1_START: 'P2_1_START',
  P2_2_FORM: 'P2_2_FORM', // P2_2
  P2_3_PRE_RESULTS: 'P2_3_PRE_RESULTS',
  P2_4_RESULTS: 'P2_4_RESULTS',
  P2_5_END: 'P2_5_END',

  // My Idol
  P3_01_START: 'P3_01_START',
  P3_02_VIDEO: 'P3_02_VIDEO', // P3_2
  P3_03_VIDEO_FEEDBACK: 'P3_03_VIDEO_FEEDBACK',

  P3_04_FORM_GOOD_THINGS: 'P3_04_FORM_GOOD_THINGS',
  P3_05_POST_FORM_GOOD_THINGS: 'P3_05_POST_FORM_GOOD_THINGS',

  P3_06_FORM_RECOVERY_PLAN: 'P3_06_FORM_RECOVERY_PLAN',
  P3_07_POST_FORM_RECOVERY_PLAN: 'P3_07_POST_FORM_RECOVERY_PLAN', // P3_7

  P3_08_PRE_FORM_SUPPORTIVE: 'P3_08_PRE_FORM_SUPPORTIVE', //P3_8
  P3_09_FORM_SUPPORTIVE: 'P3_09_FORM_SUPPORTIVE',

  P3_10_PASS_TOGETHER: 'P3_10_PASS_TOGETHER',
  P3_11_END: 'P3_11_END',

  // My Goals
  P4_1_START: 'P4_1_START',
  P4_2_FORM_SMART_GOAL: 'P4_2_FORM_SMART_GOAL', // P4_2
  P4_3_POST_FORM_SMART_GOAL: 'P4_3_POST_FORM_SMART_GOAL', // P4_3

  P4_4_FORM_MEASUREMENT: 'P4_4_FORM_MEASUREMENT', // P4_4
  P4_5_FORM_MEASUREMENT_DURATION: 'P4_5_FORM_MEASUREMENT_DURATION', // P4_5
  P4_6_FORM_MEASUREMENT_CHANCE: 'P4_6_FORM_MEASUREMENT_CHANCE', // P4_6
  P4_7_POST_FORM_MEASUREMENT_CHANCE: 'P4_7_POST_FORM_MEASUREMENT_CHANCE', // P4_7

  P4_8_SUMMARY: 'P4_8_SUMMARY', // P4_8
  P4_9_CONSOLATION: 'P4_9_CONSOLATION', // P4_9
  P4_10_END: 'P4_10_END', // P4_9

  // My Character
  P5_1_START: 'P5_1_START',
  P5_2_FORM_SELF_GOOD_THINGS: 'P5_2_FORM_SELF_GOOD_THINGS', // P5_2
  P5_3_FORM_SUPPORTIVE_FAMILY: 'P5_3_FORM_SUPPORTIVE_FAMILY',
  P5_4_FORM_SUPPORTIVE_WORK: 'P5_4_FORM_SUPPORTIVE_WORK',
  P5_5_FORM_SUPPORTIVE_ORGANIZATION: 'P5_5_FORM_SUPPORTIVE_ORGANIZATION', // P5_5

  P5_6_END: 'P5_6_END', // P5_6

  // My Growth
  P6_01_START: 'P6_01_START',
  P6_02_SUMMARY_I_AM: 'P6_02_SUMMARY_I_AM', // P6_2
  P6_03_SUMMARY_GOOD_THINGS: 'P6_03_SUMMARY_GOOD_THINGS', // P6_3
  P6_04_SUMMARY_MY_ACTIONS: 'P6_04_SUMMARY_MY_ACTIONS', // P6_4

  P6_05_SUMMARY_SUPPORTIVE_FAMILY: 'P6_05_SUMMARY_SUPPORTIVE_FAMILY', // P6_5 ALT
  P6_06_SUMMARY_SUPPORTIVE_WORK: 'P6_06_SUMMARY_SUPPORTIVE_WORK', // P6_6 ALT
  P6_07_SUMMARY_SUPPORTIVE_ORGANIZATION: 'P6_07_SUMMARY_SUPPORTIVE_ORGANIZATION', // P6_7 ALT

  P6_08_SUMMARY_SUPPORTIVE_SELF: 'P6_08_SUMMARY_SUPPORTIVE_SELF', // P6_8 ALT

  P6_09_SUMMARY_SUPPORT_MESSAGE: 'P6_09_SUMMARY_SUPPORT_MESSAGE', // P6_9

  P6_10_SUMMARY_THANK_YOU: 'P6_10_SUMMARY_THANK_YOU', // P6_10

  P7_01_START: 'P7_01_START', // P7_01
  P7_02_FORM_PERSONAL_INFO: 'P7_02_FORM_PERSONAL_INFO', // P7_02
  P7_03_FORM: 'P7_03_FORM', // P7_03
  P7_04_RESULT: 'P7_04_RESULT', // P7_04
  P7_05_THANK_YOU: 'P7_05_THANK_YOU', // P7_05

  ERROR: 'ERROR',
};

const PAGE_LIST = [
  PAGE_NAMES.P1_1_START,
  // PAGE_NAMES.P1_2_CONSENT_FORM,
  PAGE_NAMES.P1_3_FORM_PERSONAL_INFO,
  // PAGE_NAMES.P1_4_FORM_OCCUPATION,
  // PAGE_NAMES.P1_5_FORM_ROLE,
  // PAGE_NAMES.P1_6_FORM_FINANCIAL,

  // Survey
  PAGE_NAMES.P2_1_START,
  PAGE_NAMES.P2_2_FORM,
  PAGE_NAMES.P2_3_PRE_RESULTS,
  PAGE_NAMES.P2_4_RESULTS,
  PAGE_NAMES.P2_5_END,

  // My Idol
  PAGE_NAMES.P3_01_START,
  PAGE_NAMES.P3_02_VIDEO,
  PAGE_NAMES.P3_03_VIDEO_FEEDBACK,

  PAGE_NAMES.P3_04_FORM_GOOD_THINGS,
  PAGE_NAMES.P3_05_POST_FORM_GOOD_THINGS,

  PAGE_NAMES.P3_06_FORM_RECOVERY_PLAN,
  PAGE_NAMES.P3_07_POST_FORM_RECOVERY_PLAN,

  PAGE_NAMES.P3_08_PRE_FORM_SUPPORTIVE,
  PAGE_NAMES.P3_09_FORM_SUPPORTIVE,

  PAGE_NAMES.P3_10_PASS_TOGETHER,
  PAGE_NAMES.P3_11_END,

  // My Goals
  PAGE_NAMES.P4_1_START,
  PAGE_NAMES.P4_2_FORM_SMART_GOAL,
  PAGE_NAMES.P4_3_POST_FORM_SMART_GOAL,

  PAGE_NAMES.P4_4_FORM_MEASUREMENT,
  PAGE_NAMES.P4_5_FORM_MEASUREMENT_DURATION,
  PAGE_NAMES.P4_6_FORM_MEASUREMENT_CHANCE,
  PAGE_NAMES.P4_7_POST_FORM_MEASUREMENT_CHANCE,

  PAGE_NAMES.P4_8_SUMMARY,
  PAGE_NAMES.P4_9_CONSOLATION,
  PAGE_NAMES.P4_10_END,

  // My Character
  PAGE_NAMES.P5_1_START,
  PAGE_NAMES.P5_2_FORM_SELF_GOOD_THINGS,
  PAGE_NAMES.P5_3_FORM_SUPPORTIVE_FAMILY,
  PAGE_NAMES.P5_4_FORM_SUPPORTIVE_WORK,
  PAGE_NAMES.P5_5_FORM_SUPPORTIVE_ORGANIZATION,

  PAGE_NAMES.P5_6_END,

  // My Growth
  PAGE_NAMES.P6_01_START,
  PAGE_NAMES.P6_02_SUMMARY_I_AM,
  PAGE_NAMES.P6_03_SUMMARY_GOOD_THINGS,
  PAGE_NAMES.P6_04_SUMMARY_MY_ACTIONS,

  PAGE_NAMES.P6_05_SUMMARY_SUPPORTIVE_FAMILY,
  PAGE_NAMES.P6_06_SUMMARY_SUPPORTIVE_WORK,
  PAGE_NAMES.P6_07_SUMMARY_SUPPORTIVE_ORGANIZATION,

  PAGE_NAMES.P6_08_SUMMARY_SUPPORTIVE_SELF,

  PAGE_NAMES.P6_09_SUMMARY_SUPPORT_MESSAGE,

  PAGE_NAMES.P6_10_SUMMARY_THANK_YOU,

  // 30 days
  PAGE_NAMES.P7_01_START,
  PAGE_NAMES.P7_02_FORM_PERSONAL_INFO,
  PAGE_NAMES.P7_03_FORM,
  PAGE_NAMES.P7_04_RESULT,
  PAGE_NAMES.P7_05_THANK_YOU,
];

const getNextItemByIndex = (arr, currentIndex) => {
  if (Array.isArray(arr)) {
    if (arr.indexOf(currentIndex) !== arr.length - 1) {
      return arr[currentIndex + 1];
    }
  }

  return PAGE_NAMES.ERROR;
};

const getPrevItemByIndex = (arr, currentIndex) => {
  if (Array.isArray(arr)) {
    if (arr.indexOf(currentIndex) !== arr.length - 1) {
      return arr[currentIndex - 1];
    }
  }
  return PAGE_NAMES.ERROR;
};

const useSurveyAnswer = () => {
  const [currentPage, setCurrentPage] = useState(PAGE_NAMES.P1_1_START);
  const [personalInformation, setPersonalInformation] = useState({});
  const [surveyAnswers, setSurveyAnswers] = useState({});
  const [inspireInformation, setInspireInformation] = useState({});
  const [smartGoalInformation, setSmartGoalInformation] = useState({});
  const [myIdentity, setMyIdentity] = useState({});
  const [survey30DaysAnswers, setSurvey30DaysAnswers] = useState({});

  const goToNextPage = () => {
    // Check supportive message length not equal 0
    const { familySupportive, workSupportive, organizeSupportive } = myIdentity;
    const hasFamilySupportive = familySupportive?.length !== 0;
    const hasWorkSupportive = workSupportive?.length !== 0;
    const hasOrganizeSupportive = organizeSupportive?.length !== 0;

    setCurrentPage((prevPage) => {
      if (prevPage === PAGE_NAMES.P6_04_SUMMARY_MY_ACTIONS) {
        if (!hasFamilySupportive && !hasWorkSupportive && !hasOrganizeSupportive) {
          // check if all SUPPORTIVE are empty
          return PAGE_NAMES.P6_08_SUMMARY_SUPPORTIVE_SELF;
        }
        if (!hasFamilySupportive && !hasWorkSupportive) {
          // check if family and work SUPPORTIVE are empty
          return PAGE_NAMES.P6_07_SUMMARY_SUPPORTIVE_ORGANIZATION;
        }
        if (!hasFamilySupportive) {
          // check if family SUPPORTIVE are empty
          return PAGE_NAMES.P6_06_SUMMARY_SUPPORTIVE_WORK;
        }
      }
      if (prevPage === PAGE_NAMES.P6_05_SUMMARY_SUPPORTIVE_FAMILY) {
        if (!hasWorkSupportive && !hasOrganizeSupportive) {
          // check if work and organize SUPPORTIVE are empty
          return PAGE_NAMES.P6_09_SUMMARY_SUPPORT_MESSAGE;
        }
        if (!hasWorkSupportive) {
          // check if work SUPPORTIVE are empty
          return PAGE_NAMES.P6_07_SUMMARY_SUPPORTIVE_ORGANIZATION;
        }
      }
      if (prevPage === PAGE_NAMES.P6_06_SUMMARY_SUPPORTIVE_WORK) {
        if (!hasOrganizeSupportive) {
          // check if organize SUPPORTIVE are empty
          return PAGE_NAMES.P6_09_SUMMARY_SUPPORT_MESSAGE;
        }
      }

      if (prevPage === PAGE_NAMES.P6_07_SUMMARY_SUPPORTIVE_ORGANIZATION) {
        // skip next step - PAGE_NAMES.P6 SUMMARY_SUPPORTIVE_SELF,
        return PAGE_NAMES.P6_09_SUMMARY_SUPPORT_MESSAGE;
      }

      if (prevPage === PAGE_NAMES.P6_10_SUMMARY_THANK_YOU) {
        // don't change the page
        return PAGE_NAMES.P6_10_SUMMARY_THANK_YOU;
      }
      // just go to next step
      return getNextItemByIndex(PAGE_LIST, PAGE_LIST.indexOf(prevPage));
    });
  };

  const goToPreviousPage = () => {
    setCurrentPage((prevPage) => {
      if (prevPage === PAGE_NAMES.P3_06_FORM_RECOVERY_PLAN) {
        return PAGE_NAMES.P3_04_FORM_GOOD_THINGS;
      }
      if (prevPage === PAGE_NAMES.P4_4_FORM_MEASUREMENT) {
        return PAGE_NAMES.P4_2_FORM_SMART_GOAL;
      }
      return getPrevItemByIndex(PAGE_LIST, PAGE_LIST.indexOf(prevPage));
    });
  };

  const addPersonalInformation = (value) => {
    setPersonalInformation((prevPersonalInformation) => ({ ...prevPersonalInformation, ...value }));
  };

  const addSurveyAnswers = (value) => {
    setSurveyAnswers((prevSurveyAnswers) => ({ ...prevSurveyAnswers, ...value }));
  };

  const addSurvey30DaysAnswers = (value) => {
    setSurvey30DaysAnswers((prevSurvey30DaysAnswers) => ({ ...prevSurvey30DaysAnswers, ...value }));
  };

  const addInspireInformation = (value) => {
    setInspireInformation((prevInspireInformation) => ({ ...prevInspireInformation, ...value }));
  };

  const addSmartGoalInformation = (value) => {
    setSmartGoalInformation((prevSmartGoalInformation) => ({
      ...prevSmartGoalInformation,
      ...value,
    }));
  };

  const addMyIdentity = (value) => {
    setMyIdentity((prevMyIdentity) => ({ ...prevMyIdentity, ...value }));
  };

  const reset = () => {
    setPersonalInformation({});
  };

  // This acts like a selector
  const currentStep = (() => {
    if (currentPage.match(/^P\d_\d/) && currentPage.substring(0, 1) === 'P') {
      return +currentPage.substring(1, 2);
    }
    return currentStep;
  })();

  return {
    currentStep, // for progress UI to display

    currentPage,
    setCurrentPage,
    goToPreviousPage,
    goToNextPage,

    // StEP 1
    personalInformation,
    addPersonalInformation,
    // STEP 2
    surveyAnswers,
    addSurveyAnswers,
    // STEP 3
    inspireInformation,
    addInspireInformation,
    // STEP 4
    smartGoalInformation,
    addSmartGoalInformation,
    // STEP 5
    myIdentity,
    addMyIdentity,

    // STEP 7 30 Days
    survey30DaysAnswers,
    addSurvey30DaysAnswers,

    reset, // might need to reset from after Thank You page
  };
};

const [SurveyAnswerProvider, useSurveyAnswerContext] = constate(useSurveyAnswer);

export { SurveyAnswerProvider, useSurveyAnswerContext, PAGE_NAMES };

export default useSurveyAnswerContext;
