import { Image } from '@chakra-ui/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const SurveyPrepareBackground = () => {
  return (
    <>
      <Image
        src={prefixUrl('/images/prepare-for-smart-goal-background.png')}
        height={'100%'}
        width={'150%'}
        maxWidth={'unset'}
        position={'relative'}
        left={'50%'}
        top={'-20%'}
        transform={'translateX(-50%)'}
      />
      <Image
        src={prefixUrl('/images/human-4.png')}
        width={'unset'}
        position={'absolute'}
        height={'50%'}
        bottom={'10%'}
        left={'50%'}
        transform={'translateX(-50%)'}
      />
    </>
  );
};

export default SurveyPrepareBackground;
