import { Box, Button, Flex, Image } from '@chakra-ui/react';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import MessageBox from '../../../Components/MessageBox';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

import { SummaryFirstHeader, SummarySecondHeader } from './SummarySupportiveHeader';

type MuIdentityType = {
  workSupportive?: string[];
  familySupportive?: string[];
};

const SummaryWorkSupportive = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();
  const myIdentity = useSurveyAnswerContext().myIdentity as MuIdentityType;

  const { familySupportive, workSupportive = [] } = myIdentity;

  const renderSupportive = () => {
    let _message = '';

    workSupportive.map((item) => {
      _message = `${_message} ${item}`;
    });

    return _message.trim();
  };

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'My Growth',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{
        bg: 'linear-gradient(0deg, #C8F9D5 0%, #2B9B8B 100%)',
      }}
    >
      <Flex flexDirection={'column'} pt={10} pb={6} height={'100%'} overflowY={'auto'}>
        {familySupportive?.length === 0 ? (
          <SummaryFirstHeader px={6} />
        ) : (
          <SummarySecondHeader px={6} />
        )}

        <Box flex={'1 0 auto'} px={6} mt={8}>
          <MessageBox
            message={renderSupportive()}
            className={'animate-step-2'}
            subMessage={`ที่อาจกำลังเจอสถานการณ์คล้ายกัน
            จึงเข้าใจ จนเป็นเพื่อนร่วมทุกข์
            ร่วมสุข และไม่ทอดทิ้งกัน`}
            subMessageProps={{
              bg: 'linear-gradient(180deg, #18275E 0%, #2B9B8B 100%)',
            }}
            isLargeMessage
          />
        </Box>

        <Image
          src={prefixUrl('/images/my-growth-5.svg')}
          width={'100%'}
          alignSelf={'center'}
          mt={3}
          className={'animate-step-2'}
        />
        <Box px={6}>
          <Button
            colorScheme="primary"
            width={'100%'}
            borderRadius={'100px'}
            className={'animate-step-2'}
            onClick={goToNextPage}
          >
            ถัดไป
          </Button>
        </Box>
      </Flex>
    </ContentWithNavbarLayout>
  );
};

export default SummaryWorkSupportive;
