import { useEffect } from 'react';

import { QuotesLayout } from '../../../Components/Layout';

import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const PreResult = () => {
  const { goToNextPage } = useSurveyAnswerContext();

  useEffect(() => {
    setTimeout(() => goToNextPage(), 6000);
  }, []);

  return (
    <QuotesLayout
      titleQuote={'ถึงเสียงรอบตัวจะดัง'}
      keyQuote={'แต่ใจเราต้องสงบ'}
      imageUrl={prefixUrl('/images/at-first-quote.gif')}
    />
  );
};

export default PreResult;
