import { useRef, useState } from 'react';
import constate from 'constate';
import { prefixUrl } from '../../Utils/mapAssetUrl';
import { Mute } from '../../Components/Base/Icon';

const TRACK = {
  AT_FIRST_SIGHT: prefixUrl('/audio/at-first-sight.mp3'),
  MY_HEALTH: prefixUrl('/audio/my-health.mp3'),
  MY_CHARACTER: prefixUrl('/audio/my-character.mp3'),
  MY_GOAL: prefixUrl('/audio/my-goal.mp3'),
  MY_GROWTH: prefixUrl('/audio/my-growth.mp3'),
  MY_IDOL: prefixUrl('/audio/my-idol.mp3'),
};

const useSurveyAudio = () => {
  const [track, setTrack] = useState(TRACK.AT_FIRST_SIGHT);
  const [isMuted, setIsMuted] = useState(false);
  const audioRef = useRef<ReturnType<typeof setInterval>>();

  const handleChangeAudio = () => {
    var audio = document.getElementById('audio-player') as HTMLAudioElement;
    var source = document.getElementById('audio-source') as HTMLSourceElement;

    if (typeof audioRef.current === 'number') {
      clearInterval(audioRef.current);
    }

    source.src = track;
    audio.volume = 1;

    audio.load(); //call this to just preload the audio without playing
    audio.play(); //call this to play the song right away
  };

  const handleAudioSound = () => {
    audioRef.current = setInterval(() => {
      var audio = document.getElementById('audio-player') as HTMLAudioElement;
      // Reduce volume by 0.05 as long as it is above 0
      // This works as long as you start with a multiple of 0.05!

      if (audio.volume > 0) {
        audio.volume = audio.volume - 0.05 < 0 ? 0 : audio.volume - 0.05;
      } else {
        // Stop the setInterval when 0 is reached
        if (typeof audioRef.current === 'number') {
          clearInterval(audioRef.current);
        }
      }
    }, 300);
  };

  const handleMuteSound = () => {
    setIsMuted(true);
  };

  const handleUnMuteSound = () => {
    setIsMuted(false);
  };

  return {
    handleChangeAudio,
    handleAudioSound,
    handleMuteSound,
    handleUnMuteSound,
    track,
    setTrack,
    isMuted,
  };
};

const [SurveyAudioProvider, useSurveyAudioContext] = constate(useSurveyAudio);

export { SurveyAudioProvider, useSurveyAudioContext, TRACK };

export default useSurveyAudioContext;
