import { useSurveyAnswerContext, PAGE_NAMES } from '../../Domains/SurveyAnswer/useSurveyContext';
import {
  Topic,
  InspireVideo,
  InspireVideoFeedback,
  GoodThingsForm,
  GoodThingsQuotes,
  RecoveryPlan,
  UseMoreTimeQuotes,
  SupportiveForm,
  SupportScrollCard,
  PassTogetherQuotes,
  PrepareForSmartGoal,
} from './Step3';

const SurveyStep3 = () => {
  const { currentStep, currentPage } = useSurveyAnswerContext();

  if (currentStep !== 3) {
    return null;
  }

  if (currentPage === PAGE_NAMES.P3_01_START) {
    return <Topic />;
  }
  if (currentPage === PAGE_NAMES.P3_02_VIDEO) {
    return <InspireVideo />;
  }
  if (currentPage === PAGE_NAMES.P3_03_VIDEO_FEEDBACK) {
    return <InspireVideoFeedback />;
  }
  if (currentPage === PAGE_NAMES.P3_04_FORM_GOOD_THINGS) {
    return <GoodThingsForm />;
  }
  if (currentPage === PAGE_NAMES.P3_05_POST_FORM_GOOD_THINGS) {
    return <GoodThingsQuotes />;
  }
  if (currentPage === PAGE_NAMES.P3_06_FORM_RECOVERY_PLAN) {
    return <RecoveryPlan />;
  }
  if (currentPage === PAGE_NAMES.P3_07_POST_FORM_RECOVERY_PLAN) {
    return <UseMoreTimeQuotes />;
  }
  if (currentPage === PAGE_NAMES.P3_08_PRE_FORM_SUPPORTIVE) {
    return <SupportScrollCard />;
  }
  if (currentPage === PAGE_NAMES.P3_09_FORM_SUPPORTIVE) {
    return <SupportiveForm />;
  }
  if (currentPage === PAGE_NAMES.P3_10_PASS_TOGETHER) {
    return <PassTogetherQuotes />;
  }
  if (currentPage === PAGE_NAMES.P3_11_END) {
    return <PrepareForSmartGoal />;
  }

  return <div>Step 3 - Wrong currentPage: {currentPage}</div>;
};

export default SurveyStep3;
