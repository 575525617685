import { Flex, Image, Text, Box } from '@chakra-ui/react';
import { Body1, Heading5 } from '../../../Components/Base';

import { SurveyTopicLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const CustomImageBackground = () => (
  <>
    <Image
      src={prefixUrl('/images/light-bg.png')}
      position={'absolute'}
      top={0}
      left={0}
      width={'100%'}
      height={'100%'}
    />
    <Image
      src={prefixUrl('/images/humans.png')}
      position={'absolute'}
      bottom={0}
      left={0}
      width={'100%'}
    />
    <Image
      src={prefixUrl('/images/bubble.png')}
      position={'absolute'}
      top={0}
      right={0}
      width={'90px'}
    />
    <Image
      src={prefixUrl('/images/bubble.png')}
      position={'absolute'}
      top={'115px'}
      left={0}
      transform={'translateX(-60%)'}
      width={'150px'}
    />
    <Image
      src={prefixUrl('/images/bubble.png')}
      position={'absolute'}
      top={'220px'}
      left={0}
      transform={'translateX(-60%)'}
      width={'70px'}
    />
  </>
);

const Topic = () => {
  const { goToNextPage } = useSurveyAnswerContext();
  return (
    <SurveyTopicLayout
      title={`สุขภาพใจ
              คุณยังโอเคไหม?`}
      background={'linear-gradient(0deg, #FFFFFF 16.34%, #FDE9C5 100%)'}
      position={'relative'}
      onStart={goToNextPage}
      titleProps={{ position: 'relative' }}
      childProps={{ flex: '0 0 auto' }}
      customImageBackground={<CustomImageBackground />}
      hasOrganizerSection={true}
    >
      <Flex flexDirection={'column'} alignItems={'center'} mt={2} position={'relative'}>
        <Heading5 textAlign={'center'} color={'text.heading'}>
          มาสำรวจตัวเองกันเถอะ!
        </Heading5>
        <Body1
          textAlign={'center'}
          fontFamily={'content'}
          mt={2}
          color={'text.body1'}
          whiteSpace={'pre-line'}
        >
          {`จากสถานการณ์ปัจจุบันทั้ง โรคระบาด เศรษฐกิจ ที่ส่งผลกระทบกันถ้วนหน้า
          ทำให้ทุกคนมุ่งหาวิธีแก้ปัญหาต่างๆ อย่างจริงจัง จนบางครั้งเราก็ลืมดูแลหัวใจตัวเอง
          `}
        </Body1>
        <Body1
          textAlign={'center'}
          mt={6}
          fontFamily={'content'}
          color={'text.body1'}
          whiteSpace={'pre-line'}
        >
          {`วันนี้อยากให้คุณลองวางงานทุกอย่างสักครู่
          เพื่อมาสำรวจจิตใจของตัวเองสักหน่อย
          เพราะการเอาชนะปัญหาในโลกปัจจุบัน
          แค่มีร่างกายแข็งแรงอย่างเดียวนั้นไม่พอ
          แต่จิตใจต้องแข็งแกร่งตามด้วยนะ`}
        </Body1>
      </Flex>
    </SurveyTopicLayout>
  );
};

export default Topic;
