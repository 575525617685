import { Text, Button, FormControl, FormLabel, FormControlProps } from '@chakra-ui/react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import Selection from '../../../Components/Selection';
import { SurveyFormLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { BackgroundStep1 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const INCOME_CHOICES = [
  { label: 'เพียงพอ', value: 'เพียงพอ' },
  { label: 'ไม่เพียงพอ', value: 'ไม่เพียงพอ' },
];
const SAVING_CHOICES = [
  { label: 'มีเก็บออม', value: 'มีเก็บออม' },
  { label: 'ไม่มีเก็บออม', value: 'ไม่มีเก็บออม' },
];
const DEBT_CHOICES = [
  { label: 'ไม่มีหนี้สิน', value: 'ไม่มีหนี้สิน' },
  { label: 'มีหนี้สิน', value: 'มีหนี้สิน' },
];

type ErrorMessageProps = {
  message?: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Text color={'red.500'} fontSize={'xs'} mt={2}>
    {message}
  </Text>
);

type SelectFormType = {
  label: string;
  requiredMessage: string;
  control: any;
  name: string;
  choices: { label: string; value: string }[];
} & FormControlProps;

const SelectForm = (props: SelectFormType) => {
  const { label, requiredMessage, control, name, choices, ...restProps } = props;
  return (
    <FormControl {...restProps}>
      <FormLabel fontWeight={'semibold'} fontSize={'lg'}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required: requiredMessage }}
        render={({ field: { onChange, value } }) => (
          <Selection
            choices={choices}
            onAnswerChange={(value) => onChange(value)}
            value={value}
            isGridLayout
            gridTemplateColumns={'1fr 1fr'}
            gap={4}
            selectProps={{ flex: 1, py: 1, minHeight: '64px' }}
          />
        )}
      />
    </FormControl>
  );
};

type MyFinanceStatusValueType = {
  incomePerMonthEnough: string;
  savingEnough: string;
  haveDebt: string;
};

const MyFinanceStatus = () => {
  const {
    currentStep,
    personalInformation,
    addPersonalInformation,
    goToNextPage,
    goToPreviousPage,
  } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm<MyFinanceStatusValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: personalInformation,
  });

  const handleFormSubmit: SubmitHandler<MyFinanceStatusValueType> = (data: any) => {
    addPersonalInformation(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={`สภาพการเงินของฉัน
      ในขณะนี้`}
      navbarProps={{
        title: 'แนะนำตัว',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep1 />}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <SelectForm
          name={'incomePerMonthEnough'}
          label={'รายรับเพียงพอกับค่าใช้จ่ายในแต่ละเดือน?'}
          requiredMessage={'กรุณาเลือกคำตอบความเพียงพอของรายได้กัยค่าใช่จ่ายแต่ละเดือนของคุณ'}
          control={control}
          choices={INCOME_CHOICES}
        />
        {errors.incomePerMonthEnough && (
          <ErrorMessage message={errors.incomePerMonthEnough.message} />
        )}

        <SelectForm
          name={'savingEnough'}
          label={'เงินออม?'}
          requiredMessage={'กรุณาเลือกคำตอบเกี่ยวกับเงินออมของคุณ'}
          control={control}
          choices={SAVING_CHOICES}
          mt={6}
        />
        {errors.savingEnough && <ErrorMessage message={errors.savingEnough.message} />}

        <SelectForm
          name={'haveDebt'}
          label={'หนี้สิน?'}
          requiredMessage={'กรุณาเลือกคำตอบเกี่ยวกับหนี้สินของคุณ'}
          control={control}
          choices={DEBT_CHOICES}
          mt={6}
        />
        {errors.haveDebt && <ErrorMessage message={errors.haveDebt.message} />}

        <Button
          mt={6}
          colorScheme={'primary'}
          isLoading={isSubmitting}
          type="submit"
          width={'100%'}
          borderRadius={'100px'}
          disabled={!isValid}
        >
          ถัดไป
        </Button>
      </form>
    </SurveyFormLayout>
  );
};

export default MyFinanceStatus;
