import { useState, useEffect, useMemo } from 'react';
import { Flex, FlexProps, Grid, GridProps, Input as ChakraInput } from '@chakra-ui/react';

import Selectable from '../Selectable';

type SelectionInterface = {
  answerableCount?: number;
  choices: { label: string; value: string }[];
  value?: string | string[];
  onAnswerChange?: (value: string | string[]) => void;
  isGridLayout?: boolean;
  haveOtherChoice?: boolean;
  isOnlyOtherAnswer?: boolean;
  selectProps?: FlexProps;
} & FlexProps &
  GridProps;

type WrapperComponentType = {
  isGridLayout: boolean;
} & FlexProps &
  GridProps;

const Wrapper = (props: WrapperComponentType) => {
  const { isGridLayout, ...restProps } = props;

  if (isGridLayout) {
    return <Grid {...restProps} />;
  }
  return <Flex flexWrap={'wrap'} {...restProps} />;
};

const Selection = (props: SelectionInterface) => {
  const {
    answerableCount = 1,
    choices = [],
    value = [],
    onAnswerChange = () => {},
    selectProps,
    isGridLayout = false,
    haveOtherChoice = false,
    isOnlyOtherAnswer = true,
    ...restProps
  } = props;

  const [isShowOtherInput, setIsShowOtherInput] = useState(false);
  const [otherInput, setOtherInput] = useState<string | undefined>(undefined);

  const valueLastIndex = value.length - 1;
  const hasOtherValue = useMemo(() => {
    if (value.length === 0) {
      return false;
    } else {
      return !choices.some((choice) => choice.value === value[valueLastIndex]);
    }
  }, [value]);

  useEffect(() => {
    if (hasOtherValue) {
      setOtherInput(value[valueLastIndex]);
    }
  }, []);

  useEffect(() => {
    if (otherInput) {
      setIsShowOtherInput(true);
    }
  }, [otherInput]);

  const handleSelectableClick = (e: any) => {
    let _answerList = Array.isArray(value) ? [...value] : [value];
    const newValue: string = e.target.value;

    if (answerableCount === 1) {
      onAnswerChange([newValue]);
    } else {
      // Check other answer field is empty or undefined
      if (isOnlyOtherAnswer || otherInput === undefined || otherInput === '') {
        setIsShowOtherInput(false);
      }
      // Filter other answer out of answer list
      if (hasOtherValue && Array.isArray(value)) {
        _answerList = value.filter((item) => item !== otherInput);
      }
      if (Array.isArray(_answerList) && _answerList.includes(newValue)) {
        const newAnswerList = [..._answerList.filter((value) => value !== newValue)];
        if (otherInput) {
          onAnswerChange([...newAnswerList, otherInput]);
        } else {
          onAnswerChange(newAnswerList);
        }
      } else {
        let newAnswerList = Array.isArray(_answerList)
          ? [..._answerList, newValue]
          : [_answerList, newValue];
        if (newAnswerList.length >= answerableCount) {
          newAnswerList.splice(0, newAnswerList.length - answerableCount);
        }
        if (otherInput) {
          onAnswerChange([...newAnswerList, otherInput]);
        } else {
          onAnswerChange(newAnswerList);
        }
      }
    }
  };

  const handleAnswerListBeforeClickOther = () => {
    if (isOnlyOtherAnswer) {
      if (otherInput) {
        onAnswerChange([otherInput]);
      } else {
        onAnswerChange([]);
      }
    }
  };

  const handleAnswerListAfterFillOther = (answer: string) => {
    // Use case: remove other answer
    if (!answer) {
      if (typeof value === 'string') {
        onAnswerChange('');
      } else {
        onAnswerChange(
          choices.filter((choice) => value.includes(choice.value)).map((choice) => choice.value)
        );
      }
      return;
    }

    // Use case: only other answer value
    if (hasOtherValue && isOnlyOtherAnswer) {
      onAnswerChange([answer]);
      return;
    }

    // Use case: have other answer on list of answer
    if (hasOtherValue) {
      let _answerList = value;
      if (typeof _answerList === 'string') {
        _answerList = answer;
      } else {
        _answerList[valueLastIndex] = answer;
      }
      onAnswerChange(_answerList);
      return;
    }

    // Use case: not have other answer on list of answer
    if (!hasOtherValue) {
      if (typeof value === 'string') {
        onAnswerChange([value, answer]);
      } else {
        onAnswerChange([...value, answer]);
      }
      return;
    }
  };

  return (
    <Wrapper isGridLayout={isGridLayout} {...restProps}>
      {choices.map((choice, index) => (
        <Selectable
          key={index}
          name={'choice'}
          value={choice?.value}
          label={choice?.label}
          checked={value.includes(choice?.value)}
          onChange={handleSelectableClick}
          {...selectProps}
        />
      ))}
      {haveOtherChoice && (
        <>
          {!isShowOtherInput && (
            <Selectable
              name={'choice'}
              value={'other'}
              label={'อื่น ๆ'}
              onChange={() => {
                handleAnswerListBeforeClickOther();
                setIsShowOtherInput(true);
              }}
              {...selectProps}
            />
          )}

          {isShowOtherInput && (
            <ChakraInput
              name={'choice'}
              placeholder={'อื่น ๆ'}
              value={otherInput}
              onChange={(e: any) => {
                const value = e.target.value;
                setOtherInput(value);
                handleAnswerListAfterFillOther(e.target.value);
              }}
              focusBorderColor={'primary.500'}
              gridColumn={isGridLayout ? '1 / -1' : 'unset'} // Expand input full width
              flex={!isGridLayout ? '1' : 'unset'}
              px={4}
              py={2}
              {...selectProps}
            />
          )}
        </>
      )}
    </Wrapper>
  );
};

export default Selection;
