import { Box, Button, Flex, FormControl, Text } from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { SurveyFormLayout } from '../../../Components/Layout';
import Selection from '../../../Components/Selection';
import { BackgroundStep5 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { WORK_CHOICES } from './Utils';

type PersonalInformationType = {
  name?: string;
};

type WorkSupportiveValueType = {
  workSupportive: string | string[];
};

const WorkSupportiveForm = () => {
  const { currentStep, goToNextPage, goToPreviousPage, addMyIdentity } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const personalInformation = useSurveyAnswerContext()
    .personalInformation as PersonalInformationType;
  const myIdentity = useSurveyAnswerContext().myIdentity as WorkSupportiveValueType;
  const { name } = personalInformation;

  const {
    handleSubmit,
    control,
    watch,
    formState: { isSubmitting },
  } = useForm<WorkSupportiveValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      workSupportive: myIdentity.workSupportive ?? [],
    },
  });

  const handleFormSubmit: SubmitHandler<WorkSupportiveValueType> = (data: any) => {
    addMyIdentity(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={`ใครบ้างที่คอยสนับสนุน
      ให้ความช่วยเหลือ`}
      subtitle={`เมื่อคุณ${name}เจอกับปัญหาหรือวิกฤตของชีวิต`}
      navbarProps={{
        title: 'ตัวตนของฉัน',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep5 />}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} style={{ height: '100%' }}>
        <Flex height={'100%'} flexDirection={'column'} flex={1} justifyContent={'space-between'}>
          <Box>
            <FormControl>
              <Text fontWeight={'bold'} fontSize={'xl'}>
                คนที่ทำงาน
              </Text>
              <Text fontSize={'lg'} mt={2}>
                เลือกได้มากกว่า 1 ข้อ
              </Text>
              <Box pt={6} />
              <Controller
                name="workSupportive"
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Selection
                    choices={WORK_CHOICES}
                    onAnswerChange={(value) => onChange(value)}
                    haveOtherChoice
                    isOnlyOtherAnswer={false}
                    value={value}
                    answerableCount={4}
                    selectProps={{ flex: '0 0 auto', minWidth: '85px', py: 1, mr: 3, mb: 4 }}
                  />
                )}
              />
            </FormControl>
          </Box>

          <Button
            mt={6}
            colorScheme={'primary'}
            isLoading={isSubmitting}
            type="submit"
            width={'100%'}
            borderRadius={'100px'}
          >
            ถัดไป
          </Button>
        </Flex>
      </form>
    </SurveyFormLayout>
  );
};

export default WorkSupportiveForm;
