import { Image, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const bounceDown = keyframes`
  0% {
    bottom: -36px;
  }
  50% {
    bottom: -41px;
  }
  100% {
    bottom: -36px
  }
`;

const bounceUp = keyframes`
  0% {
    bottom: -36px;
  }
  50% {
    bottom: -31px;
  }
  100% {
    bottom: -36px
  }
`;

const BackgroundStep1 = () => {
  return (
    <Box background="#FDE9C5">
      <Image
        src={prefixUrl('/images/at-first-sight.svg')}
        height={'100%'}
        maxWidth={'unset'}
        position={'relative'}
        left={'50%'}
        transform={'translateX(-50%)'}
      />
      <Image
        src={prefixUrl('/images/human-3.png')}
        width={'140px'}
        position={'absolute'}
        left={2}
        animation={`${bounceDown} 1s infinite linear`}
      />
      <Image
        src={prefixUrl('/images/human-1.png')}
        width={'170px'}
        position={'absolute'}
        right={0}
        transform={'translateX(10%)'}
        animation={`${bounceDown} 1s infinite linear`}
      />
      <Image
        src={prefixUrl('/images/human-2.png')}
        width={'135px'}
        position={'absolute'}
        left={'50%'}
        transform={'translateX(-50%)'}
        animation={`${bounceUp} 1s infinite linear`}
      />
    </Box>
  );
};

export default BackgroundStep1;
