import { Flex, FlexProps, Text } from '@chakra-ui/react';
import { Heading2, Heading3 } from '../Base';

interface QuotesLayoutInterface extends FlexProps {
  titleQuote: string;
  keyQuote: string;
  imageUrl: string;
  isLightText?: boolean;
}

const QuotesLayout = (props: QuotesLayoutInterface) => {
  const { titleQuote, keyQuote, imageUrl, isLightText, ...restProps } = props;
  return (
    <Flex
      width={'100%'}
      height={'100%'}
      flexDirection={'column'}
      alignItems={'center'}
      backgroundImage={imageUrl}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'bottom'}
      backgroundSize={'cover'}
      pt={28}
      px={6}
      {...restProps}
    >
      <Heading3 textAlign={'center'} color={isLightText ? 'white' : 'inherit'}>
        {titleQuote}
      </Heading3>
      <Heading2 textAlign={'center'} color={isLightText ? 'white' : 'inherit'}>
        {keyQuote}
      </Heading2>
    </Flex>
  );
};

export default QuotesLayout;
