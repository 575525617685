import { Body1, Heading3, Heading5 } from '../../../../Components/Base';
import { Box, BoxProps, Flex } from '@chakra-ui/react';

import HeroCard from './HeroCard';
import LandingWrapper from '../../../../Components/LandingWrapper';
import styled from '@emotion/styled';
import { prefixUrl } from '../../../../Utils/mapAssetUrl';

const HeroCardSpacer = styled((props: BoxProps) => <Box pr={3} pt={6} {...props} />)();

interface WhatIsHeroInterface extends React.FunctionComponent {}

const WhatIsHero: WhatIsHeroInterface = (props) => (
  <Box px={8} pt={20} pb={16} textAlign={'center'} color={'secondary.500'}>
    <Heading3>ให้ HERO ได้ช่วยคุณ</Heading3>
    <Body1 pt={4}>
      HERO เป็นองค์ประกอบของทุนทางจิตวิทยาเชิงบวก <br />
      (Positive Psychological Capital) หรือ PsyCap
      ซึ่งเป็นแนวคิดที่จะช่วยส่งเสริมสุขภาพจิตใจให้ดีขึ้น ประกอบด้วย
    </Body1>
    <Heading5 pt={12}>HERO คืออะไร</Heading5>
    <LandingWrapper
      display={'flex'}
      pt={6}
      flexDirection={{ base: 'column', md: 'row' }}
      color={'secondary.600'}
    >
      <HeroCard
        image={prefixUrl('/images/hope.png')}
        title={'H-Hope'}
        description={`การมีความหวัง มีความมานะอุตสาหะ เพื่อให้ไปถึงเป้าหมายและค้นหาเส้นทางที่
      นำไปสู่เป้าหมายเพื่อความสำเร็จ`}
      />
      <HeroCardSpacer />
      <HeroCard
        image={prefixUrl('/images/efficacy.png')}
        title={'E-Efficacy'}
        description={`การเชื่อมั่นในความสามารถของตัวเอง มีความมั่นใจและพยายามทำสิ่งที่ท้าทายให้สำเร็จ`}
      />
      <HeroCardSpacer />
      <HeroCard
        image={prefixUrl('/images/resilience.png')}
        title={'R-Resilience'}
        description={`ความยืดหยุ่นทางจิตใจเมื่อเจออุปสรรค สามารถยืนหยัดและฟื้นกลับมา จนก้าวข้ามปัญหาไปสู่ความสำเร็จได้`}
      />
      <HeroCardSpacer />
      <HeroCard
        image={prefixUrl('/images/optimism.png')}
        title={'O-Optimism'}
        description={`การมองโลกในแง่ดี สร้างมุมมองทางบวกต่อสถานการณ์ในปัจจุบันและอนาคต`}
      />
    </LandingWrapper>
  </Box>
);

export default WhatIsHero;
