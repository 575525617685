import { Box, Flex, Button, Image, Spacer } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import { Heading3, Heading4 } from '../../../Components/Base';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const ThankYou = () => {
  return (
    <Box
      height={'100%'}
      bg={'linear-gradient(0deg, hsla(199, 100%, 81%, 1) 0%,  hsla(199, 100%, 97%, 1) 100%)'}
    >
      <Flex
        position={'relative'}
        flexDirection={'column'}
        pt={10}
        pb={6}
        height={'100%'}
        overflowY={'auto'}
      >
        <Image
          src={prefixUrl('/images/30-days-thankyou-bg-top.svg')}
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
        />
        <Image
          src={prefixUrl('/images/30-days-thankyou-bg-bottom.svg')}
          position={'absolute'}
          bottom={0}
          right={0}
          width={'100%'}
        />
        <Box position={'relative'}>
          <Heading4 mt={32} textAlign={'center'} px={6}>
            เราบันทึกข้อมูลของคุณในระบบแล้ว
          </Heading4>
          <Heading3 textAlign={'center'} px={6}>
            ขอบคุณที่ร่วมกิจกรรม
          </Heading3>
        </Box>
        <Spacer />
        <Box px={6}>
          <Link to={'/'} target={'_blank'}>
            <Button colorScheme="primary" width={'100%'} borderRadius={'100px'}>
              เข้าชมเว็บไซต์
            </Button>
          </Link>
        </Box>
      </Flex>
    </Box>
  );
};

export default ThankYou;
