import { css, Global, keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(10%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const GlobalStyles = () => {
  return (
    <Global
      styles={css`
        @font-face {
          font-family: 'Anuphan';
          font-weight: 300;
          font-display: swap;
          src: url(${prefixUrl('/fonts/Anuphan/Anuphan-Light.woff2')}) format('woff2');
          src: url(${prefixUrl('/fonts/Anuphan/Anuphan-Light.woff2')}) format('woff2'),
            url(${prefixUrl('/fonts/Anuphan/Anuphan-Light.woff')}) format('woff');
        }

        @font-face {
          font-family: 'Anuphan';
          font-weight: 400;
          font-display: swap;
          src: url(${prefixUrl('/fonts/Anuphan/Anuphan-Regular.woff2')}) format('woff2');
          src: url(${prefixUrl('/fonts/Anuphan/Anuphan-Regular.woff2')}) format('woff2'),
            url(${prefixUrl('/fonts/Anuphan/Anuphan-Regular.woff')}) format('woff');
        }

        @font-face {
          font-family: 'Anuphan';
          font-display: swap;
          font-weight: 500;
          src: url(${prefixUrl('/fonts/Anuphan/Anuphan-Medium.woff2')}) format('woff2');
          src: url(${prefixUrl('/fonts/Anuphan/Anuphan-Medium.woff2')}) format('woff2'),
            url(${prefixUrl('/fonts/Anuphan/Anuphan-Medium.woff')}) format('woff');
        }

        @font-face {
          font-family: 'Anuphan';
          font-weight: 600;
          font-display: swap;
          src: url(${prefixUrl('/fonts/Anuphan/Anuphan-SemiBold.woff2')}) format('woff2');
          src: url(${prefixUrl('/fonts/Anuphan/Anuphan-SemiBold.woff2')}) format('woff2'),
            url(${prefixUrl('/fonts/Anuphan/Anuphan-SemiBold.woff')}) format('woff');
        }

        html,
        body {
          font-family: 'Anuphan', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
            'Segoe UI Symbol', 'Noto Color Emoji';
          color: hsl(0, 0%, 26%);
        }

        .animate-step-1 {
          animation: ${fadeIn} 1.5s ease-in 0s both;
        }

        .animate-step-2 {
          animation: ${fadeIn} 1.5s ease-in 1.5s both;
        }

        .animate-step-3 {
          animation: ${fadeIn} 1.5s ease-in 3s both;
        }

        .animate-step-4 {
          animation: ${fadeIn} 1.5s ease-in 4.5s both;
        }
      `}
    />
  );
};

export default GlobalStyles;
