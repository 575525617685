import React from 'react';
import { Flex, Text, Checkbox, FlexProps } from '@chakra-ui/react';

interface SelectableInterface {
  checked?: boolean;
  name: string;
  label: string;
  disabled?: boolean;
  value: string;
  onChange?: (e: any) => void;
}

type SelectableWrapperProps = {
  checked: boolean;
  children: React.ReactChild[];
} & FlexProps;

const SelectableWrapper = (props: SelectableWrapperProps) => {
  const { children, checked, ...restProps } = props;

  return (
    <Flex
      as="label"
      justifyContent={'center'}
      alignItems={'center'}
      px={4}
      py={2}
      cursor={'pointer'}
      border={1}
      borderStyle={'solid'}
      borderColor={checked ? 'orange.300' : 'gray.300'}
      borderRadius={'lg'}
      backgroundColor={checked ? 'orange.50' : 'white'}
      {...restProps}
    >
      {children}
    </Flex>
  );
};

const Selectable = (props: SelectableInterface) => {
  const {
    checked = false,
    name,
    label,
    disabled = false,
    value,
    onChange = () => {},
    ...restProps
  } = props;

  return (
    <SelectableWrapper checked={checked} {...restProps}>
      <Text fontSize={'lg'} textAlign={'center'} whiteSpace={'pre-line'}>
        {label}
      </Text>
      <Checkbox
        type={'checkbox'}
        isChecked={checked}
        name={name}
        onChange={onChange}
        value={value}
        disabled={disabled}
        display={'none'}
        __css={{
          WebkitAppearance: 'none',
          MozAppearance: 'none',
          appearance: 'none',
        }}
      />
    </SelectableWrapper>
  );
};

export default Selectable;
