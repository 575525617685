import { useSurveyAnswerContext, PAGE_NAMES } from '../../Domains/SurveyAnswer/useSurveyContext';
import {
  SummaryFamilySupportive,
  SummaryGoodThings,
  SummaryIAm,
  SummaryMessageSupport,
  SummaryMyActions,
  SummaryOrganizeSupportive,
  SummarySelfSupportive,
  SummaryWorkSupportive,
  ThankYou,
  Topic,
} from './Step6';

const SurveyStep6 = () => {
  const { currentStep, currentPage } = useSurveyAnswerContext();

  if (currentStep !== 6) {
    return null;
  }

  // TODO: replace with real page logic
  if (currentPage === PAGE_NAMES.P6_01_START) {
    return <Topic />;
  }
  if (currentPage === PAGE_NAMES.P6_02_SUMMARY_I_AM) {
    return <SummaryIAm />;
  }
  if (currentPage === PAGE_NAMES.P6_03_SUMMARY_GOOD_THINGS) {
    return <SummaryGoodThings />;
  }
  if (currentPage === PAGE_NAMES.P6_04_SUMMARY_MY_ACTIONS) {
    return <SummaryMyActions />;
  }
  if (currentPage === PAGE_NAMES.P6_05_SUMMARY_SUPPORTIVE_FAMILY) {
    return <SummaryFamilySupportive />;
  }
  if (currentPage === PAGE_NAMES.P6_06_SUMMARY_SUPPORTIVE_WORK) {
    return <SummaryWorkSupportive />;
  }
  if (currentPage === PAGE_NAMES.P6_07_SUMMARY_SUPPORTIVE_ORGANIZATION) {
    return <SummaryOrganizeSupportive />;
  }
  if (currentPage === PAGE_NAMES.P6_08_SUMMARY_SUPPORTIVE_SELF) {
    return <SummarySelfSupportive />;
  }
  if (currentPage === PAGE_NAMES.P6_09_SUMMARY_SUPPORT_MESSAGE) {
    return <SummaryMessageSupport />;
  }
  if (currentPage === PAGE_NAMES.P6_10_SUMMARY_THANK_YOU) {
    return <ThankYou />;
  }

  return <div>Step 6 - Wrong currentPage: {currentPage}</div>;
};

export default SurveyStep6;
