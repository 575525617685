import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { Heading3 } from '../../../Components/Base';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import MessageBox from '../../../Components/MessageBox';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { useStoreData } from '../../../Domains/useStoreData';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

type SmartGoalInformationType = {
  smartGoal?: string;
  measure?: string;
  measureDuration?: string;
};

const SummaryMessageSupport = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();
  const smartGoalInformation = useSurveyAnswerContext()
    .smartGoalInformation as SmartGoalInformationType;
  const { onStoreData } = useStoreData();
  const { smartGoal = '', measure = '', measureDuration = '' } = smartGoalInformation;

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'My Growth',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{
        bg: 'linear-gradient(0deg, #FFEAB6 0%, #EE8C1A 100%)',
      }}
    >
      <Flex flexDirection={'column'} pt={10} pb={6} px={6} height={'100%'} overflowY={'auto'}>
        <Heading3 color={'white'} className={'animate-step-1'}>
          เป้าหมายของฉันคือ
        </Heading3>

        <Box flex={'1 0 auto'} mt={8}>
          <MessageBox
            message={`${smartGoal} โดยวัดจาก ${measure} และจะสามารถทำได้ภายใน ${measureDuration}`}
            className={'animate-step-3'}
            subMessage={`สำเร็จหรือไม่ ก็ไม่เป็นไร
            อย่าเพิ่งท้อล่ะ`}
            subMessageProps={{
              bg: 'linear-gradient(180deg, #18275E 0%, #D68000 100%)',
            }}
          />
        </Box>

        <Image
          src={prefixUrl('/images/colosseum.svg')}
          width={'100%'}
          alignSelf={'center'}
          mt={14}
          className={'animate-step-3'}
        />

        <Button
          mt={6}
          colorScheme="primary"
          width={'100%'}
          flex={'0 0 auto'}
          borderRadius={'100px'}
          className={'animate-step-3'}
          onClick={() => {
            onStoreData();
            goToNextPage();
          }}
        >
          ถัดไป
        </Button>
      </Flex>
    </ContentWithNavbarLayout>
  );
};

export default SummaryMessageSupport;
