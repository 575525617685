import { useMemo } from 'react';
import { Flex } from '@chakra-ui/react';

import { SurveyFormLayout, SUBTITLE_POSITION } from '../../../Components/Layout';
import Selection from '../../../Components/Selection';
import { BackgroundStep4 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { SmartGoalValueType } from './SmartGoalForm';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const MEASURE_DURATION = [
  { label: 'น้อยกว่า 6 เดือน', value: 'น้อยกว่า 6 เดือน' },
  { label: '6 เดือน - 1 ปี', value: '6 เดือน - 1 ปี' },
  { label: '1 - 2 ปี', value: '1-2 ปี' },
];

type SmartGoalInformationType = {
  measureDuration: string;
};

const MeasurementDurationForm = () => {
  const {
    currentStep,
    goToNextPage,
    goToPreviousPage,
    smartGoalInformation: smartGoal,
    addSmartGoalInformation,
  } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const smartGoalInformation = smartGoal as SmartGoalInformationType;

  const smartGoalValue = smartGoal as SmartGoalValueType;
  const smartGoal100Chars = useMemo(() => {
    let _smartGoal: string = smartGoalValue.smartGoal;
    if (_smartGoal.length <= 100) {
      return _smartGoal;
    }
    return `${_smartGoal.substring(0, 100)}...`;
  }, [smartGoalValue]);

  const measureDuration = useMemo(
    () => smartGoalInformation.measureDuration,
    [smartGoalInformation.measureDuration]
  );

  const handleFormSubmit = (value: string | string[]) => {
    addSmartGoalInformation({ measureDuration: value });
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={'น่าจะใช้เวลานานแค่ไหน'}
      subtitle={`เป้าหมายของฉัน
      “${smartGoal100Chars}”`}
      subtitlePosition={SUBTITLE_POSITION.TOP}
      navbarProps={{
        title: 'SMART Goal',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep4 />}
    >
      <Flex flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
        <Selection
          choices={MEASURE_DURATION}
          value={measureDuration}
          onAnswerChange={(value) => handleFormSubmit(value)}
          isGridLayout
          gridTemplateColumns={'1fr'}
          gap={4}
          selectProps={{ flex: 1, py: 1, minHeight: '64px' }}
        />
      </Flex>
    </SurveyFormLayout>
  );
};

export default MeasurementDurationForm;
