import * as React from 'react';

const Mute = (props: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={32} height={32} fill="none" {...props}>
      <path
        d="M15.887 6.38c.382-.34.67-.49 1.117-.29.447.198.512.477.552.977 0 .214.02.41.02 1.008V23.81c0 .599 0 .797-.02 1.011-.04.497-.133.785-.543.975-.41.189-.744.056-1.123-.283-.158-.14-.31-.31-.673-.686l-5.003-4.384-.053-.053a.589.589 0 00-.418-.183H7.387c-.673 0-.816 0-1.098-.31C6.006 19.585 6 19.5 6 18.808v-5.691c0-.717 0-.82.27-1.105a.98.98 0 01.64-.31c.152-.009.306-.009.459 0h2.374a.568.568 0 00.418-.183l.053-.053 5.003-4.397c.369-.397.515-.55.67-.69zm-4.69 6.151a2.027 2.027 0 01-1.454.621H7.455v5.601h2.288a2.036 2.036 0 011.455.621l4.922 4.31V8.213l-4.922 4.32zM20.675 19.641l-.437-.431c-.31-.258-.037-.5.062-.62a4.165 4.165 0 00.071-5.031c-.124-.168-.372-.518-.04-.838l.31-.31a.446.446 0 01.714.021 5.623 5.623 0 01-.025 7.2c-.1.115-.285.31-.642 0l-.013.01z"
        fill="#ED5656"
      />
      <path
        d="M23.027 22.052l-.356-.36c-.382-.341-.103-.57 0-.695a7.535 7.535 0 00.024-9.93c-.117-.137-.4-.385-.052-.72l.332-.332c.385-.345.62-.134.726 0a9 9 0 010 12.015c-.109.125-.31.36-.677.022h.003z"
        fill="#ED5656"
      />
      <path stroke="#ED5656" strokeWidth={1.5} strokeLinecap="round" d="M7.061 7L25 24.939" />
    </svg>
  );
};

export default Mute;
