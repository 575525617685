import { ReactChild } from 'react';
import { Box as ChakraBox, BoxProps } from '@chakra-ui/react';
import styled from '@emotion/styled';

const Box = styled(ChakraBox)<{ ratio: number }>`
  position: relative;

  &::before {
    content: '';
    height: 0;
    display: block;
    padding-bottom: ${({ ratio }) => `calc(100% / ${ratio})`};
  }
  & > * {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  & > img,
  & > video {
    object-fit: cover;
  }
`;

interface AspectRatioInterface extends BoxProps {
  ratio?: number;
  children: ReactChild;
}

const AspectRatio = (props: AspectRatioInterface) => {
  const { ratio = 16 / 9, children, ...restProps } = props;
  return (
    <Box ratio={ratio} {...restProps}>
      {children}
    </Box>
  );
};

export default AspectRatio;
