import { Box, Button, Flex, Grid, Icon, Image, Text } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { BsPhone } from 'react-icons/bs';
import { AspectRatio, Heading3 } from '../../../Components/Base';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import VideoPlayer from '../../../Components/VideoPlayer';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const rotate = keyframes`
  from {
    transform: rotate(0deg)
  }
  to {
    transform: rotate(90deg)
  }
`;

const InspireVideo = () => {
  const { currentStep, goToNextPage, goToPreviousPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'สร้างแรงบันดาลใจ',
        currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Flex
        position={'relative'}
        bg={'hsl(253, 100%, 95%)'}
        px={6}
        py={8}
        minHeight={'350px'}
        flex={'0 0 auto'}
        overflowX={'hidden'}
      >
        <Image
          src={prefixUrl('/images/bubble.png')}
          position={'absolute'}
          top={0}
          right={0}
          width={'110px'}
          transform={'translate(40%, -30%)'}
        />
        <Grid position={'relative'} gridTemplateRows={'max-content auto'}>
          <Heading3 color={'text.heading'}>{`ร่วมสร้างแรงบันดาลใจไปกับเรา`}</Heading3>
          <AspectRatio mt={6} width={'100%'}>
            <VideoPlayer src={'https://youtu.be/uFFvgN3PjUI'} width={'100%'} height={'100%'} />
          </AspectRatio>
        </Grid>
      </Flex>
      <Box py={5} px={6} flex={'1 0 auto'}>
        <Flex height={'100%'} flexDirection={'column'} flex={1} justifyContent={'space-between'}>
          <Text fontFamily={'content'} fontSize={'lg'}>
            เจนนี่ ไกด์ทัวร์อิสระ ที่ได้รับผลกระทบจาก สถานการณ์โควิด ทำให้งานของเธอต้องหยุดไป
            แต่เธอก็ได้เปลี่ยนแนวคิด ได้สู้ ทำในสิ่งต่างๆ ที่ไม่เคยทำ จนเธอผ่านวิกฤตนี้ไปได้
          </Text>
          <Box>
            <Flex alignItems={'center'} mt={4}>
              <Icon
                as={BsPhone}
                width={8}
                height={'auto'}
                animation={`${rotate} 3s ease-in-out infinite`}
              />
              <Box pl={4} />
              <Text fontSize={'lg'}>เพื่ออรรถรสในการชม เราอยากให้คุณปรับมือถือเป็นแนวนอน</Text>
            </Flex>
            <Button
              mt={6}
              colorScheme={'primary'}
              width={'100%'}
              borderRadius={'100px'}
              onClick={goToNextPage}
            >
              ถัดไป
            </Button>
          </Box>
        </Flex>
      </Box>
    </ContentWithNavbarLayout>
  );
};

export default InspireVideo;
