import { Image, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const cloudMoveBeginLeft = keyframes`
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(50%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const cloudMoveBeginRight = keyframes`
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-50%);
  }
  100% {
    transform: translateX(0%);
  }
`;

const Survey30DaysResultBackground = () => {
  return (
    <Box
      background={'linear-gradient(180deg, #E0EFFF 65.58%, #FFFDF9 95.14%)'}
      height={'100%'}
      width={'100%'}
    >
      <Image
        src={prefixUrl('/images/step7-form-result-background.svg')}
        height={'100%'}
        maxWidth={'unset'}
        position={'relative'}
        left={'50%'}
        transform={'translateX(-50%)'}
      />
      <Image
        src={prefixUrl('/images/cloud-3.png')}
        width={'104px'}
        position={'absolute'}
        top={'45%'}
        left={'10%'}
        animation={`${cloudMoveBeginLeft} 5s infinite linear`}
      />
      <Image
        src={prefixUrl('/images/cloud-2.png')}
        width={'145px'}
        position={'absolute'}
        top={'10%'}
        left={'0%'}
        animation={`${cloudMoveBeginRight} 7s infinite linear`}
      />
      <Image
        src={prefixUrl('/images/cloud-2.png')}
        width={'145px'}
        position={'absolute'}
        top={'20%'}
        right={{ base: '0%', md: '-30%' }}
        animation={`${cloudMoveBeginRight} 6s infinite linear`}
      />
    </Box>
  );
};

export default Survey30DaysResultBackground;
