import { Box, Flex, Text, Radio, RadioGroup, Stack, RadioGroupProps } from '@chakra-ui/react';

interface LinearScaleInterface {
  numberOfChoices?: number;
  minText?: string;
  maxText?: string;
  onChange?: (event: any) => void;
  value?: string | number;
}

const handleScaleColor = (value: string) => {
  switch (value) {
    case '1': {
      return 'hsl(0, 82%, 89%)';
    }
    case '2': {
      return 'hsl(0, 79%, 93%)';
    }
    case '3': {
      return 'hsl(0, 79%, 96%)';
    }
    case '4': {
      return 'hsl(170, 32%, 93%)';
    }
    case '5': {
      return 'hsl(170, 32%, 85%)';
    }
    case '6': {
      return 'hsl(170, 32%, 77%)';
    }
  }
};

const LinearScale = (props: LinearScaleInterface) => {
  const {
    numberOfChoices = 6,
    value,
    onChange = () => {},
    minText = 'ไม่จริงเลย',
    maxText = 'จริงที่สุด',
    ...restProps
  } = props;
  return (
    <Box>
      <RadioGroup pt={8} onChange={onChange} value={value} {...restProps}>
        <Stack
          justifyContent={'space-between'}
          spacing={4}
          direction="row"
          position={'relative'}
          _before={{
            content: `""`, // Ref: Resolved issue content in _before props: https://github.com/chakra-ui/chakra-ui/issues/561
            bg: 'gray.100',
            height: 'px',
            position: 'absolute',
            width: `calc(100% - 20px)`,
            maxWidth: '100%',
            transform: 'translate(-50%, -50%)',
            left: '50%',
            top: '50%',
          }}
        >
          {Array.from({ length: numberOfChoices }).map((item, index) => {
            const value = `${index + 1}`;
            const isScaleBelowMiddlePoint = index < numberOfChoices / 2;

            const backgroundColor = handleScaleColor(value);

            return (
              <Flex
                as={'label'}
                key={index}
                flexDirection={'column'}
                alignItems={'center'}
                cursor={'pointer'}
                userSelect={'none'}
              >
                <Text position={'absolute'} transform={'translateY(-100%)'}>
                  {value}
                </Text>
                <Radio
                  value={value}
                  bg={backgroundColor}
                  borderColor={backgroundColor}
                  width={'24px'}
                  height={'24px'}
                  alignItems={'center'}
                  justifyContent={'center'}
                  size={'lg'}
                  zIndex={1}
                  _checked={{
                    bg: isScaleBelowMiddlePoint ? 'hsl(0, 81%, 63%)' : 'hsl(171, 95%, 25%)',
                    borderColor: isScaleBelowMiddlePoint
                      ? 'hsl(0, 81%, 63%)'
                      : 'hsl(171, 95%, 25%)',
                    width: '24px',
                    height: '24px',
                    transition: '0.2s',
                  }}
                  _focus={{
                    border: 'none',
                  }}
                />
              </Flex>
            );
          })}
        </Stack>
      </RadioGroup>
      <Flex justifyContent={'space-between'} mt={2}>
        <Text>{minText}</Text>
        <Text>{maxText}</Text>
      </Flex>
    </Box>
  );
};

export default LinearScale;
