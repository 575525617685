import { Box } from '@chakra-ui/react';
import Footer from '../../Components/Footer';
import DocumentSection from './Components/DocumentSection';
import HeroSection from './Components/HeroSection';
import InspirationSection from './Components/InspirationSection';
import TellingSection from './Components/TellingSection';
import WhatIsHero from './Components/WhatIsHero';
import { useLocation, Redirect } from 'react-router-dom';

interface HomeInterface extends React.FunctionComponent {}

const Home: HomeInterface = (props) => {
  const queryString = new URLSearchParams(useLocation().search);
  const survey30Days = Number(queryString.get('30days'));

  if (survey30Days === 1) {
    return <Redirect to="/survey-30-days" />;
  }

  return (
    <Box>
      <HeroSection />
      <WhatIsHero />
      <TellingSection id={'smartgoal'} />
      <InspirationSection />
      <DocumentSection />
      <Footer />
    </Box>
  );
};

export default Home;
