import { Flex, Text, Box, BoxProps, Icon } from '@chakra-ui/react';
import { MdKeyboardArrowLeft } from 'react-icons/md';
import { Mute, Speaker } from '../Base/Icon';

import { Progress } from '../Base';

export interface NavbarInterface extends BoxProps {
  title?: string;
  isMuted: boolean;
  currentStep?: number;
  totalSteps?: number;
  onBackClick?: () => void;
  onMuteSound?: () => void;
  onUnMuteSound?: () => void;
}

const Navbar = (props: NavbarInterface) => {
  const {
    title,
    totalSteps,
    currentStep = 0,
    onBackClick,
    isMuted,
    onMuteSound,
    onUnMuteSound,
    ...restProps
  } = props;

  return (
    <Box bg={'white'} {...restProps}>
      <Flex position={'relative'} alignItems={'center'} px={4} height={'48px'}>
        {typeof onBackClick === 'function' && (
          <Flex alignItems={'center'} onClick={onBackClick}>
            <Icon as={MdKeyboardArrowLeft} width={6} height={6} mt={0.5} />
            <Text ml={2}>กลับ</Text>
          </Flex>
        )}
        <Text
          position={'absolute'}
          top={'50%'}
          left={'50%'}
          transform={'translate(-50%, -50%)'}
          width={'15ch'}
          textOverflow={'ellipsis'}
          whiteSpace={'nowrap'}
          overflow={'hidden'}
          textAlign={'center'}
          fontWeight={'bold'}
        >
          {title}
        </Text>
        {typeof onMuteSound === 'function' && typeof onUnMuteSound === 'function' && (
          <Icon
            as={isMuted ? Mute : Speaker}
            width={'36px'}
            height={'25px'}
            ml={'auto'}
            onClick={isMuted ? onUnMuteSound : onMuteSound}
          />
        )}
      </Flex>
      <Progress totalSteps={totalSteps} currentStep={currentStep} />
    </Box>
  );
};

export default Navbar;
