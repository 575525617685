import { Box, BoxProps, Text, TextProps } from '@chakra-ui/react';

import { Heading3 } from '../../../Components/Base';

export const SummaryFirstHeader = (props: BoxProps) => (
  <Box {...props}>
    <Text fontSize={'2xl'} fontWeight={'semibold'} color={'white'} className={'animate-step-1'}>
      ฉันเชื่อว่าจะ
    </Text>
    <Heading3 color={'white'} mt={2} className={'animate-step-2'}>
      จะทำสำเร็จได้
    </Heading3>
    <Text
      mt={2}
      fontSize={'2xl'}
      fontWeight={'semibold'}
      color={'white'}
      className={'animate-step-2'}
    >
      แม้บางครั้งจะยาก แต่ฉันยังมี
    </Text>
  </Box>
);

export const SummarySecondHeader = (props: TextProps) => (
  <Heading3 color={'white'} className={'animate-step-1'} {...props}>
    และฉันยังมี
  </Heading3>
);

export const SummaryThirdHeader = (props: TextProps) => (
  <Heading3 color={'white'} className={'animate-step-1'} {...props}>
    รวมถึง
  </Heading3>
);
