import { Image, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const bounce = keyframes`
  0% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-15px);
  }
  100% {
    transform: translateY(0px);
  }
`;

const BackgroundStep3 = () => {
  return (
    <Box background={'#ECE7FF'} height={'100%'} width={'100%'}>
      <Image
        src={prefixUrl('/images/step3-my-idol.svg')}
        height={'100%'}
        maxWidth={'unset'}
        position={'relative'}
        left={'50%'}
        transform={'translateX(-50%)'}
      />
      <Image
        src={prefixUrl('/images/cover-my-idol.svg')}
        position={'absolute'}
        bottom={-5}
        right={-3}
        width={'224px'}
      />
      <Image
        src={prefixUrl('/images/heart-ball-1.png')}
        width={'100px'}
        position={'absolute'}
        bottom={9}
        right={20}
        animation={`${bounce} 1s infinite cubic-bezier(.17,.67,.83,.67)`}
      />
    </Box>
  );
};

export default BackgroundStep3;
