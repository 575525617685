import { Button } from '@chakra-ui/button';
import { Box, Flex, Text } from '@chakra-ui/layout';
import { ContentWithNavbarLayout } from '../../../Components/Layout';
import SurveyPrepareBackground from '../../../Components/SurveyBackgroundImage/SurveyPrepareBackground';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import useSurveyAudioContext from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const PrepareForMyCharacter = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'SMART GOAL',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Flex
        position={'relative'}
        bg={'#fff'}
        alignItems={'center'}
        minHeight={'100%'}
        flex={'0 0 auto'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        px={6}
        pt={12}
        pb={5}
      >
        <Box
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
          overflow={'hidden'}
        >
          <SurveyPrepareBackground />
        </Box>
        <Flex flexDirection={'column'} alignItems={'center'} position={'relative'}>
          <Text
            as={'h4'}
            fontSize={'2xl'}
            color={'#18275E'}
            textAlign={'center'}
            fontWeight={'semibold'}
          >
            เป้าหมายจะ SMART
          </Text>
          <Text
            as={'h3'}
            fontSize={'3xl'}
            color={'#18275E'}
            textAlign={'center'}
            fontWeight={'semibold'}
            whiteSpace={'pre-line'}
          >
            {`ถ้าเรารู้จัก
            ตัวเองมากยิ่งขึ้น`}
          </Text>
        </Flex>
        <Box px={3} width={'100%'}>
          <Button
            colorScheme={'primary'}
            type="submit"
            width={'100%'}
            borderRadius={'100px'}
            onClick={goToNextPage}
          >
            ถัดไป
          </Button>
        </Box>
      </Flex>
    </ContentWithNavbarLayout>
  );
};

export default PrepareForMyCharacter;
