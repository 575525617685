import { ReactChild, ReactNode } from 'react';
import { Box, BoxProps, Grid, Text } from '@chakra-ui/react';

import ContentWithNavbarLayout, {
  ContentWithNavbarLayoutInterface,
} from './ContentWithNavbarLayout';
import { Body1, Heading3, Heading4, Heading5 } from '../Base';

export enum SUBTITLE_POSITION {
  TOP = 'TOP',
  BOTTOM = 'BOTTOM',
}

interface SurveyFormLayoutInterface extends ContentWithNavbarLayoutInterface {
  title: string;
  subtitle?: string;
  description?: string;
  imageBackground?: ReactNode | ReactNode[];
  subtitlePosition?: SUBTITLE_POSITION;
  children: ReactChild | ReactChild[];
  childProps?: BoxProps;
}

const SurveyFormLayout = (props: SurveyFormLayoutInterface) => {
  const {
    title,
    subtitle,
    description,
    imageBackground,
    subtitlePosition = SUBTITLE_POSITION.BOTTOM,
    children,
    childProps,
    ...restProps
  } = props;
  return (
    <ContentWithNavbarLayout
      childProps={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      {...restProps}
    >
      <Box px={6} py={10} minHeight={'350px'} position={'relative'} flex={'0 0 auto'}>
        <Box
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
          overflow={'hidden'}
        >
          {imageBackground}
        </Box>
        <Grid
          position={'relative'}
          gridTemplateRows={'repeat(auto-fit, minmax(20px, max-content))'}
          gap={2}
        >
          {subtitlePosition === SUBTITLE_POSITION.TOP && subtitle && (
            <Heading4 color={'text.heading'} whiteSpace={'pre-line'}>
              {subtitle}
            </Heading4>
          )}
          <Heading3 color={'text.heading'} whiteSpace={'pre-line'}>
            {title}
          </Heading3>
          {subtitlePosition === SUBTITLE_POSITION.BOTTOM && subtitle && (
            <Heading5 color={'text.heading'} whiteSpace={'pre-line'}>
              {subtitle}
            </Heading5>
          )}
          {description && (
            <Body1 color={'text.body1'} fontFamily={'content'} whiteSpace={'pre-line'}>
              {description}
            </Body1>
          )}
        </Grid>
      </Box>
      <Box px={6} py={5} flex={'1 0 auto'} {...childProps}>
        {children}
      </Box>
    </ContentWithNavbarLayout>
  );
};

export default SurveyFormLayout;
