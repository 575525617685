import { Image, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const bigStarShake = keyframes`
  0% {
    transform: rotate(-12deg);
  }
  25% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(-12deg);
  }
`;

const smallStarShake = keyframes`
  0% {
    transform: rotate(14deg);
  }
  25% {
    transform: rotate(22deg);
  }
  75% {
    transform: rotate(6deg);
  }
  100% {
    transform: rotate(14deg);
  }
`;

const BackgroundStep2 = () => {
  return (
    <Box background="#DFEEFF" width={'100%'} height={'100%'}>
      <Image
        src={prefixUrl('/images/bubble.png')}
        position={'absolute'}
        top={'8%'}
        right={'20%'}
        width={'69px'}
      />
      <Image
        src={prefixUrl('/images/bubble.png')}
        position={'absolute'}
        bottom={'10'}
        right={'-5%'}
        width={'115px'}
      />
      <Image
        src={prefixUrl('/images/bubble.png')}
        position={'absolute'}
        top={'8%'}
        right={'20%'}
        width={'69px'}
      />
      <Image
        src={prefixUrl('/images/bubble.png')}
        position={'absolute'}
        bottom={'-15%'}
        left={'-5%'}
        width={'136px'}
      />
      <Image
        src={prefixUrl('/images/bubble.png')}
        position={'absolute'}
        top={'-10%'}
        left={'5%'}
        width={'58px'}
      />
      <Image
        src={prefixUrl('/images/heart.png')}
        width={'42px'}
        position={'absolute'}
        top={1}
        right={'-12px'}
        animation={`${bigStarShake} 1s infinite cubic-bezier(.17,.67,.83,.67)`}
      />
      <Image
        src={prefixUrl('/images/heart.png')}
        width={'25px'}
        position={'absolute'}
        top={7}
        right={8}
        animation={`${smallStarShake} 1s infinite cubic-bezier(.17,.67,.83,.67)`}
      />
    </Box>
  );
};

export default BackgroundStep2;
