import { Icon } from '@chakra-ui/react';
import { css } from '@emotion/react';
import styled from '@emotion/styled';
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from 'react-icons/md';

const ArrowButton = styled.div<{
  color?: string;
  isNext: boolean;
  position: number;
  display?: string;
}>`
  outline: none;
  color: ${({ color }) => color ?? '#000'};
  background-color: white;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  border-color: ${({ color }) => color ?? '#000'};
  position: absolute;
  top: unset;
  bottom: 10%;
  z-index: 1;
  box-shadow: 0px 0px 16px rgba(17, 17, 26, 0.1);
  border-style: none;
  transition: all 400ms cubic-bezier(0.65, 0.05, 0.36, 1);
  display: ${({ display }) => display ?? 'flex'};
  justify-content: center;
  align-items: center;

  &:hover {
    background-color: ${({ color }) => color ?? '#000'};
    color: white;
  }

  ${({ isNext, position }) =>
    isNext
      ? css`
          left: unset;
          right: ${position}px;
        `
      : css`
          right: unset;
          left: ${position}px;
        `}

  /* Override content to empty string */
  &.slick-prev:before, &.slick-next:before {
    content: '';
  }
`;

interface ArrowButtonInterface {
  className?: string;
  onClick?: () => void;
  isNext?: boolean;
  color?: string;
  position?: number;
}

const CarouselArrows = (props: ArrowButtonInterface) => {
  const { onClick, isNext = true, color = 'hsl(34, 97%, 52%)', className, position = 100 } = props;
  return (
    <ArrowButton
      className={className}
      position={position}
      color={color}
      onClick={onClick}
      isNext={isNext}
      display={typeof onClick === 'function' ? 'flex' : 'none'}
    >
      {isNext ? (
        <Icon as={MdKeyboardArrowRight} width={'32px'} height={'32px'} />
      ) : (
        <Icon as={MdKeyboardArrowLeft} width={'32px'} height={'32px'} />
      )}
    </ArrowButton>
  );
};

export default CarouselArrows;
