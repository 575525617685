import { Box, Image } from '@chakra-ui/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const BackgroundConsent = () => {
  return (
    <Box bg={'hsl(39, 93%, 88%)'} width={'100%'} height={'100%'} position={'relative'}>
      <Image
        src={prefixUrl('/images/bubble.png')}
        width={'95px'}
        height={'95px'}
        position={'absolute'}
        top={-4}
        right={-4}
      />
      <Image
        src={prefixUrl('/images/bubble.png')}
        width={'60px'}
        height={'60px'}
        position={'absolute'}
        top={10}
        right={8}
      />
      <Image
        src={prefixUrl('/images/bubble.png')}
        width={'95px'}
        height={'95px'}
        position={'absolute'}
        bottom={-5}
        left={-5}
      />
      <Image
        src={prefixUrl('/images/bubble.png')}
        width={'60px'}
        height={'60px'}
        position={'absolute'}
        bottom={6}
        left={10}
      />
    </Box>
  );
};

export default BackgroundConsent;
