import { Text, Button, FormControl } from '@chakra-ui/react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import Selection from '../../../Components/Selection';
import { SurveyFormLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { BackgroundStep1 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const WORK_CATEGORIES = [
  { label: 'ธุรกิจนําเที่ยว', value: 'ธุรกิจนําเที่ยว' },
  { label: 'ธุรกิจที่พักแรม', value: 'ธุรกิจที่พักแรม' },
  { label: 'ธุรกิจขนส่ง', value: 'ธุรกิจขนส่ง' },
  { label: 'ธุรกิจการประชุม', value: 'ธุรกิจการประชุม' },
  {
    label: `ธุรกิจด้านอาหาร
  และเครื่องดื่ม`,
    value: 'ธุรกิจด้านอาหารและเครื่องดื่ม',
  },
  { label: 'ธุรกิจจําหน่ายสินค้า', value: 'ธุรกิจจําหน่ายสินค้า' },
  { label: 'ธุรกิจนันทนาการ', value: 'ธุรกิจนันทนาการ' },
  {
    label: `ธุรกิจนวด
  และสปา`,
    value: 'ธุรกิจนวดnและสปา',
  },
];

type ErrorMessageProps = {
  message?: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Text color={'red.500'} fontSize={'xs'} mt={2}>
    {message}
  </Text>
);

type WorkCategoriesValueType = {
  myWorkCategory: string;
};

const WorkCategories = () => {
  const {
    currentStep,
    personalInformation,
    addPersonalInformation,
    goToNextPage,
    goToPreviousPage,
  } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm<WorkCategoriesValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: personalInformation,
  });

  const handleFormSubmit: SubmitHandler<WorkCategoriesValueType> = (data: any) => {
    addPersonalInformation(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={'งานของฉันเกี่ยวกับ'}
      subtitle={'กรุณาเลือกข้อที่ใกล้เคียงที่สุด'}
      navbarProps={{
        title: 'แนะนำตัว',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep1 />}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <FormControl>
          <Controller
            name="myWorkCategory"
            control={control}
            rules={{ required: 'กรุณาระบุลักษณะงานของคุณ' }}
            render={({ field: { onChange, value } }) => (
              <>
                <Selection
                  choices={WORK_CATEGORIES}
                  onAnswerChange={(value) => value !== 'other' && onChange(value)}
                  value={value}
                  isGridLayout
                  haveOtherChoice
                  answerableCount={8}
                  gridTemplateColumns={'1fr 1fr'}
                  gap={4}
                  selectProps={{ flex: 1, py: 1, minHeight: '64px' }}
                />
              </>
            )}
          />
        </FormControl>
        {errors.myWorkCategory && <ErrorMessage message={errors.myWorkCategory.message} />}

        <Button
          mt={6}
          colorScheme={'primary'}
          isLoading={isSubmitting}
          type="submit"
          width={'100%'}
          borderRadius={'100px'}
          disabled={!isValid}
        >
          ถัดไป
        </Button>
      </form>
    </SurveyFormLayout>
  );
};

export default WorkCategories;
