import { Flex, Box, Button } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import HorizontalScroll from '../../../Components/HorizontalScroll';
import { Card, CardInterface, CARD_TITLE_POSITION } from '../../../Components/Card';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const TOP_SPACE_HEIGHT = '24px';

const SupportCard = (props: CardInterface) => {
  const { title, description, imageUrl, credit, ...restProps } = props;
  const { space } = useTheme();

  return (
    <Card
      imageUrl={imageUrl}
      title={title}
      description={description}
      credit={credit}
      cardHeight={`calc(100vh - ${TOP_SPACE_HEIGHT} - ${space.FOOTER_BUTTON_HEIGHT_WITH_SPACE})`}
      cardMaxHeight={[null, null, '545px']}
      backgroundBoxProps={{ backgroundSize: 'auto 100%' }}
      {...restProps}
    />
  );
};

const SupportScrollCard = () => {
  const { goToNextPage } = useSurveyAnswerContext();

  return (
    <Flex flexDirection={'column'} height={'100%'}>
      <Box mt={6} flex={'1 1 auto'}>
        <HorizontalScroll>
          <SupportCard
            imageUrl={prefixUrl('/images/support-card-1.jpg')}
            title={'วิกฤตครั้งนี้'}
            description={
              'ส่งผลกระทบเป็นวงกว้างกับ ทุกธุรกิจ ทั้งร้านค้า กิจการราย เล็ก-รายใหญ่ ต้องปิดตัวลง หรือเลื่อนเปิดไม่มีกำหนด'
            }
            credit={'Photo by Joshua Rawson-Harris on Unsplash'}
            titlePosition={CARD_TITLE_POSITION.BOTTOM}
            isAnimateArrowRight
          />
          <SupportCard
            imageUrl={prefixUrl('/images/support-card-2.jpg')}
            title={'การรวมตัวกัน'}
            description={
              'ของธุรกิจรายย่อยก็เป็นอีกช่องทางหนึ่ง ที่จะช่วยพยุงให้ผ่าน สถานการณ์นี้ไปด้วยกัน'
            }
            credit={'Photo by RYU Wongs on Unsplash'}
          />
          <SupportCard
            imageUrl={prefixUrl('/images/support-card-3.jpg')}
            title={'แม้ทางจะตันแค่ไหน'}
            description={
              'ก็ขอให้กัดฟันสู้ ล้มเหลวก็ลอง ใหม่ ไม่ว่าผลจะเป็นยังไง อย่างน้อย เราก็สู้เต็มที่แล้ว'
            }
            credit={'Photo by Edward Leon on Unsplash'}
            titlePosition={CARD_TITLE_POSITION.BOTTOM}
          />
        </HorizontalScroll>
      </Box>
      <Flex flex={'0 0 auto'} p={6}>
        <Button
          mt={6}
          colorScheme="primary"
          width={'100%'}
          borderRadius={'100px'}
          onClick={goToNextPage}
        >
          ถัดไป
        </Button>
      </Flex>
    </Flex>
  );
};

export default SupportScrollCard;
