import { Image, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const bounce = keyframes`
  0% {
    transform: translate(-50%, 0px);
  }
  50% {
    transform: translate(-50%, -15px);
  }
  100% {
    transform: translate(-50%, 0px);
  }
`;

const BackgroundStep3 = () => {
  return (
    <Box
      background={'linear-gradient(180deg, #E9FFDD 0%, #D5F0C6 100%)'}
      height={'100%'}
      width={'100%'}
    >
      <Image
        src={prefixUrl('/images/step5-my-character.svg')}
        height={'100%'}
        maxWidth={'600px'}
        position={'relative'}
        left={'50%'}
        top={'5%'}
        transform={'translateX(-50%)'}
      />
      <Image
        src={prefixUrl('/images/thinking.png')}
        width={'60px'}
        position={'relative'}
        left={{ base: '35%', sm: '40%' }}
        bottom={'30%'}
        animation={`${bounce} 1s infinite cubic-bezier(.17,.67,.83,.67)`}
      />
    </Box>
  );
};

export default BackgroundStep3;
