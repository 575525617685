import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Heading3 } from '../../../Components/Base';
import { ContentWithNavbarLayout } from '../../../Components/Layout';
import MessageBox from '../../../Components/MessageBox';
import useSurveyAnswerContext from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const ConsolationMessage = () => (
  <Flex flexDirection={'column'} alignItems={'center'}>
    <Text color={'text.heading'} fontSize={'2xl'}>
      สำเร็จหรือไม่ ก็ไม่เป็นไร
    </Text>
    <Text fontSize={'2.125rem'} fontFamily={'handWriting'} color={'text.heading'}>
      อย่าเพิ่งท้อล่ะ
    </Text>
  </Flex>
);

const Consolation = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'SMART Goal',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{
        background: 'linear-gradient(0deg, #FFEAB6 0%, #EE8C1A 100%)',
      }}
    >
      <Box pt={10} pb={6} px={6} height={'100%'} position={'relative'} overflowY={'auto'}>
        <Flex
          flexDirection={'column'}
          justifyContent={'space-between'}
          alignContent={'center'}
          minHeight={'100%'}
          height={'auto'}
        >
          <Flex flexDirection={'column'} flex={'1 0 auto'}>
            <Text
              fontSize={'xl'}
              fontWeight={'semibold'}
              color={'white'}
              className={'animate-step-1'}
            >
              {`ถึงสถานการณ์จะยากลำบากแต่`}
            </Text>
            <Heading3 mt={2} color={'white'} className={'animate-step-2'}>
              {`การมีเป้าหมายก็จะทำให้
              เรามีแรงใจที่จะฝ่าฟัน`}
            </Heading3>
            <MessageBox
              mt={5}
              customMessage={<ConsolationMessage />}
              subMessage={`ลองลดและแบ่งเป็นขั้นๆ
              หรือมีแผนสำรองไว้ก็ดีนะ
              แล้วมาเริ่มกันใหม่อีกครั้ง`}
              subMessageProps={{ bg: 'linear-gradient(180deg, #18275E 0%, #D68000 100%)' }}
              className={'animate-step-3'}
            />
          </Flex>

          <Image
            src={prefixUrl('/images/target.svg')}
            width={'300px'}
            alignSelf={'center'}
            mt={14}
            transform={'translateX(8px)'} // This style is fix image doesn't center
            className={'animate-step-3'}
          />

          <Button
            mt={6}
            colorScheme="primary"
            width={'100%'}
            borderRadius={'100px'}
            flex={'0 0 auto'}
            className={'animate-step-3'}
            onClick={goToNextPage}
          >
            ถัดไป
          </Button>
        </Flex>
      </Box>
    </ContentWithNavbarLayout>
  );
};

export default Consolation;
