import { Flex, Image, Text } from '@chakra-ui/react';
import { Heading5 } from '../../../Components/Base';

import { SurveyTopicLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const Topic = () => {
  const { goToNextPage } = useSurveyAnswerContext();
  return (
    <SurveyTopicLayout
      title={'แบบสำรวจสุขภาพจิตใจ'}
      titleProps={{ color: 'text.onLight' }}
      background={'linear-gradient(0deg, #FFFFFF 16.34%, #DFEEFF 100%)'}
      onStart={goToNextPage}
    >
      <Flex flexDirection={'column'} alignItems={'center'} mt={2}>
        <Image src={prefixUrl('/images/30-days.svg')} width={'300px'} />
        <Heading5 whiteSpace={'pre-line'} color={'text.heading'} textAlign={'center'}>
          {`กลับมาสำรวจตัวเองอีกครั้ง
          ว่าตอนนี้คุณเป็นอย่างไรบ้าง`}
        </Heading5>
      </Flex>
    </SurveyTopicLayout>
  );
};

export default Topic;
