import { FormControl, Flex } from '@chakra-ui/react';

import Selection from '../../../Components/Selection';
import { SurveyFormLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { BackgroundStep1 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const WORK_ROLES = [
  { label: 'ผู้ประกอบการหรือเจ้าของกิจการ', value: 'ผู้ประกอบการหรือเจ้าของกิจการ' },
  {
    label: `พนักงานระดับบริหาร
    (มีผู้ใต้บังคับบัญชา)`,
    value: 'พนักงานระดับบริหาร',
  },
  {
    label: `พนักงานระดับปฏิบัติการ
    (ไม่มีผู้ใต้บังคับบัญชา)`,
    value: 'พนักงานระดับปฏิบัติการ',
  },
];

type MyRoleValueType = {
  myWorkRole: string;
};

const MyRole = () => {
  const { currentStep, addPersonalInformation, goToNextPage, goToPreviousPage } =
    useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const personalInformation = useSurveyAnswerContext().personalInformation as MyRoleValueType;
  const { myWorkRole } = personalInformation;

  const handleRoleValue = (value: string | string[]) => {
    addPersonalInformation({ myWorkRole: value });
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={'ระดับงานที่ฉันรับผิดชอบ'}
      subtitle={'กรุณาเลือกข้อที่ใกล้เคียงที่สุด 1 คำตอบ'}
      navbarProps={{
        title: 'แนะนำตัว',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep1 />}
    >
      <Flex height={'100%'} flexDirection={'column'} flex={1} justifyContent={'space-between'}>
        <FormControl>
          <Selection
            choices={WORK_ROLES}
            onAnswerChange={(value) => handleRoleValue(value)}
            value={myWorkRole ?? []}
            isGridLayout
            gridTemplateColumns={'1fr'}
            gap={4}
            selectProps={{ flex: 1, py: 1, minHeight: '64px' }}
          />
        </FormControl>
      </Flex>
    </SurveyFormLayout>
  );
};

export default MyRole;
