import { Box, Flex, Image, Text } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

import { SurveyTopicLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

type ContentBoxType = {
  title: string;
  description: string;
};

const CustomImageBackground = () => (
  <Image
    src={prefixUrl('/images/mountain.png')}
    position={'absolute'}
    width={'250px'}
    height={'auto'}
    bottom={0}
    right={0}
    transform={'translateX(25%)'}
  />
);

const ContentBox = (props: ContentBoxType) => {
  const { title, description } = props;
  return (
    <Box>
      <Flex alignItems={'center'}>
        <Flex
          bg={'radial-gradient(74.62% 74.62% at 17.69% 17.69%, #F4A641 0%, #E87E1C 100%)'}
          justifyContent={'center'}
          alignItems={'center'}
          width={'36px'}
          height={'36px'}
          borderRadius={'full'}
        >
          <Text color={'white'} fontSize={'xl'} fontWeight={'bold'}>
            {title.charAt(0).toUpperCase()}
          </Text>
        </Flex>
        <Text fontSize={'xl'} fontWeight={'bold'} ml={2}>
          {title}
        </Text>
      </Flex>
      <Text fontFamily={'content'} fontSize={'lg'} mt={1}>
        {description}
      </Text>
    </Box>
  );
};

const Topic = () => {
  const { goToNextPage } = useSurveyAnswerContext();
  const { colors } = useTheme();

  return (
    <SurveyTopicLayout
      title={`มารู้จัก SMART Goal
              เพื่อสร้างเป้าหมาย
              ให้ตัวเอง`}
      background={'linear-gradient(0deg, #FFFFFF 14.58%, #FFF1CE 27.04%)'}
      position={'relative'}
      height={'auto'}
      onStart={goToNextPage}
      titleProps={{ position: 'relative' }}
      childProps={{ position: 'relative' }}
      customImageBackground={<CustomImageBackground />}
      rippleProps={{
        message: `เริ่มตั้ง
        SMART
        Goal`,
        textStyles: {
          mt: 2,
          fontSize: 'xl',
          textAlign: 'center',
          whiteSpace: 'pre-line',
        },
      }}
    >
      {/* Image Background Zone */}
      <Image
        src={prefixUrl('/images/archery-target.png')}
        position={'absolute'}
        width={'135px'}
        height={'auto'}
        top={0}
        right={0}
        transform={'translate(45%, -15%)'}
      />
      <Image
        src={prefixUrl('/images/bubble.png')}
        position={'absolute'}
        width={'84px'}
        height={'auto'}
        top={0}
        right={0}
        transform={'translate(-15%, -50%)'}
      />

      {/* Table of SMART Goal Zone */}
      <Flex flexDirection={'column'} alignItems={'center'} mt={14} position={'relative'}>
        <Box
          bg={'linear-gradient(92.01deg, #FB940F 61.92%, #FEDFB7 97.64%)'}
          borderRadius={'20px 20px 0 0'}
          width={'100%'}
          px={6}
          py={1}
        >
          <Text color={'white'} textAlign={'center'} fontWeight={'bold'}>
            เป้าหมายที่ดีจะต้อง…
          </Text>
        </Box>
        <Box
          bg={'white'}
          width={'100%'}
          px={4}
          py={5}
          position={'relative'}
          borderRadius={'0 0 20px 20px'}
        >
          <ContentBox
            title={'Specific'}
            description={'เฉพาะเจาะจง ไม่คลุมเครือ และบอกชัดเจนว่า คุณต้องการจะทำอะไร'}
          />
          <Box borderTop={`1px solid ${colors.gray[100]}`} my={4} />
          <ContentBox
            title={'Measurable'}
            description={
              'ต้องวัดผลและติดตามความก้าวหน้าได้ จะทำให้คุณรู้ว่า สิ่งที่ทำสำเร็จหรือไม่ เข้าใกล้เป้าหมายแค่ไหน ยังต้องทำอีกเท่าไหร่'
            }
          />
          <Box borderTop={`1px solid ${colors.gray[100]}`} my={4} />
          <ContentBox
            title={'Achievable'}
            description={
              'มีโอกาสทำสำเร็จได้ และไม่ยากจนเกินไป เพื่อกระตุ้นให้คุณมีกำลังใจและอยากทำต่อ'
            }
          />
          <Box borderTop={`1px solid ${colors.gray[100]}`} my={4} />
          <ContentBox
            title={'Realistic'}
            description={
              'ต้องสมเหตุสมผล ตั้งอยู่บนพื้นฐานความเป็นจริง และสอดคล้องกับสถานการณ์ปัจจุบันของคุณ'
            }
          />
          <Box borderTop={`1px solid ${colors.gray[100]}`} my={4} />
          <ContentBox
            title={'Time Bound'}
            description={
              'มีกรอบระยะเวลาชัดเจน เพราะจะทำให้คุณรู้ว่า ต้องทำถึงเมื่อไหร่ จะวางแผนอย่างไร และเหลือเวลาที่จะทำเป้าหมายให้สำเร็จอีกแค่ไหน'
            }
          />

          {/* Image Background Zone */}
          <Image
            src={prefixUrl('/images/archery.png')}
            position={'absolute'}
            width={'105px'}
            height={'auto'}
            bottom={0}
            left={0}
            transform={'translateY(80%)'}
          />
          <Image
            src={prefixUrl('/images/bubble.png')}
            position={'absolute'}
            width={'87px'}
            height={'auto'}
            bottom={0}
            left={0}
            transform={'translate(-45%, 80%)'}
          />
        </Box>
      </Flex>
    </SurveyTopicLayout>
  );
};

export default Topic;
