const HeroWink = (props: any) => (
  <svg width={72} height={25} viewBox="0 0 72 25" fill="none" {...props}>
    <path
      d="M46.215 12.77c-4.843-1.9-7.797-6.34-9.168-9.492-.18-.412-.983-.35-1.123.079-1.522 4.647-6.145 7.859-9.174 9.305-.343.163-.33.708.018.86 4.782 2.079 7.52 6.157 8.81 9.173.183.43 1.247.393 1.435-.034 1.719-3.9 6.282-7.352 9.269-9.056.322-.184.277-.701-.067-.836z"
      fill="url(#prefix__paint0_linear)"
    />
    <rect
      x={67.371}
      y={15.407}
      width={3.485}
      height={9.061}
      rx={1.742}
      transform="rotate(135 67.371 15.407)"
      fill="#fff"
    />
    <rect
      x={7.964}
      y={17.871}
      width={3.485}
      height={9.061}
      rx={1.742}
      transform="rotate(-135 7.964 17.871)"
      fill="#fff"
    />
    <defs>
      <linearGradient
        id="prefix__paint0_linear"
        x1={36.5}
        y1={3}
        x2={36.5}
        y2={23}
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FFE660" />
        <stop offset={1} stopColor="#FFD43C" />
      </linearGradient>
    </defs>
  </svg>
);

export default HeroWink;
