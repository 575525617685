import { useMemo } from 'react';
import { Text, Button, FormControl, FormLabel, Flex, Box } from '@chakra-ui/react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Input, Select } from '../../../Components/Base';
import Selection from '../../../Components/Selection';
import { SurveyFormLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { BackgroundStep1 } from '../../../Components/SurveyBackgroundImage';

import { provinces } from './provinces';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const GENDER = [
  { label: 'ชาย', value: 'ชาย' },
  { label: 'หญิง', value: 'หญิง' },
  { label: 'ไม่ระบุ', value: 'ไม่ระบุ' },
];

type ErrorMessageProps = {
  message?: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Text color={'red.500'} fontSize={'xs'} mt={2}>
    {message}
  </Text>
);

type PersonalInformationValueType = {
  email?: string;
  gender?: string;
  mobileNumber?: string;
  name: string;
  province?: string;
  yearOfBirth: string;
};

const PersonalInformation = () => {
  const { currentStep, personalInformation, addPersonalInformation, goToNextPage } =
    useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm<PersonalInformationValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: { mobileNumber: '', ...personalInformation },
  });

  const yearList = useMemo(() => {
    let _yearList: { value: string; label: string }[] = [];

    let currentYear = new Date().getFullYear() + 543;
    let earliestYear = 2483;

    while (currentYear >= earliestYear) {
      _yearList.push({ value: `${currentYear}`, label: `${currentYear}` });
      currentYear -= 1;
    }
    return _yearList;
  }, []);

  const handleFormSubmit: SubmitHandler<PersonalInformationValueType> = (data: any) => {
    addPersonalInformation(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={'แนะนำตัว'}
      subtitle={'กรุณากรอกข้อมูลให้ครบถ้วน'}
      // description={
      //   'ทางเราจะได้ติดต่อให้คุณหลังจากนี้อีก 30 วัน เพื่อให้คุณกลับมาลองสำรวจตัวเองอีกครั้ง'
      // }
      navbarProps={{
        title: 'แนะนำตัว',
        currentStep: currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep1 />}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} style={{ height: '100%' }}>
        <Flex flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
          <Box>
            <Input
              label={'ชื่อที่ฉันอยากใช้ในกิจกรรมนี้'}
              inputProps={{
                type: 'text',
                placeholder: 'ชื่อ',
                ...register('name', { required: 'กรุณากรอกชื่อของคุณ' }),
              }}
            />
            {errors.name && <ErrorMessage message={errors.name.message} />}
            <Select
              label={'ปีที่เกิด (พ.ศ.)'}
              options={yearList}
              selectProps={{
                placeholder: 'เลือกปีเกิด',
                ...register('yearOfBirth', { required: 'กรุณาเลือกปีเกิดของคุณ' }),
              }}
              mt={6}
            />
            {errors.yearOfBirth && <ErrorMessage message={errors.yearOfBirth.message} />}

            {/* https://skooldio.atlassian.net/browse/SWU-241 Hide gender, province, email, and mobile number fields */}
            {/* <FormControl mt={6}>
              <FormLabel fontWeight={'semibold'} fontSize={'lg'}>
                เพศ
              </FormLabel>
              <Controller
                name="gender"
                control={control}
                rules={{ required: 'กรุณาเลือกเพศของคุณ' }}
                render={({ field: { onChange, value } }) => (
                  <Selection
                    choices={GENDER}
                    onAnswerChange={(value) => onChange(value)}
                    value={value}
                    isGridLayout
                    gridTemplateColumns={'1fr 1fr 1fr'}
                    gap={4}
                    selectProps={{ flex: 1 }}
                  />
                )}
              />
            </FormControl>
            {errors.gender && <ErrorMessage message={errors.gender.message} />} */}

            {/* <Select
              label={'จังหวัดที่พักอาศัย'}
              options={provinces}
              selectProps={{
                placeholder: 'เลือกจังหวัด',
                ...register('province', { required: 'กรุณาเลือกจังหวัดที่พักอาศัยของคุณ' }),
              }}
              mt={6}
            />
            {errors.province && <ErrorMessage message={errors.province.message} />} */}

            {/* <Input
              label={'อีเมล'}
              inputProps={{
                type: 'email',
                placeholder: 'กรอกอีเมล',
                ...register('email', { required: 'กรุณากรอกอีเมลของคุณ' }),
              }}
              mt={6}
            />
            {errors.email && <ErrorMessage message={errors.email.message} />} */}

            {/* <Controller
              name={'mobileNumber'}
              control={control}
              rules={{
                // required: 'กรุณากรอกเบอร์มือถือของคุณ',
                minLength: {
                  value: 10,
                  message: 'กรุณากรอกเบอร์มือถือของคุณให้ถูกต้อง',
                },
                maxLength: {
                  value: 10,
                  message: 'กรุณากรอกเบอร์มือถือของคุณให้ถูกต้อง',
                },
              }}
              render={({ field: { onChange, value } }) => (
                <InputMask
                  mask={'999 999 9999'}
                  maskPlaceholder={null}
                  value={value}
                  onChange={(e) => onChange(e.target.value.replaceAll(' ', ''))}
                >
                  <Input
                    label={'เบอร์มือถือ'}
                    inputProps={{
                      type: 'tel',
                      placeholder: 'กรอกเบอร์มือถือ',
                      textDecoration: 'none',
                    }}
                    mt={6}
                  />
                </InputMask>
              )}
            />
            {errors.mobileNumber && <ErrorMessage message={errors.mobileNumber.message} />} */}
          </Box>

          <Button
            mt={6}
            colorScheme={'primary'}
            isLoading={isSubmitting}
            type="submit"
            width={'100%'}
            borderRadius={'100px'}
            disabled={!isValid}
          >
            ถัดไป
          </Button>
        </Flex>
      </form>
    </SurveyFormLayout>
  );
};

export default PersonalInformation;
