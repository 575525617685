import { Image, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const heartShake = keyframes`
  0% {
    transform: rotate(12deg);
  }
  25% {
    transform: rotate(20deg);
  }
  75% {
    transform: rotate(4deg);
  }
  100% {
    transform: rotate(12deg);
  }
`;

const bulbShake = keyframes`
  0% {
    transform: rotate(-12deg);
  }
  25% {
    transform: rotate(-20deg);
  }
  75% {
    transform: rotate(-4deg);
  }
  100% {
    transform: rotate(-12deg);
  }
`;

const jigsawShake = keyframes`
  0% {
    transform: rotate(-8deg);
  }
  25% {
    transform: rotate(-16deg);
  }
  75% {
    transform: rotate(-2deg);
  }
  100% {
    transform: rotate(-8deg);
  }
`;

export const Survey30DaysPersonalFormBackground = () => {
  return (
    <Box background={'#DFEEFF'} height={'100%'} width={'100%'}>
      <Image
        src={prefixUrl('/images/step7-personal-form-background.svg')}
        width={'550px'}
        maxWidth={'unset'}
        height={'100%'}
        position={'absolute'}
        right={-20}
        bottom={-10}
      />
      <Image
        src={prefixUrl('/images/heart-ball-2.png')}
        width={'60px'}
        position={'absolute'}
        top={'45%'}
        left={5}
        animation={`${heartShake} 3s cubic-bezier(.17,.67,.83,.67) infinite`}
      />
      <Image
        src={prefixUrl('/images/bulb.png')}
        width={'84px'}
        position={'absolute'}
        top={'65%'}
        left={'15%'}
        animation={`${bulbShake} 3s cubic-bezier(.17,.67,.83,.67) infinite`}
      />
      <Image
        src={prefixUrl('/images/jigsaw.png')}
        width={'44px'}
        position={'absolute'}
        right={5}
        top={'15%'}
        animation={`${jigsawShake} 3s cubic-bezier(.17,.67,.83,.67) infinite`}
      />
    </Box>
  );
};
