import { Image, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const cloudShakeBeginLeft = keyframes`
  0% {
    transform: translate(0px, -50%);
  }
  25% {
    transform: translate(-15px, -50%);
  }
  75% {
    transform: translate(15px, -50%);
  }
  100% {
    transform: translate(0px, -50%);
  }
`;

const cloudShakeBeginRight = keyframes`
  0% {
    transform: translate(0px, -50%);
  }
  25% {
    transform: translate(15px, -50%);
  }
  75% {
    transform: translate(-15px, -50%);
  }
  100% {
    transform: translate(0px, -50%);
  }
`;

const cloudSmallShake = keyframes`
  0% {
    transform: translateX(-80%);
  }
  25% {
    transform: translateX(-85%);
  }
  75% {
    transform: translateX(-75%);
  }
  100% {
    transform: translateX(-80%);
  }
`;

export const BackgroundStep4WithAnimation = () => {
  return (
    <>
      <Image
        src={prefixUrl('/images/step4-form-background.png')}
        height={'100%'}
        maxWidth={'unset'}
        position={'relative'}
        left={'50%'}
        transform={'translateX(-50%)'}
      />
      <Image
        src={prefixUrl('/images/cloud-1.png')}
        width={'180px'}
        position={'absolute'}
        top={'50%'}
        left={4}
        transform={'translateY(-50%)'}
        animation={`${cloudShakeBeginLeft} 3s cubic-bezier(.17,.67,.83,.67) infinite`}
      />
      <Image
        src={prefixUrl('/images/cloud-1.png')}
        width={'160px'}
        position={'absolute'}
        top={'65%'}
        right={6}
        transform={'translateY(-50%)'}
        animation={`${cloudShakeBeginRight} 3s cubic-bezier(.17,.67,.83,.67) infinite`}
      />
      <Image
        src={prefixUrl('/images/cloud-1.png')}
        width={'125px'}
        position={'absolute'}
        bottom={5}
        left={'50%'}
        transform={'translateX(-80%)'}
        animation={`${cloudSmallShake} 3s cubic-bezier(.17,.67,.83,.67) infinite`}
      />
    </>
  );
};

const BackgroundStep4 = () => {
  return (
    <Box
      background={'linear-gradient(180deg, #FFF1CF 0%, #FAE1A6 100%)'}
      height={'100%'}
      width={'100%'}
    >
      <Image
        src={prefixUrl('/images/step4-my-goal.svg')}
        maxWidth={'unset'}
        position={'absolute'}
        bottom={0}
        right={-3}
        width={'97px'}
      />
      <Image
        src={prefixUrl('/images/step4-mask-group.png')}
        width={'250px'}
        maxWidth={'unset'}
        position={'absolute'}
        right={'-17%'}
        top={0}
      />
    </Box>
  );
};

export default BackgroundStep4;
