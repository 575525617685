import { Box, Button, Checkbox as ChakraCheckbox, Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { SubmitHandler, useForm } from 'react-hook-form';
import { MdCheck } from 'react-icons/md';
import { Heading3 } from '../../../Components/Base';
import { ContentWithNavbarLayout } from '../../../Components/Layout';
import MessageBox from '../../../Components/MessageBox';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

type SmartGoalInformationType = {
  smartGoal?: string;
  measure?: string;
  measureDuration?: string;
  allowPublish: boolean;
};

type AllowToPublishValueType = {
  allowPublish: boolean;
};

const Checkbox = styled(ChakraCheckbox)`
  & span {
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
`;

const SummaryGoal = () => {
  const { currentStep, goToNextPage, addSmartGoalInformation } = useSurveyAnswerContext();
  const smartGoalInformation = useSurveyAnswerContext()
    .smartGoalInformation as SmartGoalInformationType;

  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();
  const { smartGoal = '', measure = '', measureDuration = '' } = smartGoalInformation;

  const { register, handleSubmit } = useForm<AllowToPublishValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: {
      allowPublish: true,
    },
  });

  const handleFormSubmit: SubmitHandler<AllowToPublishValueType> = (data: any) => {
    addSmartGoalInformation(data);
    goToNextPage();
  };

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'SMART Goal',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{
        background: 'linear-gradient(0deg, #FFEAB6 0%, #EE8C1A 100%)',
      }}
    >
      <Flex flexDirection={'column'} px={6} pt={10} pb={6} height={'100%'} overflowY={'auto'}>
        <Heading3 color={'white'} className={'animate-step-1'}>
          เป้าหมายของฉันคือ
        </Heading3>
        <Box mt={6} />
        <form onSubmit={handleSubmit(handleFormSubmit)} style={{ flex: '1 0 auto' }}>
          <Flex
            flexDirection={'column'}
            justifyContent={'space-between'}
            alignContent={'center'}
            height={'100%'}
          >
            <Box flex={'1 0 auto'}>
              <MessageBox
                message={`${smartGoal} โดยวัดจาก ${measure} และจะสามารถทำได้ภายใน ${measureDuration}`}
                className={'animate-step-2'}
              />
              <Box mt={8} />
              <Box
                py={4}
                px={4}
                bg={'linear-gradient(171.99deg, #FFFFFF 4.42%, #D7D3D0 164.46%)'}
                borderRadius={'24px'}
                boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.16)'}
                className={'animate-step-2'}
              >
                <Flex as={'label'} alignItems={'center'}>
                  <Checkbox
                    colorScheme={'primary'}
                    icon={<MdCheck size={'24px'} />}
                    size={'lg'}
                    {...register('allowPublish')}
                  />
                  <Text ml={4} fontSize={'sm'} whiteSpace={'pre-line'}>
                    {`ร่วมสร้างเป้าหมายและกำลังใจให้กับคนอื่นโดยยินยอมที่จะเผยแพร่ข้อความนี้ให้กับเว็บ`}
                  </Text>
                </Flex>
              </Box>
            </Box>

            <Image
              src={prefixUrl('/images/colosseum.svg')}
              width={'300px'}
              alignSelf={'center'}
              mt={14}
              className={'animate-step-2'}
            />

            <Button
              mt={6}
              colorScheme="primary"
              type="submit"
              width={'100%'}
              borderRadius={'100px'}
              flex={'0 0 auto'}
              className={'animate-step-2'}
            >
              ถัดไป
            </Button>
          </Flex>
        </form>
      </Flex>
    </ContentWithNavbarLayout>
  );
};

export default SummaryGoal;
