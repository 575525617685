import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Heading3 } from '../../../Components/Base';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import MessageBox from '../../../Components/MessageBox';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const SummarySelfSupportive = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'My Growth',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{
        bg: 'linear-gradient(0deg, #C8F9D5 0%, #2B9B8B 100%)',
      }}
    >
      <Flex flexDirection={'column'} pt={10} pb={6} height={'100%'} overflowY={'auto'}>
        <Box px={6}>
          <Text
            fontSize={'xl'}
            fontWeight={'semibold'}
            color={'white'}
            className={'animate-step-1'}
          >
            ฉันเชื่อว่า
          </Text>
          <Heading3 color={'white'} className={'animate-step-2'}>
            จะทำสำเร็จเพราะ
          </Heading3>
        </Box>

        <Box flex={'1 0 auto'} px={6} mt={8}>
          <MessageBox
            message={'ตัวฉันเองนี่แหล่ะ!'}
            className={'animate-step-3'}
            subMessage={`ที่ก้าวผ่านอุปสรรคมาหลายต่อหลายครั้ง ไม่ว่าจะเจอปัญหาอะไรอีก ฉันก็พร้อมสู้ต่อ!!`}
            subMessageProps={{
              bg: 'linear-gradient(180deg, #18275E 0%, #2B9B8B 100%)',
            }}
            isLargeMessage
          />
        </Box>

        <Image
          src={prefixUrl('/images/my-growth-7.svg')}
          width={'100%'}
          alignSelf={'center'}
          className={'animate-step-3'}
        />

        <Box px={6}>
          <Button
            colorScheme="primary"
            width={'100%'}
            borderRadius={'100px'}
            className={'animate-step-3'}
            onClick={goToNextPage}
          >
            ถัดไป
          </Button>
        </Box>
      </Flex>
    </ContentWithNavbarLayout>
  );
};

export default SummarySelfSupportive;
