import { Box, Button, Flex, Text, TextProps } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { SurveyFormLayout } from '../../../Components/Layout';
import { BackgroundConsent } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const BodyText = (props: TextProps) => <Text fontFamily={'content'} fontSize={'lg'} {...props} />;

const TextWithTab = styled(BodyText)`
  text-indent: 2ch;
`;

const ConsentForm = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();
  const { replace } = useHistory();

  const goToLandingPage = () => {
    replace('/');
  };

  return (
    <SurveyFormLayout
      title={`ท่านได้รับเชิญให้
      เข้าร่วมการวิจัยเรื่อง`}
      subtitle={
        'โครงการพัฒนาสุขภาวะด้านจิตใจในกลุ่มผู้ประกอบการและพนักงานของวิสาหกิจขนาดกลางและขนาดย่อมในกลุ่มธุรกิจการท่องเที่ยว'
      }
      description={'กรุณาอ่านเอกสารแสดงความยินยอมเข้าร่วมการวิจัย (Consent Form)'}
      navbarProps={{
        title: 'แนะนำตัว',
        currentStep: currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundConsent />}
    >
      <TextWithTab>
        เนื่องจากท่านมีคุณสมบัติตามเกณฑ์ที่กำหนดไว้ในงานวิจัย
        โดยโครงการวิจัยมีวัตถุประสงค์เพื่อพัฒนา
        สุขภาวะด้านจิตใจให้กับผู้ใช้โปรแกรมด้วยแนวคิดทุนทางจิตวิทยาเชิงบวก
        ประโยชน์จากการเข้าร่วมโครงการวิจัยท่านอาจมีทักษะในการสร้างต้นทุนทางจิตใจ
        เพื่อฝ่าฟันอุปสรรคสำหรับสถานการณ์ต่างๆ ในชีวิตของท่าน
      </TextWithTab>
      <Box pt={4} />
      <TextWithTab>
        ผู้วิจัยขอความกรุณาให้ท่านได้ให้ข้อมูลที่ตรงกับ ความรู้สึก/สิ่งที่ตรงกับตัวท่านมากที่สุด
        โดยข้อมูล และคำตอบทั้งหมดจะถูกเก็บรักษาไว้เป็นความลับ และจะไม่เปิดเผยต่อสาธารณะ
        และจะนำมาใช้ในการวิเคราะห์ผลการศึกษาครั้งนี้ โดยจะแสดงผลในลักษณะภาพรวมของการศึกษาเท่านั้น
      </TextWithTab>
      <Box pt={4} />
      <TextWithTab>
        หากมีข้อสงสัยที่จะสอบถามเกี่ยวข้องกับโครงการวิจัยหรือเกิดผลข้างเคียงที่ไม่พึงประสงค์จากการวิจัย
        ท่านสามารถติดต่อหัวหน้าโครงการวิจัย:
      </TextWithTab>
      <Flex pt={8} flexDirection={'column'} alignItems={'center'}>
        <BodyText>ผศ.ดร.ศรัณย์ พิมพ์ทอง</BodyText>
        <BodyText textAlign={'center'}>สถานที่ติดต่อ สถาบันวิจัยพฤติกรรมศาสตร์</BodyText>
        <BodyText>มหาวิทยาลัยศรีนครินทรวิโรฒ</BodyText>
        <BodyText>เบอร์โทรศัพท์ 098-469-3624</BodyText>
      </Flex>
      <Box pt={8} />
      <TextWithTab>
        ข้าพเจ้าได้เข้าใจข้อความในเอกสารชี้แจงผู้เข้าร่วมวิจัยและยินยอมเข้าร่วมโครงการวิจัย
      </TextWithTab>
      <Box pt={8} />
      <Button
        variant="outline"
        color={'text.heading'}
        borderColor={'gray.300'}
        width={'100%'}
        borderRadius={'100px'}
        onClick={goToLandingPage}
        fontWeight={'normal'}
      >
        ไม่ยินยอม
      </Button>
      <Button
        mt={4}
        colorScheme="primary"
        width={'100%'}
        borderRadius={'100px'}
        onClick={goToNextPage}
      >
        ยินยอม
      </Button>
    </SurveyFormLayout>
  );
};

export default ConsentForm;
