import { useSurveyAnswerContext } from '../SurveyAnswer/useSurveyContext';

import { keyType, survey30DaysKeyType, MATCH_FORM_FIELD, MATCH_PERSONAL_FORM_FIELD } from './Utils';

type formResponseParamType = {
  value: any;
  MATCH_FORM: any;
};

const getParam = ({ value, MATCH_FORM }: formResponseParamType) => {
  let _param = '';
  const keys = Object.keys(value);
  keys.map((key, index) => {
    let _key = key;
    if (
      key === '1' ||
      key === '2' ||
      key === '3' ||
      key === '4' ||
      key === '5' ||
      key === '6' ||
      key === '7' ||
      key === '8' ||
      key === '9' ||
      key === '10'
    ) {
      _key = `question${key}`;
      _param = _param + `${MATCH_FORM[_key]}=${value[key]}&`;
    } else {
      _param = _param + `${MATCH_FORM[_key]}=${value[_key]}&`;
    }
  });

  return _param;
};

const useStoreData = () => {
  const {
    personalInformation,
    surveyAnswers,
    inspireInformation,
    smartGoalInformation,
    myIdentity,
    survey30DaysAnswers,
  } = useSurveyAnswerContext();

  const onStoreData = async () => {
    const formId = process.env.REACT_APP_FORM_ID;
    const values: any = {
      ...personalInformation,
      ...surveyAnswers,
      ...inspireInformation,
      ...smartGoalInformation,
      ...myIdentity,
    };
    const param = getParam({ value: values as keyType, MATCH_FORM: MATCH_FORM_FIELD });

    const url = `https://docs.google.com/forms/d/${formId}/formResponse?${param}`;

    const { status, statusText } = await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
    });

    return { status, statusText };
  };

  const onStoreSurvey30Days = async () => {
    const formId = process.env.REACT_APP_SURVEY_30_DAYS_FORM_ID;
    const values: any = {
      ...survey30DaysAnswers,
    };
    const param = getParam({
      value: values as survey30DaysKeyType,
      MATCH_FORM: MATCH_PERSONAL_FORM_FIELD,
    });

    const url = `https://docs.google.com/forms/d/${formId}/formResponse?${param}`;

    const { status, statusText } = await fetch(url, {
      method: 'POST',
      mode: 'no-cors',
    });

    return { status, statusText };
  };

  return { onStoreData, onStoreSurvey30Days };
};

export default useStoreData;
