export const STEP1_IMAGE_LIST = [
  '/images/bubble.png',
  '/images/human-1.png',
  '/images/human-2.png',
  '/images/human-3.png',
  '/images/humans.png',
  '/images/ipad-wallpaper.svg',
  '/images/light-bg.png',
  '/images/at-first-sight.svg',
  '/images/swu-logo.svg',
  '/images/thai-health-logo.svg',
  '/images/wallpaper.svg',
];

export const STEP2_IMAGE_LIST = [
  '/images/at-first-quote.gif',
  '/images/heart.png',
  '/images/hero-slide-1.jpg',
  '/images/hero-slide-2.jpg',
  '/images/hero-slide-3.jpg',
  '/images/hero-slide-4.jpg',
  '/images/hero-slide-5.jpg',
  '/images/step2-topic.svg',
  '/images/survey2-result-object.svg',
  '/images/wink.png',
];

export const STEP3_IMAGE_LIST = [
  '/images/cover-inspire-video.jpg',
  '/images/cover-my-idol.svg',
  '/images/emoji-happy.png',
  '/images/emoji-same.png',
  '/images/emoji-very-happy.png',
  '/images/good-thing-quote.gif',
  '/images/heart-ball-1.png',
  '/images/more-time-quote.gif',
  '/images/pass-together-quote.gif',
  '/images/step3-my-idol.svg',
  '/images/step3-topic.svg',
  '/images/support-card-1.jpg',
  '/images/support-card-2.jpg',
  '/images/support-card-3.jpg',
  '/images/prepare-for-smart-goal-background.png',
  '/images/human-4.png',
];

export const STEP4_IMAGE_LIST = [
  '/images/archery-target',
  '/images/archery.png',
  '/images/cloud.png',
  '/images/colosseum.svg',
  '/images/motivation-quote.gif',
  '/images/mountain.png',
  '/images/rome-building-quote.gif',
  '/images/step4-my-goal.svg',
  '/images/step4-mask-group.png',
  '/images/target.svg',
];

export const STEP5_IMAGE_LIST = [
  '/images/encourage-quote.gif',
  '/images/step5-my-character.svg',
  '/images/step5-topic.svg',
  '/images/thinking.png',
];

export const STEP6_IMAGE_LIST = [
  '/images/30-days.png',
  '/images/my-growth-1.svg',
  '/images/my-growth-2.svg',
  '/images/my-growth-3.svg',
  '/images/my-growth-4.svg',
  '/images/my-growth-5.svg',
  '/images/my-growth-6.svg',
  '/images/my-growth-7.svg',
  '/images/share-footer.png',
  '/images/share-heading.svg',
  '/images/step6-topic.svg',
];

export const STEP7_IMAGE_LIST = [
  '/images/step7-personal-form-background.svg',
  '/images/step7-form-result-background.svg',
  '/images/step2-form-background.png',
  '/images/heart.png',
  '/images/bulb.png',
  '/images/jigsaw.png',
  '/images/heart-ball-2.png',
  '/images/cloud-2.png',
  '/images/cloud-3.png',
  '/images/30-days-thankyou-bg-top.svg',
  '/images/30-days-thankyou-bg-bottom.svg',
  '/images/30-days.svg',
];
