import { Flex, Box, Button } from '@chakra-ui/react';
import { useTheme } from '@emotion/react';

import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import HorizontalScroll from '../../../Components/HorizontalScroll';
import { Card } from '../../../Components/Card';

const TOP_SPACE_HEIGHT = '24px';

const HeroScrollCard = () => {
  const { goToNextPage } = useSurveyAnswerContext();
  const { space } = useTheme();

  return (
    <Flex flexDirection={'column'} height={'100%'}>
      <Box mt={6} flex={'1 1 auto'}>
        <HorizontalScroll>
          {Array.from({ length: 5 }).map((item, index) => (
            <Card
              imageUrl={`images/hero-slide-${index + 1}.jpg`}
              cardHeight={`calc(100vh - ${TOP_SPACE_HEIGHT} - ${space.FOOTER_BUTTON_HEIGHT_WITH_SPACE})`}
              cardMaxHeight={[null, null, '545px']}
              isAnimateArrowRight={index === 0}
            />
          ))}
        </HorizontalScroll>
      </Box>
      <Flex flex={'0 0 auto'} p={6}>
        <Button
          mt={6}
          colorScheme="primary"
          onClick={goToNextPage}
          width={'100%'}
          borderRadius={'100px'}
        >
          ถัดไป
        </Button>
      </Flex>
    </Flex>
  );
};

export default HeroScrollCard;
