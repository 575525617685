import { Box, BoxProps, Flex, FlexProps, Text, TextProps, Image } from '@chakra-ui/react';
import { ReactChild } from 'react';
import { prefixUrl } from '../../Utils/mapAssetUrl';
import RippleBox, { RippleBoxInterface } from '../RippleBox';

interface SurveyTopicLayoutInterface extends FlexProps {
  title: string;
  background?: string;
  onStart: () => void;
  rippleProps?: RippleBoxInterface;
  titleProps?: TextProps;
  customImageBackground?: ReactChild | ReactChild[];
  hasOrganizerSection?: boolean;
  children: ReactChild | ReactChild[];
  childProps?: BoxProps;
}

const SurveyTopicLayout = (props: SurveyTopicLayoutInterface) => {
  const {
    title,
    background,
    onStart,
    rippleProps = {},
    titleProps,
    customImageBackground,
    hasOrganizerSection = false,
    children,
    childProps,
    ...restProps
  } = props;
  const { message, textStyles } = rippleProps;
  return (
    <Flex
      width={'100%'}
      minHeight={'100%'}
      height={'auto'}
      position={'relative'}
      flexDirection={'column'}
      alignItems={'center'}
      pt={10}
      pb={20}
      px={6}
      backgroundImage={background ? background : 'unset'}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      overflowY={'auto'}
      overflowX={'hidden'}
      {...restProps}
    >
      {customImageBackground}
      <Text
        textAlign={'center'}
        fontWeight={'bold'}
        fontSize={'3xl'}
        color={'hsl(240, 94%, 23%)'}
        flex={'0 0 auto'}
        whiteSpace={'pre-line'}
        {...titleProps}
      >
        {title}
      </Text>
      <Box flex={1} {...childProps}>
        {children}
      </Box>
      <Box mt={10}>
        <RippleBox message={message} textStyles={textStyles} onClick={onStart} flex={'0 0 auto'} />
      </Box>
      {hasOrganizerSection && (
        <Flex
          mt={14}
          mb={20}
          px={6}
          position={'relative'}
          flexDirection={'column'}
          alignItems={'center'}
          width={'100%'}
        >
          <Text fontSize={'xl'} fontWeight={'bold'} whiteSpace={'pre-line'} mt={2}>
            จัดทำโดย
          </Text>
          <Flex justifyContent={'space-evenly'} width={'100%'} mt={4}>
            <Image src={prefixUrl('/images/thai-health-logo.svg')} height={'80px'} width={'auto'} />
            <Image src={prefixUrl('/images/swu-logo.svg')} height={'80px'} width={'auto'} />
          </Flex>
        </Flex>
      )}
    </Flex>
  );
};

export default SurveyTopicLayout;
