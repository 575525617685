import {
  Box,
  Button,
  Flex,
  FormControl,
  FormControlProps,
  FormLabel,
  Image,
  Text,
} from '@chakra-ui/react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';

import { SurveyFormLayout } from '../../../Components/Layout';
import LinearScale from '../../../Components/LinearScale';
import { BackgroundStep2 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { MentalHealthValueType } from '../../../Utils/MentalHealthValueType';

type ErrorMessageProps = {
  message?: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Text color={'red.500'} fontSize={'xs'} mt={2}>
    {message}
  </Text>
);

type LinearFormType = {
  label: string;
  requiredMessage?: string;
  control: any;
  name: string;
} & FormControlProps;

const LinearForm = (props: LinearFormType) => {
  const { label, requiredMessage, control, name, ...restProps } = props;
  return (
    <FormControl {...restProps}>
      <FormLabel fontWeight={'semibold'} fontSize={'lg'}>
        {label}
      </FormLabel>
      <Controller
        name={name}
        control={control}
        /**
         * Note Ref: https://react-hook-form.com/api/usecontroller
         * useController in react-hook-form doesn't support value as
         */
        rules={{ required: requiredMessage ?? 'กรุณาเลือกระดับความรู้สึกของคุณ' }}
        render={({ field }) => {
          return <LinearScale numberOfChoices={6} {...field} />;
        }}
      />
    </FormControl>
  );
};

const MentalHealthForm = () => {
  const { currentStep, goToNextPage, goToPreviousPage, addSurveyAnswers, surveyAnswers } =
    useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const {
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<MentalHealthValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: surveyAnswers,
  });

  const handleFormSubmit: SubmitHandler<MentalHealthValueType> = (data: any) => {
    addSurveyAnswers(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={'มาวัดสุขภาพใจกันเถอะ'}
      subtitle={
        'ทบทวนตัวท่านในช่วง 2 สัปดาห์ที่ผ่านมาว่า ข้อความต่อไปนี้ตรงกับตัวท่านมากน้อย เพียงใด ตั้งแต่ระดับ 1 ถึง 6 '
      }
      description={
        'โดยระดับ 1 = ประสบการณ์นั้นไม่จริงกับตัวท่านเลย และไล่ระดับที่จริงกับท่านขึ้นเรื่อยๆ จนถึงระดับ 6 = ประสบการณ์นั้นจริงกับตัวท่านที่สุด'
      }
      navbarProps={{
        title: 'สุขภาวะทางจิตใจ',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep2 />}
      childProps={{ px: 0 }}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Box px={6}>
          <LinearForm label={'ฉันรู้สึกผ่อนคลาย'} name={'1'} control={control} />
          {errors[1] && <ErrorMessage message={errors[1].message} />}

          <LinearForm
            label={'ฉันรู้สึกว่าชีวิตฉันจะมีแต่เรื่องดีๆ เกิดขึ้น'}
            name={'2'}
            control={control}
            mt={6}
          />
          {errors[2] && <ErrorMessage message={errors[2].message} />}

          <LinearForm
            label={'ฉันรู้สึกว่าปัญหาทุกอย่างจะผ่านไป'}
            name={'3'}
            control={control}
            mt={6}
          />
          {errors[3] && <ErrorMessage message={errors[3].message} />}

          <LinearForm label={'ฉันรู้สึกสดชื่นแจ่มใส'} name={'4'} control={control} mt={6} />
          {errors[4] && <ErrorMessage message={errors[4].message} />}

          <LinearForm label={'ฉันรู้สึกสนิทสนมกับคนรอบตัว'} name={'5'} control={control} mt={6} />
          {errors[5] && <ErrorMessage message={errors[5].message} />}

          <LinearForm
            label={'ฉันรู้สึกว่าได้รับความรักจากคนรอบข้าง'}
            name={'6'}
            control={control}
            mt={6}
          />
          {errors[6] && <ErrorMessage message={errors[6].message} />}

          <LinearForm
            label={'ฉันพอใจในความสัมพันธ์กับคนรอบข้าง'}
            name={'7'}
            control={control}
            mt={6}
          />
          {errors[7] && <ErrorMessage message={errors[7].message} />}

          <LinearForm label={'ฉันไม่เคยรู้สึกหมดพลัง'} name={'8'} control={control} mt={6} />
          {errors[8] && <ErrorMessage message={errors[8].message} />}

          <LinearForm label={'ฉันเป็นคนจัดการปัญหาได้ดี'} name={'9'} control={control} mt={6} />
          {errors[9] && <ErrorMessage message={errors[9].message} />}

          <LinearForm
            label={'ฉันสามารถแสดงความคิดเห็นต่อเรื่องต่างๆ ได้อย่างชัดเจน'}
            name={'10'}
            control={control}
            mt={6}
          />
          {errors[10] && <ErrorMessage message={errors[10].message} />}
        </Box>

        <Box px={6}>
          <Button
            mt={6}
            colorScheme={'primary'}
            isLoading={isSubmitting}
            type="submit"
            width={'100%'}
            borderRadius={'100px'}
            disabled={!isDirty || !isValid}
          >
            ถัดไป
          </Button>
        </Box>
      </form>
    </SurveyFormLayout>
  );
};

export default MentalHealthForm;
