import {
  FormControl,
  FormControlProps,
  FormLabel,
  Select as ChakraSelect,
  SelectProps,
} from '@chakra-ui/react';

interface InputInterface extends FormControlProps {
  label: string;
  isRequired?: boolean;
  selectProps?: SelectProps;
  options?: { value: string; label: string }[];
}

const Select = (props: InputInterface) => {
  const { label, options = [], isRequired = false, selectProps, ...restProps } = props;
  return (
    <FormControl isRequired={isRequired} {...restProps}>
      <FormLabel fontWeight={'semibold'} fontSize={'lg'}>
        {label}
      </FormLabel>
      <ChakraSelect focusBorderColor={'primary.500'} placeholder="Select option" {...selectProps}>
        {options.map((option, index) => {
          const { value, label } = option;
          return (
            <option value={value} key={index}>
              {label}
            </option>
          );
        })}
      </ChakraSelect>
    </FormControl>
  );
};

export default Select;
