export const banks = [
  { label: 'ธนาคาร กสิกรไทย', value: 'ธนาคาร กสิกรไทย' },
  { label: 'ธนาคาร กรุงเทพ', value: 'ธนาคาร กรุงเทพ' },
  { label: 'ธนาคาร กรุงไทย', value: 'ธนาคาร กรุงไทย' },
  { label: 'ธนาคาร กรุงศรี', value: 'ธนาคาร กรุงศรี' },
  { label: 'ธนาคาร ซีโอเอ็มบี', value: 'ธนาคาร ซีโอเอ็มบี' },
  { label: 'ธนาคาร ทหารไทยธนชาต', value: 'ธนาคาร ทหารไทยธนชาต' },
  { label: 'ธนาคาร ไทยพาณิชย์', value: 'ธนาคาร ไทยพาณิชย์' },
  { label: 'ธนาคาร ยูโอบี', value: 'ธนาคาร ยูโอบี' },
  { label: 'ธนาคาร แลนด์ แอนด์ เฮ้าส์', value: 'ธนาคาร แลนด์ แอนด์ เฮ้าส์' },
  { label: 'ธนาคาร สแตนดาร์ดชาร์เตอร์ด', value: 'ธนาคาร สแตนดาร์ดชาร์เตอร์ด' },
  { label: 'ธนาคาร ออมสิน', value: 'ธนาคาร ออมสิน' },
  { label: 'ธนาคาร เกียรตินาคินภัทร', value: 'ธนาคาร เกียรตินาคินภัทร' },
  { label: 'ธนาคาร ซิตี้แบงก์', value: 'ธนาคาร ซิตี้แบงก์' },
  { label: 'ธนาคาร อาคารสงเคราะห์', value: 'ธนาคาร อาคารสงเคราะห์' },
  { label: 'ธนาคาร ธ.ก.ส.', value: 'ธนาคาร ธ.ก.ส.' },
  { label: 'ธนาคาร มิซูโฮ', value: 'ธนาคาร มิซูโฮ' },
  { label: 'ธนาคาร อิสลาม', value: 'ธนาคาร อิสลาม' },
  { label: 'ธนาคาร ทิสโก้', value: 'ธนาคาร ทิสโก้' },
  { label: 'ธนาคาร ไอซีบีซี (ไทย)', value: 'ธนาคาร ไอซีบีซี (ไทย)' },
  { label: 'ธนาคาร ไทยเครดิต', value: 'ธนาคาร ไทยเครดิต' },
  { label: 'ธนาคาร ซูมิโตโม มิตซุย', value: 'ธนาคาร ซูมิโตโม มิตซุย' },
  { label: 'ธนาคาร เอชเอสบีซี', value: 'ธนาคาร เอชเอสบีซี' },
  { label: 'ธนาคาร ดอยซ์แบงก์ เอจี', value: 'ธนาคาร ดอยซ์แบงก์ เอจี' },
  { label: 'ธนาคาร แห่งประเทศจีน', value: 'ธนาคาร แห่งประเทศจีน' },
  { label: 'ธนาคาร เอเอ็นแซด', value: 'ธนาคาร เอเอ็นแซด' },
  { label: 'ธนาคาร อินเดียนโอเวอร์ซี', value: 'ธนาคาร อินเดียนโอเวอร์ซี' },
];

export const provinces = [
  { label: 'กรุงเทพมหานคร', value: 'กรุงเทพมหานคร' },
  { label: 'ประจวบคีรีขันธ์', value: 'ประจวบคีรีขันธ์' },
  { label: 'ชลบุรี', value: 'ชลบุรี' },
  { label: 'ภูเก็ต', value: 'ภูเก็ต' },
  { label: 'นครราชสีมา', value: 'นครราชสีมา' },
  { label: 'เชียงใหม่', value: 'เชียงใหม่' },
];

export const levels = [
  { label: 'มากที่สุด', value: 'มากที่สุด' },
  { label: 'มาก', value: 'มาก' },
  { label: 'ปานกลาง', value: 'ปานกลาง' },
  { label: 'น้อย', value: 'น้อย' },
  { label: 'น้อยที่สุด', value: 'น้อยที่สุด' },
];
