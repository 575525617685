import {
  Box,
  BoxProps,
  Button,
  Center,
  Flex,
  FlexProps,
  IconButton,
  Image,
  Spacer,
} from '@chakra-ui/react';
import React, { useState } from 'react';

import { GiHamburgerMenu } from 'react-icons/gi';
import styled from '@emotion/styled';
import { Link, useHistory } from 'react-router-dom';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const NavbarWrapper = styled((props: FlexProps) => (
  <Flex width={'100%'} bg={'white'} margin={'0 auto'} maxWidth={'1280px'} {...props} />
))``;

export interface LandingNavbarInterface extends BoxProps {}

const LandingNavbar: React.FunctionComponent<LandingNavbarInterface> = (props) => {
  const history = useHistory();
  const [isToggled, setIsToggled] = useState(false);

  const onMenuClick =
    (to = '/') =>
    () => {
      setIsToggled(false);
      history.push(to);
    };

  return (
    <NavbarWrapper
      flexDirection={{ base: isToggled ? 'column' : 'row', md: 'row' }}
      px={4}
      py={2}
      {...props}
    >
      <Flex
        px={4}
        flex={1}
        position={'relative'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {/* Navbar logo */}
        <Box as={'button'} width={'175px'} onClick={onMenuClick('/')}>
          <Image src={prefixUrl('/images/myhero-logo.png')} />
        </Box>
        <Spacer px={2} />
        {/* Mobile navbar toggle menu */}
        <Box>
          <IconButton
            display={{ base: 'block', md: 'none' }}
            position={'relative'}
            variant={'unstyled'}
            aria-label={'Navbar menu'}
            icon={
              <Center>
                <GiHamburgerMenu size={24} />
              </Center>
            }
            onClick={() => setIsToggled(!isToggled)}
          />
        </Box>
      </Flex>
      <Flex
        display={{ base: isToggled ? 'flex' : 'none', md: 'flex' }}
        flexDirection={{ base: isToggled ? 'column' : 'row', md: 'row' }}
      >
        <Box p={1} />
        <Button
          variant={'outline'}
          colorScheme={'secondary'}
          fontWeight={'normal'}
          fontSize={'lg'}
          borderRadius={'3xl'}
          px={'2rem'}
          onClick={onMenuClick('/about')}
        >
          เกี่ยวกับโครงการ
        </Button>
        <Box pt={3} pr={3} />
        <Button
          colorScheme={'primary'}
          fontWeight={'normal'}
          fontSize={'lg'}
          borderRadius={'3xl'}
          px={'2rem'}
          onClick={onMenuClick('/survey')}
        >
          กิจกรรม
        </Button>
      </Flex>
    </NavbarWrapper>
  );
};

export default LandingNavbar;
