import { useSurveyAnswerContext, PAGE_NAMES } from '../../Domains/SurveyAnswer/useSurveyContext';
import {
  Consolation,
  MeasurementChanceForm,
  MeasurementDurationForm,
  MeasurementForm,
  MotivationQuote,
  RomeBuildingQuote,
  SmartGoalForm,
  SummaryGoal,
  Topic,
  PrepareForMyCharacter,
} from './Step4';

const SurveyStep4 = () => {
  const { currentStep, currentPage } = useSurveyAnswerContext();

  if (currentStep !== 4) {
    return null;
  }

  // TODO: replace with real page logic
  if (currentPage === PAGE_NAMES.P4_1_START) {
    return <Topic />;
  }
  if (currentPage === PAGE_NAMES.P4_2_FORM_SMART_GOAL) {
    return <SmartGoalForm />;
  }
  if (currentPage === PAGE_NAMES.P4_3_POST_FORM_SMART_GOAL) {
    return <MotivationQuote />;
  }
  if (currentPage === PAGE_NAMES.P4_4_FORM_MEASUREMENT) {
    return <MeasurementForm />;
  }
  if (currentPage === PAGE_NAMES.P4_5_FORM_MEASUREMENT_DURATION) {
    return <MeasurementDurationForm />;
  }
  if (currentPage === PAGE_NAMES.P4_6_FORM_MEASUREMENT_CHANCE) {
    return <MeasurementChanceForm />;
  }
  if (currentPage === PAGE_NAMES.P4_7_POST_FORM_MEASUREMENT_CHANCE) {
    return <RomeBuildingQuote />;
  }
  if (currentPage === PAGE_NAMES.P4_8_SUMMARY) {
    return <SummaryGoal />;
  }
  if (currentPage === PAGE_NAMES.P4_9_CONSOLATION) {
    return <Consolation />;
  }
  if (currentPage === PAGE_NAMES.P4_10_END) {
    return <PrepareForMyCharacter />;
  }

  return <div>Step 4 - Wrong currentPage: {currentPage}</div>;
};

export default SurveyStep4;
