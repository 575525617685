import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { Heading3 } from '../../../Components/Base';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import MessageBox from '../../../Components/MessageBox';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

type InspireInformationType = {
  recoveryPlan?: string;
};

const SummaryMyActions = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();
  const inspireInformation = useSurveyAnswerContext().inspireInformation as InspireInformationType;

  const { recoveryPlan } = inspireInformation;

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'My Growth',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{
        bg: 'linear-gradient(0deg, #C3B8FF 0%, #5242A8 102.42%)',
      }}
    >
      <Flex flexDirection={'column'} pt={10} pb={6} height={'100%'} overflowY={'auto'}>
        <Box px={6}>
          <Text
            fontSize={'2xl'}
            fontWeight={'semibold'}
            color={'white'}
            className={'animate-step-1'}
          >
            สิ่งดีๆ ที่ฉัน
          </Text>
          <Heading3 color={'white'} mt={2} className={'animate-step-2'}>
            จะทำต่อจากนี้
          </Heading3>
        </Box>

        <Box flex={'1 0 auto'} px={6} mt={8}>
          <MessageBox message={`“${recoveryPlan}”`} className={'animate-step-3'} isLargeMessage />
        </Box>

        <Image
          src={prefixUrl('/images/my-growth-3.svg')}
          width={'100%'}
          alignSelf={'center'}
          mt={3}
          className={'animate-step-3'}
        />
        <Box px={6}>
          <Button
            colorScheme="primary"
            width={'100%'}
            borderRadius={'100px'}
            className={'animate-step-3'}
            onClick={goToNextPage}
          >
            ถัดไป
          </Button>
        </Box>
      </Flex>
    </ContentWithNavbarLayout>
  );
};

export default SummaryMyActions;
