import { useEffect, useRef } from 'react';

import { useSurveyAnswerContext, PAGE_NAMES } from '../../Domains/SurveyAnswer/useSurveyContext';
import { Audio } from '../../Components/Base';
import { useSurveyAudioContext, TRACK } from '../../Domains/SurveyAudio/useSuveyAudioContext';

const SurveyAudio = () => {
  const { currentPage } = useSurveyAnswerContext();
  const { handleChangeAudio, handleAudioSound, track, setTrack, isMuted } = useSurveyAudioContext();
  const audioRef = useRef<ReturnType<typeof setInterval>>();

  useEffect(() => {
    if (currentPage === PAGE_NAMES.P7_02_FORM_PERSONAL_INFO) {
      setTrack(TRACK.AT_FIRST_SIGHT);
      handleChangeAudio();
    } else if (currentPage === PAGE_NAMES.P2_1_START) {
      setTrack(TRACK.MY_HEALTH);
      handleChangeAudio();
    } else if (
      currentPage === PAGE_NAMES.P3_01_START ||
      currentPage === PAGE_NAMES.P3_03_VIDEO_FEEDBACK
    ) {
      setTrack(TRACK.MY_IDOL);
      handleChangeAudio();
    } else if (currentPage === PAGE_NAMES.P4_1_START) {
      setTrack(TRACK.MY_GOAL);
      handleChangeAudio();
    } else if (currentPage === PAGE_NAMES.P5_1_START) {
      setTrack(TRACK.MY_CHARACTER);
      handleChangeAudio();
    } else if (currentPage === PAGE_NAMES.P6_01_START) {
      setTrack(TRACK.MY_GROWTH);
      handleChangeAudio();
    }
  }, [currentPage]);

  useEffect(() => {
    if (
      currentPage === PAGE_NAMES.P2_5_END ||
      currentPage === PAGE_NAMES.P3_02_VIDEO ||
      currentPage === PAGE_NAMES.P3_11_END ||
      currentPage === PAGE_NAMES.P4_10_END ||
      currentPage === PAGE_NAMES.P5_6_END ||
      currentPage === PAGE_NAMES.P6_10_SUMMARY_THANK_YOU ||
      currentPage === PAGE_NAMES.P7_05_THANK_YOU
    ) {
      handleAudioSound();
    }
    return () => {
      if (typeof audioRef.current === 'number') {
        clearInterval(audioRef.current);
      }
    };
  }, [currentPage]);

  if (
    currentPage === PAGE_NAMES.P1_1_START ||
    currentPage === PAGE_NAMES.P1_2_CONSENT_FORM ||
    currentPage === PAGE_NAMES.P1_3_FORM_PERSONAL_INFO ||
    currentPage === PAGE_NAMES.P7_01_START
  ) {
    return null;
  }

  return (
    <Audio
      id={'audio-player'}
      songUrl={track}
      isBackgroundAudio
      autoPlay
      loop
      muted={isMuted}
      sourceProps={{ id: 'audio-source' }}
    />
  );
};

export default SurveyAudio;
