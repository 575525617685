export const MY_IDENTITY_CHOICES = [
  { label: 'เชื่อมั่นในตนเอง', value: 'เชื่อมั่นในตนเอง' },
  { label: 'สู้ไม่ถอย', value: 'สู้ไม่ถอย' },
  { label: 'อดทน', value: 'อดทน' },
  { label: 'ไม่ยอมแพ้อะไรง่ายๆ', value: 'ไม่ยอมแพ้อะไรง่ายๆ' },
  { label: 'ยืดหยุ่น', value: 'ยืดหยุ่น' },
  { label: 'ปรับตัวง่าย', value: 'ปรับตัวง่าย' },
  { label: 'ชอบเรียนรู้อะไรใหม่ๆ', value: 'ชอบเรียนรู้อะไรใหม่ๆ' },
  { label: 'คิดริเริ่ม', value: 'คิดริเริ่ม' },
  { label: 'สร้างสรรค์', value: 'สร้างสรรค์' },
  { label: 'มีสติ', value: 'มีสติ' },
  { label: 'มองแง่บวก', value: 'มองแง่บวก' },
  { label: 'ชอบคิดแก้ปัญหา', value: 'ชอบคิดแก้ปัญหา' },
  { label: 'ยอมรับความจริงที่เกิดขึ้น', value: 'ยอมรับความจริงที่เกิดขึ้น' },
  { label: 'มุ่งมั่น', value: 'มุ่งมั่น' },
  { label: 'ยอมรับกับการเปลี่ยนแปลง', value: 'ยอมรับกับการเปลี่ยนแปลง' },
  { label: 'ชอบให้กำลังใจตัวเอง', value: 'ชอบให้กำลังใจตัวเอง' },
  { label: 'พร้อมรับมือปัญหา', value: 'พร้อมรับมือปัญหา' },
];

export const FAMILY_CHOICES = [
  { label: 'พ่อ', value: 'พ่อ' },
  { label: 'แม่', value: 'แม่' },
  { label: 'ลูก', value: 'ลูก' },
  { label: 'พี่น้อง', value: 'พี่น้อง' },
  { label: 'หลาน', value: 'หลาน' },
  { label: 'ญาติ', value: 'ญาติ' },
  { label: 'คู่รัก/แฟน', value: 'คู่รัก/แฟน' },
  { label: 'สามี/ภรรยา', value: 'สามี/ภรรยา' },
];

export const WORK_CHOICES = [
  { label: 'หัวหน้า/เจ้านาย', value: 'หัวหน้า/เจ้านาย' },
  { label: 'ลูกน้อง', value: 'ลูกน้อง' },
  { label: 'เพื่อนร่วมงาน', value: 'เพื่อนร่วมงาน' },
];

export const ORGANIZE_CHOICES = [
  { label: 'ภาครัฐ', value: 'ภาครัฐ' },
  { label: 'ภาคเอกชน', value: 'ภาคเอกชน' },
  { label: 'ภาคประชาชน', value: 'ภาคประชาชน' },
  { label: 'องค์กรไม่แสวงกำไร', value: 'องค์กรไม่แสวงกำไร' },
];
