import { Text, Button } from '@chakra-ui/react';
import { useForm, SubmitHandler, Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';

import { Input, Select } from '../../../Components/Base';
import { SurveyFormLayout } from '../../../Components/Layout';
import { Survey30DaysPersonalFormBackground } from '../../../Components/SurveyBackgroundImage/Survey30DaysPersonalFormBackground';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';

import { useStoreData } from '../../../Domains/useStoreData';
import { levels, provinces } from './utils';

type ErrorMessageProps = {
  message?: string;
};

const ErrorMessage = ({ message }: ErrorMessageProps) => (
  <Text color={'red.500'} fontSize={'xs'} mt={2}>
    {message}
  </Text>
);

type PersonalInformationValueType = {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  province: string;
  level: string;
  // TODO: Add field type for the rest input
};

const PersonalInformation = () => {
  // TODO: Create the state of 30 days personal information
  const { personalInformation, survey30DaysAnswers, addSurvey30DaysAnswers, goToNextPage } =
    useSurveyAnswerContext();
  const { onStoreSurvey30Days } = useStoreData();
  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors, isValid },
  } = useForm<PersonalInformationValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: personalInformation,
  });

  const handleFormSubmit: SubmitHandler<PersonalInformationValueType> = (data: any) => {
    addSurvey30DaysAnswers(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={'ข้อมูลผู้ร่วมกิจกรรม'}
      subtitle={'กรุณากรอกข้อมูลให้ครบ'}
      hasShowNavbar={false}
      imageBackground={<Survey30DaysPersonalFormBackground />}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)}>
        <Input
          label={'ชื่อ'}
          inputProps={{
            type: 'text',
            placeholder: 'กรอกชื่อจริง',
            ...register('firstName', { required: 'กรุณากรอกชื่อของคุณ' }),
          }}
          color={'text.heading'}
        />
        {errors.firstName && <ErrorMessage message={errors.firstName.message} />}

        <Input
          label={'นามสกุล'}
          inputProps={{
            type: 'text',
            placeholder: 'กรอกนามสกุลจริง',
            ...register('lastName', { required: 'กรุณากรอกนามสกุลของคุณ' }),
          }}
          color={'text.heading'}
          mt={6}
        />
        {errors.lastName && <ErrorMessage message={errors.lastName.message} />}

        <Input
          label={'อีเมล'}
          inputProps={{
            type: 'text',
            placeholder: 'กรอกอีเมล',
            ...register('email', { required: 'กรุณากรอกอีเมลของคุณ' }),
          }}
          color={'text.heading'}
          mt={6}
        />
        {errors.email && <ErrorMessage message={errors.email.message} />}

        <Controller
          name={'mobileNumber'}
          control={control}
          rules={{
            minLength: {
              value: 10,
              message: 'กรุณากรอกเบอร์มือถือของคุณให้ถูกต้อง',
            },
            maxLength: {
              value: 10,
              message: 'กรุณากรอกเบอร์มือถือของคุณให้ถูกต้อง',
            },
            required: 'กรุณากรอกเบอร์มือถือ',
          }}
          render={({ field: { onChange, value } }) => (
            <InputMask
              mask={'999 999 9999'}
              maskPlaceholder={null}
              value={value}
              onChange={(e) => onChange(e.target.value.replaceAll(' ', ''))}
            >
              <Input
                label={'เบอร์มือถือ'}
                inputProps={{
                  type: 'tel',
                  placeholder: 'กรอกเบอร์มือถือ',
                  textDecoration: 'none',
                }}
                color={'text.heading'}
                mt={6}
              />
            </InputMask>
          )}
        />
        {errors.mobileNumber && <ErrorMessage message={errors.mobileNumber.message} />}
        <Select
          label={'จังหวัดที่ท่านทำงานอยู่'}
          options={provinces}
          selectProps={{
            placeholder: 'เลือกจังหวัด',
            ...register('province', { required: 'กรุณาเลือกจังหวัดที่ท่านทำงานอยู่' }),
          }}
          color={'text.heading'}
          mt={6}
        />
        {errors.province && <ErrorMessage message={errors.province.message} />}
        <Select
          label={
            'ใน 1 เดือนที่ผ่านมา ท่านนำสิ่งที่ได้เรียนรู้จาก “MY HERO คู่มือสร้างสุขภาพใจ ด้วยฮีโร่ในตัวคุณภายใต้สถานการณ์ COVID-19” ไปใช้ในชีวิตมากน้อยเพียงใด'
          }
          options={levels}
          selectProps={{
            placeholder: 'เลือกระดับ',
            ...register('level', { required: 'กรุณาเลือกระดับ' }),
          }}
          color={'text.heading'}
          mt={6}
        />
        {errors.level && <ErrorMessage message={errors.level.message} />}
        <Button
          mt={6}
          colorScheme={'primary'}
          isLoading={isSubmitting}
          type="submit"
          width={'100%'}
          borderRadius={'100px'}
          disabled={!isValid}
        >
          ถัดไป
        </Button>
      </form>
    </SurveyFormLayout>
  );
};

export default PersonalInformation;
