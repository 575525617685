import { ReactChild } from 'react';
import { keyframes, css } from '@emotion/react';
import styled from '@emotion/styled';
import { Flex, Box, BoxProps } from '@chakra-ui/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const resizeTransition = keyframes`
  0% {
    width: 100vw;
    height: 100vh;
  }
  100% {
    width: 360px;
    height: 680px;
  }
`;

const visibilityTransition = keyframes`
  0%, 80% {
    opacity: 0;
    visibility: hidden;
  }
  100% {
    opacity: 1;
    visibility: visible;
  }
`;

const MobileLayoutWrapper = styled(Box)<{ isAnimated?: boolean }>`
  & > :nth-child(1) {
    background-color: white;
    width: 100%;
    height: 100%;
    overflow: auto;
    ${({ isAnimated }) =>
      isAnimated &&
      css`
        @media screen and (min-width: 768px) {
          animation: ${visibilityTransition} 6s ease-in-out;
        }
      `}
  }
`;

interface MobileBoxLayoutInterface extends BoxProps {
  isAnimated?: boolean;
  children: ReactChild;
}

const MobileBoxLayout = (props: MobileBoxLayoutInterface) => {
  const { children, isAnimated = true, ...restProps } = props;

  return (
    <Flex
      backgroundImage={{
        base: prefixUrl('/images/ipad-wallpaper.svg'),
        lg: prefixUrl('/images/wallpaper.svg'),
      }}
      backgroundSize={'cover'}
      backgroundRepeat={'no-repeat'}
      backgroundPosition={'center'}
      width={'100vw'}
      height={'100vh'}
      overflow={'hidden'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <MobileLayoutWrapper
        width={['100%', null, 'min(360px,100vw)']}
        height={['100%', null, 'min(680px,100vh)']}
        backgroundColor={'white'}
        overflow={'auto'}
        animation={[null, null, isAnimated ? `${resizeTransition} 5s ease-in-out` : null]}
        transitionDuration={'5s'}
        isAnimated={isAnimated}
        {...restProps}
      >
        {children}
      </MobileLayoutWrapper>
    </Flex>
  );
};

export default MobileBoxLayout;
