interface AudioInterface extends React.AudioHTMLAttributes<HTMLAudioElement> {
  songUrl: string;
  isBackgroundAudio?: boolean;
  sourceProps?: object;
}

const Audio = (props: AudioInterface) => {
  const { songUrl, isBackgroundAudio, sourceProps, ...restProps } = props;

  return (
    <audio style={{ display: isBackgroundAudio ? 'none' : 'inherit' }} {...restProps}>
      <source src={songUrl} type={'audio/mpeg'} {...sourceProps} />
    </audio>
  );
};

export default Audio;
