import ReactPlayer, { ReactPlayerProps } from 'react-player';

interface VideoPlayerInterface extends ReactPlayerProps {
  src: string;
}

const VideoPlayer = (props: VideoPlayerInterface) => {
  const { src, ...restProps } = props;
  return (
    <ReactPlayer
      url={src}
      controls
      config={{
        youtube: {
          playerVars: { showinfo: 1 },
        },
      }}
      {...restProps}
    />
  );
};

export default VideoPlayer;
