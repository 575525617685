import { useMemo } from 'react';
import { Box, Button, Flex, Text, Image } from '@chakra-ui/react';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import RippleBox from '../../../Components/RippleBox';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import Survey30DaysResultBackground from '../../../Components/SurveyBackgroundImage/Survey30DaysResultBackground';
import { useStoreData } from '../../../Domains/useStoreData';

const getTitle = (result: number) => {
  if (result <= 31) {
    return `ช่วงนี้คุณมีปัญหา
    เข้ามารบกวนจิตใจคุณอยู่บ่อยๆ`;
  } else if (result > 31 && result <= 50) {
    return 'ช่วงนี้คุณมีสุขภาพใจที่ดีอยู่';
  } else if (result > 50) {
    return `ช่วงนี้คุณมีสุขภาพใจ
    ที่ยอดเยี่ยมมาก!`;
  }
};

const getDescription = (result: number) => {
  if (result <= 31) {
    return `แต่เชื่อว่าหลังจากนี้ทุกๆ วัน
    มันจะดีขึ้นไปเรื่อยๆ จะคอยเป็นกำลังใจให้นะ`;
  } else if (result > 31 && result <= 50) {
    return `และหวังว่ามันจะดียิ่งขึ้นไปเรื่อยๆ นะ`;
  } else if (result > 50) {
    return `โปรดรักษาความรู้สึกดีๆ
    ให้คงอยู่กับคุณแบบนี้ตลอดไปเลยนะ`;
  }
};

const MentalHealthResult = () => {
  const { goToNextPage, addSurvey30DaysAnswers, survey30DaysAnswers } = useSurveyAnswerContext();
  const { onStoreSurvey30Days } = useStoreData();

  const result = useMemo(() => {
    let _answers: string[] = Object.values(survey30DaysAnswers);
    let _answersValues: number[] = [];
    _answersValues = _answers.slice(0, 10).map((numStr) => Number.parseInt(numStr));
    const _result = _answersValues.reduce((value1, value2) => value1 + value2);

    addSurvey30DaysAnswers({ surveyResult: _result });
    return _result;
  }, []);

  return (
    <ContentWithNavbarLayout
      hasShowNavbar={false}
      childProps={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Flex
        position={'relative'}
        bg={'hsl(37, 80%, 94%)'}
        alignItems={'center'}
        minHeight={'300px'}
        flex={'0 0 auto'}
        flexDirection={'column'}
        px={6}
        py={12}
      >
        <Box
          position={'absolute'}
          top={0}
          left={0}
          width={'100%'}
          height={'100%'}
          overflow={'hidden'}
        >
          <Survey30DaysResultBackground />
        </Box>
        <Flex flexDirection={'column'} alignItems={'center'} position={'relative'}>
          <Text fontSize={'2xl'} fontWeight={'bold'} color={'hsl(227, 60%, 33%)'}>
            ผลคะแนนของฉัน
          </Text>
        </Flex>
        <Flex position={'absolute'} bottom={0} left={'50%'} transform={'translate(-50%, 50%)'}>
          <RippleBox message={result} flex={'0 0 auto'} textStyles={{ fontSize: '5xl' }} />
        </Flex>
      </Flex>
      <Box pt={'100px'} pb={5} px={6} flex={'1 0 auto'}>
        <Flex height={'100%'} flexDirection={'column'} flex={1} justifyContent={'space-between'}>
          <Flex flexDirection={'column'} alignItems={'center'}>
            <Text
              as={'h2'}
              fontSize={'3xl'}
              fontWeight={'bold'}
              textAlign={'center'}
              whiteSpace={'pre-line'}
              color={'hsl(227, 59%, 23%)'}
            >
              {getTitle(result)}
            </Text>
            <Text mt={4} fontSize={'lg'} textAlign={'center'} whiteSpace={'pre-line'}>
              {getDescription(result)}
            </Text>
          </Flex>
          <Button
            mt={6}
            colorScheme={'primary'}
            width={'100%'}
            borderRadius={'100px'}
            onClick={() => {
              onStoreSurvey30Days();
              goToNextPage();
            }}
          >
            ถัดไป
          </Button>
        </Flex>
      </Box>
    </ContentWithNavbarLayout>
  );
};

export default MentalHealthResult;
