import { Box, Flex, FlexProps, TextProps } from '@chakra-ui/react';

import { Body1 } from '../Base';

const TellingCardWrapper = (props: FlexProps) => (
  <Flex
    flexDirection={'column'}
    maxWidth={'260px'}
    minHeight={'260px'}
    height={'100%'}
    borderRadius={'16px'}
    justifyContent={'space-between'}
    bg={'white'}
    mx={'auto'}
    p={6}
    {...props}
  />
);

const Message = (props: TextProps) => <Body1 textAlign={'left'} {...props} />;

interface TellingCardInterface {
  message: string;
  name: string;
  age: number;
  workCategory: string;
}

const TellingCard = (props: TellingCardInterface) => {
  const { message, name, age, workCategory } = props;

  return (
    <TellingCardWrapper>
      <Message>{message}</Message>
      <Box>
        <Message fontWeight={'bold'}>{`${name}, ${age}`}</Message>
        <Message>{workCategory}</Message>
      </Box>
    </TellingCardWrapper>
  );
};

export default TellingCard;
