import { Box, Flex, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { prefixUrl } from '../../Utils/mapAssetUrl';
import { Body1, Heading4, Heading5 } from '../Base';
import LandingWrapper from '../LandingWrapper';

const FooterWrapper = styled(Box)`
  background-color: hsl(227, 86%, 16%);
  color: white;
`;

const SubFooterWrapper = styled(Box)`
  background-color: hsl(0, 0%, 95%);
`;

const Footer = () => (
  <Box>
    <SubFooterWrapper px={8}>
      <LandingWrapper flexDirection={{ base: 'column', md: 'row' }} py={10} display={'flex'}>
        <Heading4 flex={'0 0 auto'}>จัดทำโดย</Heading4>
        <Box px={8} py={4} />
        <Flex>
          <Image
            src={prefixUrl('/images/thai-health-logo.svg')}
            height={'80px'}
            width={'auto'}
            maxWidth={'90px'}
          />
          <Box p={4} />
          <Image
            src={prefixUrl('/images/swu-logo.svg')}
            height={'80px'}
            width={'auto'}
            maxWidth={'80px'}
          />
        </Flex>
        <Box p={4} />
        <Box>
          <Heading5 whiteSpace={'pre-line'}>
            {`สำนักงานกองทุนสนับสนุนการสร้างเสริมสุขภาพ (สสส.)
            และ สถาบันวิจัยพฤติกรรมศาสตร์ มศว`}
          </Heading5>
          <Body1 mt={2} fontWeight={'normal'}>
            ปรึกษาเรื่องสุขภาพใจ ติดต่อได้ที่สายด่วนสุขภาพจิต <Heading4 as={'span'}>1323</Heading4>
          </Body1>
        </Box>
      </LandingWrapper>
    </SubFooterWrapper>
    <FooterWrapper px={8}>
      <LandingWrapper
        flexDirection={{ base: 'column', sm: 'row' }}
        py={3}
        display={'flex'}
        justifyContent={'space-between'}
        textAlign={'center'}
      >
        <Body1>© 2021 All rights reserved</Body1>
        <Body1>Designed by Skooldio</Body1>
      </LandingWrapper>
    </FooterWrapper>
  </Box>
);

export default Footer;
