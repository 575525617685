import { Box, Button, Flex, Image } from '@chakra-ui/react';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import MessageBox from '../../../Components/MessageBox';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

import {
  SummaryFirstHeader,
  SummarySecondHeader,
  SummaryThirdHeader,
} from './SummarySupportiveHeader';

type MuIdentityType = {
  workSupportive?: string[];
  familySupportive?: string[];
  organizeSupportive?: string[];
};

const SummaryOrganizeSupportive = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();
  const myIdentity = useSurveyAnswerContext().myIdentity as MuIdentityType;

  const { familySupportive, workSupportive, organizeSupportive = [] } = myIdentity;

  const renderSupportive = () => {
    let _message = '';

    organizeSupportive.map((item) => {
      _message = `${_message} ${item}`;
    });

    return _message.trim();
  };

  const renderSupportiveHeader = () => {
    if (familySupportive?.length === 0 && workSupportive?.length === 0) {
      return <SummaryFirstHeader px={6} />;
    }
    // Have either family supportive or work supportive
    if (
      (familySupportive?.length === 0 && workSupportive?.length !== 0) ||
      (familySupportive?.length !== 0 && workSupportive?.length === 0)
    ) {
      return <SummarySecondHeader px={6} />;
    }
    return <SummaryThirdHeader px={6} />;
  };

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'My Growth',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{
        bg: 'linear-gradient(0deg, #C8F9D5 0%, #2B9B8B 100%)',
      }}
    >
      <Flex flexDirection={'column'} pt={10} pb={6} height={'100%'} overflowY={'auto'}>
        {renderSupportiveHeader()}

        <Box flex={'1 0 auto'} px={6} mt={8}>
          <MessageBox
            message={renderSupportive()}
            className={'animate-step-2'}
            subMessage={`ที่ช่วยเหลือเยียวยา สนับสนุน
            ของที่จำเป็น และคอยให้ข้อมูล
            ข่าวสารฉันอยู่เสมอ`}
            subMessageProps={{
              bg: 'linear-gradient(180deg, #18275E 0%, #2B9B8B 100%)',
            }}
            isLargeMessage
          />
        </Box>

        <Image
          src={prefixUrl('/images/my-growth-6.svg')}
          width={'100%'}
          alignSelf={'center'}
          mt={3}
          className={'animate-step-2'}
        />
        <Box px={6}>
          <Button
            colorScheme="primary"
            width={'100%'}
            borderRadius={'100px'}
            className={'animate-step-2'}
            onClick={goToNextPage}
          >
            ถัดไป
          </Button>
        </Box>
      </Flex>
    </ContentWithNavbarLayout>
  );
};

export default SummaryOrganizeSupportive;
