import { useState } from 'react';
import { Box, Flex, Button, Text } from '@chakra-ui/react';
import { ColorModeSwitcher } from '../../Components/ChakraExample/ColorModeSwitcher';

import LinearScale from '../../Components/LinearScale';
import Selection from '../../Components/Selection';
import { PersonalInformation } from '../../Container/Survey/Step1';
import { Progress } from '../../Components/Base';
import HorizontalScroll from '../../Components/HorizontalScroll';
import { Card, CARD_TITLE_POSITION } from '../../Components/Card';
import VideoPlayer from '../../Components/VideoPlayer';

const mockChoices = [
  { label: 'เนื้อหา', value: 'content' },
  { label: 'โจทย์หรือแบบฝึกหัด', value: 'exercise' },
  { label: 'เคสตัวอย่างที่ใช้ประกอบ', value: 'example' },
  { label: 'ผู้สอน', value: 'instructor' },
  { label: 'สื่อประกอบการเรียน', value: 'presentation' },
];

const TOTAL_STEPS = 5;

function Playground() {
  const [currentStep, setCurrentStep] = useState<number>(0);

  const handleNextStep = () => {
    if (currentStep >= TOTAL_STEPS) {
      return;
    }
    setCurrentStep(currentStep + 1);
  };
  const handlePrevStep = () => {
    if (currentStep <= 0) {
      return;
    }
    setCurrentStep(currentStep - 1);
  };

  return (
    <Box fontSize="xl">
      <Flex flexDirection={'column'}>
        <ColorModeSwitcher alignSelf="flex-end" />
        <Box pt={4} />
        <Text>Linear Scale Component</Text>
        <LinearScale numberOfChoices={6} />
        <Box pt={4} />
        <Text>Selection 3 Choices</Text>
        <Selection
          answerableCount={3}
          choices={mockChoices}
          onChange={(value) => console.log('value:', value)}
          selectProps={{
            mr: 4,
            mb: 4,
          }}
        />
        <Box pt={4} />
        <Text>Form Control Personal Information</Text>
        <PersonalInformation />
        <Box pt={4} />
        <Text>Progress Bar</Text>
        <Box>
          <Progress currentStep={currentStep} totalSteps={TOTAL_STEPS} />
          <Flex mt={2} justifyContent={'space-between'}>
            <Button onClick={handlePrevStep}>{`< Prev Step`}</Button>
            <Button onClick={handleNextStep}>{`Next Step >`}</Button>
          </Flex>
        </Box>
        <Box pt={4} />
        <Text>Scroll Card</Text>
        <HorizontalScroll>
          {Array.from({ length: 6 }).map((item, index) => (
            <Card
              imageUrl={'https://picsum.photos/360'}
              title={
                'ผลกระทบที่เกิดขึ้นจากวิกฤตในครั้งนี้ ส่งผลเป็นวงกว้างกับทุก ภาคส่วน ร้านค้า กิจการทั้งราย เล็ก รายใหญ่ ต้องปิดตัวลงหรือ เลื่อนอย่างไม่มีกำหนด'
              }
              titlePosition={
                (index + 1) % 2 === 0 ? CARD_TITLE_POSITION.BOTTOM : CARD_TITLE_POSITION.TOP
              }
            />
          ))}
        </HorizontalScroll>
        <Box pt={4} />
        <Text>Card</Text>
        <Card
          imageUrl={'https://picsum.photos/360'}
          title={
            'ผลกระทบที่เกิดขึ้นจากวิกฤตในครั้งนี้ ส่งผลเป็นวงกว้างกับทุก ภาคส่วน ร้านค้า กิจการทั้งราย เล็ก รายใหญ่ ต้องปิดตัวลงหรือ เลื่อนอย่างไม่มีกำหนด'
          }
        />
        <Text>Video Player</Text>
        <VideoPlayer
          src={'https://www.youtube.com/watch?v=sjb0g9oKg5g'}
          width={'100%'}
          height={'250px'}
        />
      </Flex>
    </Box>
  );
}

export default Playground;
