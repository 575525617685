import { Fragment } from 'react';
import { Flex, FlexProps, Box, BoxProps, Text, Icon } from '@chakra-ui/react';
import { HiArrowNarrowRight } from 'react-icons/hi';
import { keyframes, useTheme } from '@emotion/react';

const shake = keyframes`
  0% {
    transform: translateX(0%);
  }
  50% {
    transform: translateX(-30%);
  }
  100% {
    transform: translateX(0%);
  }
`;

export enum CARD_TITLE_POSITION {
  TOP = 'top',
  BOTTOM = 'bottom',
}

export interface CardInterface extends FlexProps {
  title?: string;
  description?: string;
  credit?: string;
  imageUrl: string;
  isAnimateArrowRight?: boolean;
  titlePosition?: CARD_TITLE_POSITION | undefined;
  backgroundBoxProps?: FlexProps;
  cardHeight?: string;
  cardMaxHeight?: [string | null, string | null, string | null];
}

type BlackGradientProps = {
  titlePosition: CARD_TITLE_POSITION;
} & BoxProps;

const BlackGradient = (props: BlackGradientProps) => {
  const { titlePosition, ...restProps } = props;
  switch (titlePosition) {
    case CARD_TITLE_POSITION.BOTTOM: {
      return (
        <Box
          background={'linear-gradient(0, hsla(0, 0%, 0%, 100%), hsla(0, 0%, 0%, 0%))'}
          bottom={0}
          left={0}
          {...restProps}
        />
      );
    }
    case CARD_TITLE_POSITION.TOP:
    default: {
      return (
        <Box
          background={'linear-gradient(0, hsla(0, 0%, 0%, 0%), hsla(0, 0%, 0%, 100%))'}
          top={0}
          left={0}
          {...restProps}
        />
      );
    }
  }
};

const Card = (props: CardInterface) => {
  const {
    title,
    description,
    credit,
    imageUrl,
    isAnimateArrowRight,
    titlePosition = CARD_TITLE_POSITION.TOP,
    backgroundBoxProps,
    cardHeight,
    cardMaxHeight,
    ...restProps
  } = props;
  const { space } = useTheme();

  return (
    <Box {...restProps}>
      <Flex
        mx={1}
        p={6}
        borderRadius={24}
        position={'relative'}
        overflow={'hidden'}
        backgroundImage={imageUrl}
        backgroundPosition={'center'}
        backgroundRepeat={'no-repeat'}
        backgroundSize={'100% 100%'}
        height={
          cardHeight
            ? cardHeight
            : `calc(100vh - ${space.NAVBAR_HEIGHT} - ${space.FOOTER_BUTTON_HEIGHT_WITH_SPACE})`
        }
        maxHeight={cardMaxHeight ? cardMaxHeight : [null, null, '500px']}
        alignItems={titlePosition === CARD_TITLE_POSITION.BOTTOM ? 'flex-end' : 'flex-start'}
        {...backgroundBoxProps}
      >
        <Flex
          flexDirection={'column'}
          justifyContent={titlePosition === CARD_TITLE_POSITION.TOP ? 'space-between' : 'flex-end'}
          height={'100%'}
        >
          {title && (
            <Fragment>
              <BlackGradient
                width={'100%'}
                height={'100%'}
                maxHeight={'264px'}
                position={'absolute'}
                titlePosition={titlePosition}
              />
              <Box zIndex={'docked'}>
                <Text fontWeight={'bold'} fontSize={'3xl'} color={'white'}>
                  {title}
                </Text>
                <Text fontWeight={'bold'} fontSize={'xl'} color={'white'} mt={2}>
                  {description}
                </Text>
              </Box>
            </Fragment>
          )}
          {credit && (
            <Text
              fontSize={'sm'}
              color={'white'}
              zIndex={'docked'}
              mt={2}
              mb={isAnimateArrowRight ? { base: 6, lg: 0 } : 0}
              opacity={0.7}
            >
              {credit}
            </Text>
          )}
        </Flex>
        <Box display={{ base: 'block', lg: 'none' }}>
          {isAnimateArrowRight && (
            <Flex position={'absolute'} alignItems={'center'} bottom={4} right={'15px'}>
              <Text color={'white'} fontSize={'sm'} mr={4}>
                เลื่อน
              </Text>
              <Icon
                as={HiArrowNarrowRight}
                color={'white'}
                width={8}
                height={8}
                animation={`${shake} 1s cubic-bezier(.17,.67,.83,.67) infinite`}
              />
            </Flex>
          )}
        </Box>
      </Flex>
    </Box>
  );
};

export default Card;
