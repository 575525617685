import { Image, Box } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const zoom = keyframes`
  0% {
    transform: scale(50%);
  }
  50% {
    transform: scale(100%);
  }
  100% {
    transform: scale(50%);
  }
`;

const SurveyResultBackground = () => {
  return (
    <Box
      background="linear-gradient(180deg, #FCF3E4 65.58%, #FFFDF9 95.14%)"
      height={'100%'}
      width={'100%'}
    >
      <Image
        src={prefixUrl('/images/survey2-result-object.svg')}
        height={'100%'}
        maxWidth={'unset'}
        position={'relative'}
        left={'50%'}
        transform={'translateX(-50%)'}
      />
      <Image
        src={prefixUrl('/images/wink.png')}
        width={'24px'}
        position={'absolute'}
        top={'55%'}
        left={'10%'}
        animation={`${zoom} 1.5s infinite cubic-bezier(.17,.67,.83,.67)`}
      />
      <Image
        src={prefixUrl('/images/wink.png')}
        width={'24px'}
        position={'absolute'}
        top={'10%'}
        left={'30%'}
        animation={`${zoom} 1s infinite cubic-bezier(.17,.67,.83,.67)`}
      />
      <Image
        src={prefixUrl('/images/wink.png')}
        width={'24px'}
        position={'absolute'}
        top={'30%'}
        right={'5%'}
        animation={`${zoom} 2s infinite cubic-bezier(.17,.67,.83,.67)`}
      />
    </Box>
  );
};

export default SurveyResultBackground;
