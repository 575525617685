import { Box, useTheme } from '@chakra-ui/react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import Slider, { Settings } from 'react-slick';

import { Heading5 } from '../../../../Components/Base';
import LandingWrapper from '../../../../Components/LandingWrapper';
import TellingCard from '../../../../Components/TellingCard';

import { smartGoals } from './Utils';

interface ArrowProps {
  onClick?: React.MouseEventHandler;
  className?: string;
  style?: object;
}

interface ArrowInterface extends React.FunctionComponent<ArrowProps> {}

const NextArrow: ArrowInterface = ({ onClick, className, style }) => {
  const { colors } = useTheme();
  return (
    <BsChevronRight
      className={className}
      style={{ ...style, color: colors.secondary[600], width: '4rem', height: '4rem' }}
      onClick={onClick}
    />
  );
};

const PrevArrow: ArrowInterface = ({ onClick, className, style }) => {
  const { colors } = useTheme();
  return (
    <BsChevronLeft
      className={className}
      style={{ ...style, color: colors.secondary[600], width: '4rem', height: '4rem', zIndex: 1 }}
      onClick={onClick}
    />
  );
};

const settings: Settings = {
  dots: false,
  infinite: true,
  adaptiveHeight: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  responsive: [
    {
      breakpoint: 960,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

interface TellingSectionInterface {
  id?: string;
}

// interface TellingSectionInterface extends React.FunctionComponent<TellingSectionProps> {}

const getAge = (yearOfBirth: string) => {
  const currentYear = new Date().getFullYear() + 543;
  const _yearOfBirth = Number.parseInt(yearOfBirth);
  return currentYear - _yearOfBirth;
};

const TellingSection = (props: TellingSectionInterface) => {
  const { id } = props;
  return (
    <Box px={10} pt={10} pb={'5.5rem'} background={'#E8F3FD'} id={id}>
      <LandingWrapper color={'secondary.600'} textAlign={'center'}>
        <Heading5 pb={6}>เพื่อน ๆ ตั้งเป้าฝ่าวิกฤต COVID-19 อย่างไรบ้าง?</Heading5>
        <Slider {...settings}>
          {smartGoals.map((smartGoal, index) => {
            const { message, name, yearOfBirth, workCategory } = smartGoal;
            const age = getAge(yearOfBirth);
            return (
              <TellingCard
                key={`${index}-${name}-${age}`}
                message={message}
                name={name}
                age={age}
                workCategory={workCategory}
              />
            );
          })}
        </Slider>
      </LandingWrapper>
    </Box>
  );
};

export default TellingSection;
