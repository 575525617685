import { Text, TextProps } from '@chakra-ui/react';

import styled from '@emotion/styled';

export const Heading1 = styled((props: TextProps) => (
  <Text as={'h1'} fontSize={'6xl'} lineHeight={'1.167em'} fontWeight={'semibold'} {...props} />
))``;

export const Heading2 = styled((props: TextProps) => (
  <Text as={'h2'} fontSize={'5xl'} lineHeight={'1.167em'} fontWeight={'semibold'} {...props} />
))``;

export const Heading3 = styled((props: TextProps) => (
  <Text as={'h3'} fontSize={'2.125rem'} lineHeight={'1.294em'} fontWeight={'semibold'} {...props} />
))``;

export const Heading4 = styled((props: TextProps) => (
  <Text as={'h4'} fontSize={'2xl'} lineHeight={'1.333em'} fontWeight={'semibold'} {...props} />
))``;

export const Heading5 = styled((props: TextProps) => (
  <Text as={'h5'} fontSize={'xl'} lineHeight={'1.4em'} fontWeight={'semibold'} {...props} />
))``;

export const Body1 = styled((props: TextProps) => (
  <Text fontSize={'lg'} lineHeight={'1.55em'} {...props} />
))``;

export const Body2 = styled((props: TextProps) => (
  <Text fontSize={'sm'} lineHeight={'1.714em'} {...props} />
))``;

export const Body3 = styled((props: TextProps) => <Text fontSize={'xs'} {...props} />)``;

export const SubTitle = styled((props: TextProps) => (
  <Text fontSize={'lg'} lineHeight={'1.44em'} {...props} />
))``;

export const Caption = styled((props: TextProps) => (
  <Text fontSize={'sm'} lineHeight={'1.286em'} {...props} />
))``;
