import { useMemo, useState } from 'react';
import useSurveyAnswerContext from '../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../Utils/mapAssetUrl';

import {
  STEP1_IMAGE_LIST,
  STEP2_IMAGE_LIST,
  STEP3_IMAGE_LIST,
  STEP4_IMAGE_LIST,
  STEP5_IMAGE_LIST,
  STEP6_IMAGE_LIST,
  STEP7_IMAGE_LIST,
} from './Utils';

const setImageList = (currentStep: number) => {
  switch (currentStep) {
    case 1: {
      return STEP1_IMAGE_LIST;
    }
    case 2: {
      return STEP2_IMAGE_LIST;
    }
    case 3: {
      return STEP3_IMAGE_LIST;
    }
    case 4: {
      return STEP4_IMAGE_LIST;
    }
    case 5: {
      return STEP5_IMAGE_LIST;
    }
    case 6: {
      return STEP6_IMAGE_LIST;
    }
    case 7: {
      return STEP7_IMAGE_LIST;
    }
    default: {
      return [];
    }
  }
};

const PreloadImage = () => {
  const { currentStep } = useSurveyAnswerContext();
  const [loaded, setLoaded] = useState(0);

  const imageList = useMemo(() => setImageList(currentStep), [currentStep]);

  const handleLoad = () => {
    const loadedCount = loaded + 1;
    setLoaded(() => {
      // console.log('handleLoad', loadedCount, 'of total:', imageList.length); // Try to test preload count
      return loadedCount;
    });
  };

  return (
    <div style={{ visibility: 'hidden', height: 0, overflow: 'hidden' }}>
      {imageList.map((imageSrc, index) => (
        <img
          key={`${index}_${imageSrc}`}
          src={prefixUrl(imageSrc)}
          onLoad={handleLoad}
          alt="preload"
        />
      ))}
    </div>
  );
};

export default PreloadImage;
