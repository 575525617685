import { useMemo } from 'react';
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { Heading3 } from '../../../Components/Base';
import MessageBox from '../../../Components/MessageBox';
import { prefixUrl } from '../../../Utils/mapAssetUrl';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

type PersonalInformationType = {
  name?: string;
  yearOfBirth?: string;
};

type MuIdentityType = {
  myGoodThings?: string[];
};

const SummaryIAm = () => {
  const { currentStep, goToNextPage } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();
  const personalInformation = useSurveyAnswerContext()
    .personalInformation as PersonalInformationType;
  const myIdentity = useSurveyAnswerContext().myIdentity as MuIdentityType;

  const { name = '', yearOfBirth = '' } = personalInformation;
  const { myGoodThings = [] } = myIdentity;

  const age = useMemo(() => {
    const currentYear = new Date().getFullYear() + 543;
    const _yearOfBirth = typeof yearOfBirth === 'string' ? Number.parseInt(yearOfBirth) : 2483;
    return currentYear - _yearOfBirth;
  }, [yearOfBirth]);

  const renderMyGoodThings = () => {
    let _message = '';
    myGoodThings.map((item) => {
      _message = `${_message} ${item}`;
    });

    return _message.trim();
  };

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'My Growth',
        currentStep,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{
        bg: 'linear-gradient(0deg, #FFEAB6 0%, #EE8C1A 100%)',
      }}
    >
      <Flex
        flexDirection={'column'}
        justifyContent={'space-between'}
        alignContent={'center'}
        px={6}
        pt={10}
        pb={6}
        height={'100%'}
        overflowY={'auto'}
      >
        <Box flex={'1 0 auto'}>
          <Text
            fontSize={'2xl'}
            fontWeight={'semibold'}
            color={'white'}
            className={'animate-step-1'}
          >
            {`ฉันคือ${name} ตอนนี้อายุ ${age} ปี แม้ว่าจะอยู่ท่ามกลางวิกฤตที่กำลังเผชิญ`}
          </Text>

          <Heading3
            mt={2}
            color={'white'}
            className={'animate-step-2'}
          >{`แต่ฉันก็ยังคงเป็นคนที่`}</Heading3>

          <Box mt={7}>
            <MessageBox
              message={renderMyGoodThings()}
              className={'animate-step-3'}
              isLargeMessage
            />
          </Box>
        </Box>

        <Image
          src={prefixUrl('/images/my-growth-1.svg')}
          width={'300px'}
          alignSelf={'center'}
          mt={4}
          className={'animate-step-3'}
        />

        <Button
          mt={6}
          colorScheme="primary"
          flex={'0 0 auto'}
          onClick={goToNextPage}
          width={'100%'}
          borderRadius={'100px'}
          className={'animate-step-3'}
        >
          ถัดไป
        </Button>
      </Flex>
    </ContentWithNavbarLayout>
  );
};

export default SummaryIAm;
