export type keyType =
  // STEP 1
  | 'name'
  | 'email'
  | 'mobileNumber'
  | 'gender'
  | 'yearOfBirth'
  | 'province'
  | 'myWorkCategory'
  | 'myWorkRole'
  | 'incomePerMonthEnough'
  | 'savingEnough'
  | 'haveDebt'
  // STEP 2
  | 'question1'
  | 'question2'
  | 'question3'
  | 'question4'
  | 'question5'
  | 'question6'
  | 'question7'
  | 'question8'
  | 'question9'
  | 'question10'
  | 'surveyResult'
  // STEP 3
  | 'feedbackVideo'
  | 'goodThings'
  | 'recoveryPlan'
  | 'supportive'
  // STEP 4
  | 'smartGoal'
  | 'measure'
  | 'measureDuration'
  | 'measureChance'
  | 'allowPublish'
  // STEP 4
  | 'myGoodThings'
  | 'familySupportive'
  | 'workSupportive'
  | 'organizeSupportive';

export type survey30DaysKeyType =
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'mobileNumber'
  | 'personalId'
  | 'question1'
  | 'question2'
  | 'question3'
  | 'question4'
  | 'question5'
  | 'question6'
  | 'question7'
  | 'question8'
  | 'question9'
  | 'question10'
  | 'surveyResult';

export enum MATCH_FORM_FIELD {
  // STEP 1
  name = 'entry.1127944279',
  email = 'entry.303466737',
  mobileNumber = 'entry.1180474679',
  gender = 'entry.1252037413',
  yearOfBirth = 'entry.2068541662',
  province = 'entry.616213804',
  myWorkCategory = 'entry.2062213335',
  myWorkRole = 'entry.770932481',
  incomePerMonthEnough = 'entry.2060466101',
  savingEnough = 'entry.1504797201',
  haveDebt = 'entry.39837651',
  // STEP 2
  question1 = 'entry.1559729395',
  question2 = 'entry.1040168087',
  question3 = 'entry.536356613',
  question4 = 'entry.885339880',
  question5 = 'entry.1665191097',
  question6 = 'entry.211475998',
  question7 = 'entry.2334613',
  question8 = 'entry.535752698',
  question9 = 'entry.1844175327',
  question10 = 'entry.605666821',
  surveyResult = 'entry.459453200',
  // STEP 3
  feedbackVideo = 'entry.1878178032',
  goodThings = 'entry.1206104047',
  recoveryPlan = 'entry.1084539642',
  supportive = 'entry.741143118',
  // STEP 4
  smartGoal = 'entry.1141739946',
  measure = 'entry.15869957',
  measureDuration = 'entry.999648793',
  measureChance = 'entry.1693001385',
  allowPublish = 'entry.736023141',
  // STEP 5
  myGoodThings = 'entry.43093856',
  familySupportive = 'entry.895943033',
  workSupportive = 'entry.1163952369',
  organizeSupportive = 'entry.803189712',
}

export enum MATCH_PERSONAL_FORM_FIELD {
  firstName = 'entry.13912994',
  lastName = 'entry.2113398971',
  email = 'entry.951879756',
  mobileNumber = 'entry.1146076342',
  province = 'entry.107955087',
  level = 'entry.1545045298',
  question1 = 'entry.460631862',
  question2 = 'entry.1328462417',
  question3 = 'entry.150884729',
  question4 = 'entry.1045489697',
  question5 = 'entry.2076189449',
  question6 = 'entry.529154652',
  question7 = 'entry.1600213571',
  question8 = 'entry.999435373',
  question9 = 'entry.183935008',
  question10 = 'entry.1859356431',
  surveyResult = 'entry.167643465',
}
