import { useSurveyAnswerContext, PAGE_NAMES } from '../../Domains/SurveyAnswer/useSurveyContext';
import {
  EncouragementQuote,
  FamilySupportiveForm,
  OrganizeSupportiveForm,
  SelfGoodThingsForm,
  Topic,
  WorkSupportiveForm,
} from './Step5';

const SurveyStep5 = () => {
  const { currentStep, currentPage } = useSurveyAnswerContext();

  if (currentStep !== 5) {
    return null;
  }

  // TODO: replace with real page logic
  if (currentPage === PAGE_NAMES.P5_1_START) {
    return <Topic />;
  }
  if (currentPage === PAGE_NAMES.P5_2_FORM_SELF_GOOD_THINGS) {
    return <SelfGoodThingsForm />;
  }
  if (currentPage === PAGE_NAMES.P5_3_FORM_SUPPORTIVE_FAMILY) {
    return <FamilySupportiveForm />;
  }
  if (currentPage === PAGE_NAMES.P5_4_FORM_SUPPORTIVE_WORK) {
    return <WorkSupportiveForm />;
  }
  if (currentPage === PAGE_NAMES.P5_5_FORM_SUPPORTIVE_ORGANIZATION) {
    return <OrganizeSupportiveForm />;
  }
  if (currentPage === PAGE_NAMES.P5_6_END) {
    return <EncouragementQuote />;
  }

  return <div>Step 5 - Wrong currentPage: {currentPage}</div>;
};

export default SurveyStep5;
