import { useSurveyAnswerContext, PAGE_NAMES } from '../../Domains/SurveyAnswer/useSurveyContext';
import {
  Topic,
  MentalHealthForm,
  MentalHealthResult,
  PersonalInformation,
  ThankYou,
} from './Step7';

const SurveyStep7 = () => {
  const { currentPage, setCurrentPage } = useSurveyAnswerContext();

  if (currentPage === PAGE_NAMES.P1_1_START) {
    setCurrentPage(PAGE_NAMES.P7_01_START);
  }

  if (currentPage === PAGE_NAMES.P7_01_START) {
    return <Topic />;
  }
  if (currentPage === PAGE_NAMES.P7_02_FORM_PERSONAL_INFO) {
    return <PersonalInformation />;
  }
  if (currentPage === PAGE_NAMES.P7_03_FORM) {
    return <MentalHealthForm />;
  }
  if (currentPage === PAGE_NAMES.P7_04_RESULT) {
    return <MentalHealthResult />;
  }
  if (currentPage === PAGE_NAMES.P7_05_THANK_YOU) {
    return <ThankYou />;
  }

  return <div>Step 7 - Wrong currentPage: {currentPage}</div>;
};

export default SurveyStep7;
