import { AspectRatio, Body1, Body2, Heading5 } from '../../../../Components/Base';
import { Box, Flex, Spacer, Text } from '@chakra-ui/react';

import LandingWrapper from '../../../../Components/LandingWrapper';
import VideoPlayer from '../../../../Components/VideoPlayer';

interface InspirationSectionInterface extends React.FunctionComponent {}

const InspirationSection: InspirationSectionInterface = (props) => (
  <Box px={8} py={16}>
    <LandingWrapper color={'secondary.600'}>
      <Heading5 textAlign={{ base: 'center', md: 'left' }}>
        วิธีสร้างพลังใจด้วยหลัก Psycap โดยผู้เชี่ยวชาญ
      </Heading5>
      <Flex mt={{ base: 5, md: 10 }} flexDirection={{ base: 'column', md: 'row' }}>
        <AspectRatio width={'100%'} maxWidth={{ md: '340px', lg: '622px' }}>
          <VideoPlayer
            src={'https://www.youtube.com/watch?v=8B11a6s0e04'}
            width={'100%'}
            height={'100%'}
          />
        </AspectRatio>
        <Flex flexDirection={'column'} flex={1} px={{ base: 0, md: 8 }} py={2}>
          <Heading5>วิธีสร้างพลังใจด้วยหลัก Psycap โดยผู้เชี่ยวชาญ</Heading5>
          <Box pt={2} />
          <Body1 whiteSpace={'pre-line'}>
            {`Psycap คืออะไร
            เราจะใช้ประโยชน์จากหลักนี้ได้อย่างไรในช่วงวิกฤตของชีวิต`}
          </Body1>
        </Flex>
      </Flex>
    </LandingWrapper>
  </Box>
);

export default InspirationSection;
