import { useSurveyAnswerContext, PAGE_NAMES } from '../../Domains/SurveyAnswer/useSurveyContext';

import { Topic, MentalHealthForm, PreResult, MentalHealthResult, HeroScrollCard } from './Step2';

const SurveyStep2 = () => {
  const { currentStep, currentPage } = useSurveyAnswerContext();

  if (currentStep !== 2) {
    return null;
  }

  if (currentPage === PAGE_NAMES.P2_1_START) {
    return <Topic />;
  }
  if (currentPage === PAGE_NAMES.P2_2_FORM) {
    return <MentalHealthForm />;
  }
  if (currentPage === PAGE_NAMES.P2_3_PRE_RESULTS) {
    return <PreResult />;
  }
  if (currentPage === PAGE_NAMES.P2_4_RESULTS) {
    return <MentalHealthResult />;
  }
  if (currentPage === PAGE_NAMES.P2_5_END) {
    return <HeroScrollCard />;
  }

  return <div>Step 2 - Wrong currentPage: {currentPage}</div>;
};

export default SurveyStep2;
