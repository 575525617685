import { Box } from '@chakra-ui/react';

import { SurveyStep7 } from '../../Container/Survey';
import PreloadImage from '../../Container/PreloadImage';
import SurveyAudio from '../../Container/SurveyAudio';

const Survey30Days = () => {
  return (
    <Box>
      <PreloadImage />
      <SurveyAudio />
      <SurveyStep7 />
    </Box>
  );
};

export default Survey30Days;
