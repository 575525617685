import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  space: {
    NAVBAR_HEIGHT: '56px',
    FOOTER_BUTTON_HEIGHT_WITH_SPACE: '115px',
  },
  fonts: {
    main: `'Anuphan',sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    content: `'Sarabun',sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
    handWriting: `'Sriracha',sans-serif, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji'`,
  },
  colors: {
    primary: {
      100: 'hsl(34, 97%, 95%)',
      300: 'hsl(34, 97%, 62%)',
      500: 'hsl(34, 97%, 52%)',
      600: 'hsl(34, 97%, 44%)',
      700: 'hsl(34, 97%, 42%)',
    },
    secondary: {
      100: ' hsl(227, 59%, 95%)',
      300: ' hsl(227, 59%, 33%)',
      500: ' hsl(227, 59%, 23%)',
      600: ' hsl(227, 59%, 18%)',
      700: ' hsl(227, 59%, 13%)',
    },
    text: {
      heading: 'hsl(227, 59%, 23%)',
      heading2: 'hsl(235, 86%, 15%)',
      onLight: 'hsl(345, 6%, 13%)',
      body1: 'hsl(0, 0%, 26%)',
      body2: 'hsl(0, 0%, 61%)',
    },
  },
});

type Theme = typeof theme;

export type { Theme };
export default theme;
