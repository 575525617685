import { ReactChild } from 'react';
import { Box, BoxProps, Flex, FlexProps } from '@chakra-ui/react';

import Navbar, { NavbarInterface } from '../Navbar';

export interface ContentWithNavbarLayoutInterface extends FlexProps {
  navbarProps?: NavbarInterface;
  hasShowNavbar?: boolean;
  childProps?: BoxProps;
  children: ReactChild | ReactChild[];
}

const ContentWithNavbarLayout = (props: ContentWithNavbarLayoutInterface) => {
  const { navbarProps, children, childProps, hasShowNavbar = true, ...restProps } = props;
  const {
    title = '',
    currentStep = 0,
    totalSteps = 6,
    onBackClick,
    isMuted = false,
    onMuteSound,
    onUnMuteSound,
  } = navbarProps ?? {};

  return (
    <Flex
      position={'relative'}
      flexDirection={'column'}
      width={'100%'}
      height={'100%'}
      {...restProps}
    >
      {hasShowNavbar && (
        <Navbar
          title={title}
          currentStep={currentStep}
          totalSteps={totalSteps}
          isMuted={isMuted}
          onBackClick={onBackClick}
          onMuteSound={onMuteSound}
          onUnMuteSound={onUnMuteSound}
          flex={'0 0 auto'}
        />
      )}
      <Box flex={'1 1 auto'} overflowY={'auto'} {...childProps}>
        {children}
      </Box>
    </Flex>
  );
};

export default ContentWithNavbarLayout;
