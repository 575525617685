import { Flex, Image, Text } from '@chakra-ui/react';

import { SurveyTopicLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const Topic = () => {
  const { goToNextPage } = useSurveyAnswerContext();
  return (
    <SurveyTopicLayout
      title={'มารู้จักตัวเองให้มากขึ้น'}
      background={'linear-gradient(0deg, #FFFFFF 16.34%, #D9F3CA 100%)'}
      onStart={goToNextPage}
      rippleProps={{
        message: `เริ่มสำรวจ
        ตัวเอง`,
        textStyles: {
          mt: 2,
          fontSize: 'xl',
          textAlign: 'center',
          whiteSpace: 'pre-line',
        },
      }}
    >
      <Flex flexDirection={'column'} alignItems={'center'} mt={2}>
        <Image src={prefixUrl('/images/step5-topic.svg')} width={'100%'} />
        <Text
          mt={12}
          fontSize={'lg'}
          fontFamily={'content'}
          textAlign={'center'}
          whiteSpace={'pre-line'}
        >
          {`เพื่อรับรู้ถึงคุณสมบัติดีๆ ที่เรามีอยู่ในตัว
          และปัจจัยสนับสนุนรอบตัว
          ที่จะช่วยให้เรารับมือกับสถานการณ์ต่างๆ
          ได้อย่างเหมาะสม`}
        </Text>
      </Flex>
    </SurveyTopicLayout>
  );
};

export default Topic;
