import { Body1, Body2 } from '../../../../Components/Base';
import { Box, Image } from '@chakra-ui/react';

import styled from '@emotion/styled';

const HeroCardWrapper = styled(Box)`
  margin: 0 auto;
  max-width: 261px;
  flex: 1;
`;

interface HeroCardInterface {
  image: string;
  title: string;
  description: string;
}

const HeroCard: React.FunctionComponent<HeroCardInterface> = ({ image, title, description }) => (
  <HeroCardWrapper>
    <Image src={image} />
    <Box pt={6} />
    <Body1 fontWeight={'semibold'}>{title}</Body1>
    <Box pt={4} />
    <Body2 px={4}>{description}</Body2>
  </HeroCardWrapper>
);

export default HeroCard;
