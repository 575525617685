import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';

import { Heading1 } from '../../../../Components/Base';
import styled from '@emotion/styled';
import { useHistory } from 'react-router-dom';
import { prefixUrl } from '../../../../Utils/mapAssetUrl';

const HeroBgContainer = styled(Box)`
  background-image: url(images/hero-bg.png);
  background-position: left bottom;
  background-repeat: no-repeat;
  min-height: 500px;
  background-color: #fff7db;
  position: relative;
  display: flex;
  flex-direction: column;
`;

const HeroContent = styled(Box)`
  flex: 1;
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
`;

interface HeroSectionInterface extends React.FunctionComponent {}

const HeroSection: HeroSectionInterface = (props) => {
  const { replace } = useHistory();

  const handleGoToSurvey = () => {
    replace('/survey');
  };

  return (
    <HeroBgContainer backgroundSize={{ base: 'contain, contain', md: 'contain, 70%' }}>
      <HeroContent py={16} position={'relative'}>
        <Box pb={6} px={8} color={'secondary.600'} textAlign={{ base: 'center', md: 'left' }}>
          <Heading1>MY HERO</Heading1>
          <Text as={'h2'} fontSize={'4xl'} fontWeight={'semibold'}>
            คู่มือสร้างสุขภาพใจ ด้วยฮีโร่ในตัวคุณ <br />
            ภายใต้สถานการณ์ COVID-19
          </Text>
        </Box>

        {/* Responsive graphic, survey button */}
        <Flex flexDirection={{ base: 'column', md: 'row' }} alignItems={'center'}>
          <Image
            position={{ base: 'relative', md: 'absolute' }}
            right={0}
            bottom={0}
            width={{ base: '100%', md: '60%' }}
            src={prefixUrl('/images/hero-graphic.svg')}
          />
          <Box order={{ base: 1, md: 2 }} pt={6} px={8}>
            <Button
              onClick={handleGoToSurvey}
              colorScheme={'primary'}
              fontWeight={'normal'}
              fontSize={'lg'}
              borderRadius={'2rem'}
              px={16}
              py={6}
            >
              เริ่มทำกิจกรรมเลย
            </Button>
          </Box>
        </Flex>
      </HeroContent>
    </HeroBgContainer>
  );
};

export default HeroSection;
