import { Flex, Image, Text } from '@chakra-ui/react';

import { SurveyTopicLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const Topic = () => {
  const { goToNextPage } = useSurveyAnswerContext();
  return (
    <SurveyTopicLayout
      title={`ฉันพร้อมจะเติบโต
      และก้าวต่อไป`}
      background={'linear-gradient(0deg, #FFFFFF 16.34%, #FFEAED 100%)'}
      onStart={goToNextPage}
      rippleProps={{
        message: `มาทบทวน
        กันอีกที`,
        textStyles: {
          mt: 2,
          fontSize: 'xl',
          textAlign: 'center',
          whiteSpace: 'pre-line',
        },
      }}
    >
      <Flex flexDirection={'column'} alignItems={'center'} mt={2}>
        <Image src={prefixUrl('/images/step6-topic.svg')} width={'100%'} />
        <Text
          mt={6}
          fontSize={'lg'}
          fontFamily={'content'}
          textAlign={'center'}
          whiteSpace={'pre-line'}
        >
          {`ลองมาทบทวนสิ่งต่างๆ
          ก่อนจะลงมือทำเป้าหมายของเรา
          ให้เป็นจริงกันเถอะ!`}
        </Text>
      </Flex>
    </SurveyTopicLayout>
  );
};

export default Topic;
