import { Box, Button, Flex, Text, Textarea } from '@chakra-ui/react';
import { useMemo } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { SurveyFormLayout, SUBTITLE_POSITION } from '../../../Components/Layout';
import { BackgroundStep4 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';
import { SmartGoalValueType } from './SmartGoalForm';

type MeasureValueType = {
  measure: string;
};

const MeasurementForm = () => {
  const {
    currentStep,
    goToNextPage,
    goToPreviousPage,
    addSmartGoalInformation,
    smartGoalInformation,
  } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const smartGoalValue = smartGoalInformation as SmartGoalValueType;
  const smartGoal100Chars = useMemo(() => {
    let _smartGoal: string = smartGoalValue.smartGoal;
    if (_smartGoal.length <= 100) {
      return _smartGoal;
    }
    return `${_smartGoal.substring(0, 100)}...`;
  }, [smartGoalValue]);

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isValid },
  } = useForm<MeasureValueType>({
    mode: 'onChange',
    reValidateMode: 'onChange',
    defaultValues: smartGoalInformation,
  });

  const handleFormSubmit: SubmitHandler<MeasureValueType> = (data: any) => {
    addSmartGoalInformation(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={`มีเกณฑ์วัด
      ความสำเร็จได้จาก`}
      subtitle={`เป้าหมายของฉัน
      “${smartGoal100Chars}”`}
      subtitlePosition={SUBTITLE_POSITION.TOP}
      navbarProps={{
        title: 'SMART Goal',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep4 />}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} style={{ height: '100%' }}>
        <Flex flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
          <Box>
            <Textarea
              placeholder={'เช่น มีเงินเก็บก้อนใหม่ 50,000 บาท'}
              focusBorderColor={'primary.500'}
              maxLength={150}
              rows={9}
              {...register('measure', {
                required: 'กรุณากรอกเกณฑ์การวัดของคุณ',
              })}
            />
            <Flex justifyContent={'flex-end'} mt={3}>
              <Text fontSize={'sm'} color={'gray.500'}>
                {`${watch().measure?.length ?? 0} / 150`}
              </Text>
            </Flex>
            {errors.measure && (
              <Text color={'red.500'} fontSize={'xs'} mt={2}>
                {errors.measure.message}
              </Text>
            )}
          </Box>

          <Button
            mt={6}
            colorScheme={'primary'}
            isLoading={isSubmitting}
            type="submit"
            width={'100%'}
            borderRadius={'100px'}
            disabled={!isValid}
          >
            ถัดไป
          </Button>
        </Flex>
      </form>
    </SurveyFormLayout>
  );
};

export default MeasurementForm;
