import { useMemo } from 'react';
import { Box, Button, Flex, Grid, Icon, Image, Text } from '@chakra-ui/react';
import styled from '@emotion/styled';

import { ContentWithNavbarLayout } from '../../../Components/Layout';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { MdPlayCircleFilled } from 'react-icons/md';
import { Heading3 } from '../../../Components/Base';
import { prefixUrl } from '../../../Utils/mapAssetUrl';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const ImageWrapper = styled(Flex)<{ active?: boolean }>`
  cursor: pointer;

  & > div {
    border-radius: 100%;
    background-color: ${({ active }) => (active ? 'hsl(34, 97%, 86%)' : 'unset')};
  }

  &:hover > div {
    background-color: hsl(34, 97%, 86%);
  }
`;

type InspireInfomationValueType = {
  feedbackVideo: string;
};

const InspireVideoFeedback = () => {
  const { currentStep, goToNextPage, goToPreviousPage, addInspireInformation } =
    useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const inspireInformation = useSurveyAnswerContext()
    .inspireInformation as InspireInfomationValueType;

  const feedbackValue = useMemo(
    () => inspireInformation?.feedbackVideo,
    [inspireInformation?.feedbackVideo]
  );

  const handleFeedBack = (feedback: string) => {
    addInspireInformation({ feedbackVideo: feedback });
  };

  const handleFeedBackSubmit = (): void => {
    goToNextPage();
  };

  return (
    <ContentWithNavbarLayout
      navbarProps={{
        title: 'สร้างแรงบันดาลใจ',
        currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      childProps={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <Box
        position={'relative'}
        bg={'hsl(253, 100%, 95%)'}
        px={6}
        py={8}
        minHeight={'350px'}
        flex={'0 0 auto'}
        overflowX={'hidden'}
      >
        <Image
          src={prefixUrl('/images/bubble.png')}
          position={'absolute'}
          top={0}
          right={0}
          width={'110px'}
          transform={'translate(40%, -30%)'}
        />
        <Box position={'relative'}>
          <Heading3 color={'text.heading'}>{`ร่วมสร้างแรงบันดาลใจไปกับเรา`}</Heading3>
          <Box mt={3}>
            <Image src={prefixUrl('/images/cover-inspire-video.jpg')} width={'100%'} />
          </Box>
        </Box>
      </Box>
      <Box py={5} px={6} flex={'1 0 auto'}>
        <Flex height={'100%'} flexDirection={'column'} flex={1} justifyContent={'space-between'}>
          <Box>
            <Text fontSize={'2xl'} fontWeight={'bold'}>
              หลังดูคลิปจบแล้ว ฉันรู้สึก
            </Text>
            <Grid gridTemplateColumns={'1fr 1fr 1fr'} mt={6}>
              <ImageWrapper
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                active={feedbackValue === 'เฉยๆ'}
                onClick={() => handleFeedBack('เฉยๆ')}
              >
                <Box p={4} className={'image'}>
                  <Image src={prefixUrl('/images/emoji-same.png')} width={'52px'} />
                </Box>
                <Text>เฉยๆ</Text>
              </ImageWrapper>
              <ImageWrapper
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                active={feedbackValue === 'ประทับใจ'}
                onClick={() => handleFeedBack('ประทับใจ')}
              >
                <Box p={4}>
                  <Image src={prefixUrl('/images/emoji-happy.png')} width={'52px'} />
                </Box>
                <Text>ประทับใจ</Text>
              </ImageWrapper>
              <ImageWrapper
                flexDirection={'column'}
                justifyContent={'center'}
                alignItems={'center'}
                active={feedbackValue === 'มีกำลังใจ'}
                onClick={() => handleFeedBack('มีกำลังใจ')}
              >
                <Box p={4}>
                  <Image src={prefixUrl('/images/emoji-very-happy.png')} width={'52px'} />
                </Box>
                <Text>มีกำลังใจ</Text>
              </ImageWrapper>
            </Grid>
          </Box>
          <Flex mt={10} justifyContent={'center'} alignItems={'center'} onClick={goToPreviousPage}>
            <Icon as={MdPlayCircleFilled} color={'text.body1'} width={34} height={'auto'} mr={1} />
            <Text
              as={'span'}
              color={'text.body1'}
              fontSize={'lg'}
              textDecoration={'underline'}
              cursor={'pointer'}
            >{`ชมคลิปอีกครั้ง`}</Text>
          </Flex>
          <Button
            mt={6}
            colorScheme={'primary'}
            type="submit"
            width={'100%'}
            borderRadius={'100px'}
            disabled={!feedbackValue}
            onClick={handleFeedBackSubmit}
          >
            ถัดไป
          </Button>
        </Flex>
      </Box>
    </ContentWithNavbarLayout>
  );
};

export default InspireVideoFeedback;
