import { Box } from '@chakra-ui/react';

import {
  SurveyStep1,
  SurveyStep2,
  SurveyStep3,
  SurveyStep4,
  SurveyStep5,
  SurveyStep6,
} from '../../Container/Survey';
import PreloadImage from '../../Container/PreloadImage';
import SurveyAudio from '../../Container/SurveyAudio';

const Survey = () => {
  return (
    <Box>
      <PreloadImage />
      <SurveyAudio />
      <SurveyStep1 />
      <SurveyStep2 />
      <SurveyStep3 />
      <SurveyStep4 />
      <SurveyStep5 />
      <SurveyStep6 />
    </Box>
  );
};

export default Survey;
