import { useEffect } from 'react';

import { QuotesLayout } from '../../../Components/Layout';

import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

const MotivationQuote = () => {
  const { goToNextPage } = useSurveyAnswerContext();

  useEffect(() => {
    setTimeout(() => goToNextPage(), 6000);
  }, []);

  return (
    <QuotesLayout
      titleQuote={'เป้าหมายทำให้เรา'}
      keyQuote={'มีกำลังใจ'}
      imageUrl={prefixUrl('/images/motivation-quote.gif')}
      isLightText
    />
  );
};

const RomeBuildingQuote = () => {
  const { goToNextPage } = useSurveyAnswerContext();

  useEffect(() => {
    setTimeout(() => goToNextPage(), 6000);
  }, []);

  return (
    <QuotesLayout
      titleQuote={'กรุงโรมไม่ได้'}
      keyQuote={'สร้างเสร็จในวันเดียว'}
      imageUrl={prefixUrl('/images/rome-building-quote.gif')}
    />
  );
};

export { MotivationQuote, RomeBuildingQuote };
