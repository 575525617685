import {
  FormControl,
  FormControlProps,
  FormLabel,
  Input as ChakraInput,
  InputProps,
} from '@chakra-ui/react';

interface InputInterface extends FormControlProps {
  label: string;
  isRequired?: boolean;
  inputProps?: InputProps;
}

const Input = (props: InputInterface) => {
  const { label, isRequired = false, inputProps, ...restProps } = props;
  return (
    <FormControl isRequired={isRequired} {...restProps}>
      <FormLabel fontWeight={'semibold'} fontSize={'lg'}>
        {label}
      </FormLabel>
      <ChakraInput focusBorderColor={'primary.500'} {...inputProps} />
    </FormControl>
  );
};

export default Input;
