import { useSurveyAnswerContext, PAGE_NAMES } from '../../Domains/SurveyAnswer/useSurveyContext';
import {
  MyRole,
  WorkCategories,
  PersonalInformation,
  MyFinanceStatus,
  Topic,
  ConsentForm,
} from './Step1';

const SurveyStep1 = () => {
  const { currentStep, currentPage } = useSurveyAnswerContext();

  if (currentStep !== 1) {
    return null;
  }

  if (currentPage === PAGE_NAMES.P1_1_START) {
    return <Topic />;
  }
  if (currentPage === PAGE_NAMES.P1_2_CONSENT_FORM) {
    return <ConsentForm />;
  }
  if (currentPage === PAGE_NAMES.P1_3_FORM_PERSONAL_INFO) {
    return <PersonalInformation />;
  }
  if (currentPage === PAGE_NAMES.P1_4_FORM_OCCUPATION) {
    return <WorkCategories />;
  }
  if (currentPage === PAGE_NAMES.P1_5_FORM_ROLE) {
    return <MyRole />;
  }
  if (currentPage === PAGE_NAMES.P1_6_FORM_FINANCIAL) {
    return <MyFinanceStatus />;
  }
  return <div>Step 1 - Wrong currentPage: {currentPage}</div>;
};

export default SurveyStep1;
