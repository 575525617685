import { Box, BoxProps, Flex, Image, Text, useBreakpointValue } from '@chakra-ui/react';

import styled from '@emotion/styled';
import { Body1, Body2, Heading3 } from '../../Components/Base';
import Footer from '../../Components/Footer';
import { prefixUrl } from '../../Utils/mapAssetUrl';

const ProjectCard = styled(Flex)`
  border-radius: 1rem;
  box-shadow: 0 10px 30px #d3e4f3;
  flex: 0 1 504px;
  max-width: 504px;
  margin: 2rem 0.1rem;
  min-height: 140px;
  position: relative;
`;

const ProjectWrapper = styled(Flex)`
  flex-wrap: wrap;
  justify-content: space-around;
`;

const ProjectImage = styled((props: any) => (
  <Box position={'relative'} paddingRight={'199px'}>
    <Image {...props} />
  </Box>
))`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
  height: 171px;
`;

export const ContentWrapper = styled((props: BoxProps) => (
  <Box maxWidth={'1280px'} margin={'0 auto'} {...props} />
))``;

interface AboutInterface extends React.FunctionComponent {}

const About: AboutInterface = (props) => {
  const headerImageSource = useBreakpointValue({
    base: prefixUrl('/images/about-header-mobile.svg'),
    md: prefixUrl('/images/about-header.svg'),
  });
  return (
    <Box>
      <Image width={'100%'} src={headerImageSource} />
      <ContentWrapper py={'20'} px={8} color={'secondary.600'}>
        <Heading3 fontWeight={'bold'}>โครงการ MY HERO</Heading3>
        <Body1 mt={6}>
          โครงการนี้เกิดขึ้นเพื่อสร้างความเข้าใจ และทำให้เรารับรู้ถึงความสำคัญของต้นทุนทางจิตวิทยา
          ที่ส่งผลต่อสุขภาพใจ
        </Body1>
        <Body1 mt={4}>
          นอกจากนี้ยังช่วยให้รับรู้ถึงศักยภาพของตัวเอง
          ผ่านการเรียนรู้จากต้นแบบในอุดมคติและตัวอย่างใกล้ตัว ทำให้เกิดความหวังในการใช้ชีวิต
          รวมถึงได้ฝึกตั้งเป้าหมายที่เหมาะสม และสามารถจัดการอุปสรรคต่างๆ
          เพื่อไปถึงจุดหมายที่ตั้งใจไว้
        </Body1>
        <Body1 mt={4}>ซึ่งโครงการนี้ ประกอบด้วย</Body1>
        <ProjectWrapper mt={4}>
          <ProjectCard>
            <ProjectImage src={prefixUrl('/images/at-first-sight.png')} />
            <Box p={3}>
              <Text fontWeight={'bold'}>At first sight</Text>
              <Body2 mt={1}>ประเมินสุขภาพจิตใจก่อนเข้าร่วมโครงการฯ</Body2>
            </Box>
          </ProjectCard>
          <ProjectCard>
            <ProjectImage src={prefixUrl('/images/my-idol.png')} />
            <Box p={3}>
              <Text fontWeight={'bold'}>My Idol</Text>
              <Body2 mt={1}>
                ช่วยให้เรารับรู้ศักยภาพของตัวเอง ผ่านการเรียนรู้จากต้นแบบในอุดมคติ
              </Body2>
            </Box>
          </ProjectCard>
          <ProjectCard>
            <ProjectImage src={prefixUrl('/images/my-goal.png')} />
            <Box p={3}>
              <Text fontWeight={'bold'}>My Goal</Text>
              <Body2 mt={1}>
                เรียนรู้วิธีตั้งเป้าหมายที่เหมาะสมกับตัวเอง และวิธีนำไปสู่ความสำเร็จตามเป้าหมาย
              </Body2>
            </Box>
          </ProjectCard>
          <ProjectCard>
            <ProjectImage src={prefixUrl('/images/my-character.png')} />
            <Box p={3}>
              <Text fontWeight={'bold'}>My Character</Text>
              <Body2 mt={1}>รับรู้ถึงคุณสมบัติด้านบวกหรือสิ่งมีอยู่ของตัวเอง</Body2>
            </Box>
          </ProjectCard>
          <ProjectCard>
            <ProjectImage src={prefixUrl('/images/my-growth.png')} />
            <Box p={3}>
              <Text fontWeight={'bold'}>My Growth</Text>
              <Body2 mt={1}>
                สรุปทบทวนสิ่งที่ได้เรียนรู้ และตระหนักถึง PsyCap ที่ส่งผลต่อสุขภาพจิตใจ
              </Body2>
            </Box>
          </ProjectCard>
          <ProjectCard>
            <ProjectImage src={prefixUrl('/images/my-change.png')} />
            <Box p={3}>
              <Text fontWeight={'bold'}>My Change</Text>
              <Body2 mt={1}>
                ประเมินและติดตามการเปลี่ยนแปลงของ สุขภาพจิตใจหลังจากเข้าร่วมโครงการฯ ไปแล้ว 1 เดือน
              </Body2>
            </Box>
          </ProjectCard>
        </ProjectWrapper>
      </ContentWrapper>
      <Footer />
    </Box>
  );
};

export default About;
