import { Link } from 'react-router-dom';
import { Box, Button, Flex, Icon, Image, Text, TextProps } from '@chakra-ui/react';
import { FaFacebook, FaTwitter } from 'react-icons/fa';
import VideoPlayer from '../../../Components/VideoPlayer';
import { HashLink } from 'react-router-hash-link';

import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { AspectRatio, Body1, Heading3, Heading5 } from '../../../Components/Base';
import { prefixUrl } from '../../../Utils/mapAssetUrl';

type InspireInformationType = {
  supportive?: string;
};

const Body1Content = (props: TextProps) => <Body1 fontFamily={'content'} {...props} />;

const MY_HERO_URL = 'https://bsri.swu.ac.th/myhero';

const ThankYou = () => {
  const inspireInformation = useSurveyAnswerContext().inspireInformation as InspireInformationType;
  const { supportive } = inspireInformation;

  const shareToTwitter = () => {
    const width = 550;
    const height = 450;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;
    const windowOptions = `width=${width},height=${height},left=${left},top=${top}`;
    const sharedUrl = `http://twitter.com/share?url=
    ${encodeURIComponent(MY_HERO_URL)}
    &text=${encodeURIComponent(`"${supportive}" #MyHero`)}`;
    window.open(sharedUrl, 'blank', windowOptions);
  };

  const shareToFacebook = () => {
    const width = 550;
    const height = 450;
    const left = (window.screen.width - width) / 2;
    const top = (window.screen.height - height) / 4;
    const windowOptions = `width=${width},height=${height},left=${left},top=${top}`;
    const sharedUrl = `http://facebook.com/sharer/sharer.php?u=${encodeURIComponent(MY_HERO_URL)}
      &quote=${encodeURIComponent(`"${supportive}"
      #MyHero`)}`;
    window.open(sharedUrl, 'blank', windowOptions);
  };

  return (
    <Box
      bg={'linear-gradient(180deg, #DFEEFF 0%, #FFFFFF 100%)'}
      height={'100%'}
      overflowY={'auto'}
    >
      {/* https://skooldio.atlassian.net/browse/SWU-241 Hide the section of a survey after 30 days */}
      {/* <Flex flexDirection={'column'} alignItems={'center'} px={6}>
        <Heading5 color={'text.heading'}>ขอบคุณที่ร่วมกิจกรรม</Heading5>
        <Heading3 whiteSpace={'pre-line'} textAlign={'center'} mt={2} color={'text.heading'}>
          {`ไว้มาสำรวจสุขภาพใจ
          กันอีกใน 30 วัน*`}
        </Heading3>
        <Image src={prefixUrl('/images/30-days.svg')} width={'250px'} height={'auto'} />
        <Body1
          mt={4}
          fontWeight={'semibold'}
          whiteSpace={'pre-line'}
          textAlign={'center'}
          color={'text.heading'}
        >
          {`*คุณจะได้รับอีเมลแจ้งเตือน
          ให้มาร่วมประเมินสุขภาพใจกับเราอีกครั้ง
          เมื่อครบกำหนด 30 วัน`}
        </Body1>
        <Body1Content whiteSpace={'pre-line'} textAlign={'center'} mt={4}>
          {`“ในการเข้าร่วมกิจกรรมนี้หวังว่าจะทำให้คุณมีแรงใจเพิ่มขึ้น ไม่ว่าสถานการณ์จะเป็นอย่างไร อย่าลืมว่าคุณไม่ได้อยู่คนเดียว สำหรับคนที่อยากได้รับคำปรึกษา
          สามารถติดต่อได้ที่ สายด่วนสุขภาพจิต 1323"`}
        </Body1Content>
      </Flex> */}
      <Box bg={'#ECE7FF'} px={6} py={10} position={'relative'} overflow={'hidden'}>
        {/* Background image zone */}
        <Image
          src={prefixUrl('/images/bubble.png')}
          position={'absolute'}
          width={'75px'}
          top={0}
          left={0}
          transform={'translate(-25%, -25%)'}
        />
        <Image
          src={prefixUrl('/images/share-heading.svg')}
          position={'absolute'}
          width={'100px'}
          top={2}
          right={-4}
        />
        <Image
          src={prefixUrl('/images/bubble.png')}
          position={'absolute'}
          width={'40px'}
          top={2}
          right={16}
          transform={'translateX(-50%)'}
        />
        <Image
          src={prefixUrl('/images/share-footer.png')}
          position={'absolute'}
          width={'250px'}
          bottom={-5}
          left={0}
        />

        <Heading5 whiteSpace={'pre-line'} position={'relative'} color={'text.heading'}>
          {`กำลังใจที่เราสามารถ
          ร่วมสร้างให้กันและกันได้`}
        </Heading5>
        <Box
          mt={4}
          px={4}
          py={6}
          borderRadius={'24px'}
          bg={'linear-gradient(180deg, #FFFFFF -56.61%, #F2F2F2 110.23%)'}
          boxShadow={'0px 4px 8px rgba(0, 0, 0, 0.16)'}
        >
          <Text fontSize={'xl'} fontWeight={'semibold'} color={'text.heading'}>
            {`ขอให้เราเชื่อว่า`}
          </Text>
          <Text fontSize={'2xl'} fontWeight={'semibold'} color={'text.heading'}>
            {`“${supportive}”`}
          </Text>
        </Box>
        <Flex justifyContent={'flex-end'} mt={16} alignItems={'center'} position={'relative'}>
          <Body1>แชร์</Body1>
          <Icon
            as={FaFacebook}
            color={'#1877F2'}
            width={6}
            height={6}
            ml={4}
            cursor={'pointer'}
            onClick={shareToFacebook}
          />
          <Icon
            as={FaTwitter}
            color={'twitter.500'}
            width={6}
            height={6}
            ml={4}
            cursor={'pointer'}
            onClick={shareToTwitter}
          />
        </Flex>
      </Box>
      <Box mt={10} />
      <Flex flexDirection={'column'} alignItems={'center'} px={6}>
        <Heading5 color={'text.heading'}>SMART Goal</Heading5>
        <Heading3 whiteSpace={'pre-line'} textAlign={'center'} mt={2} color={'text.heading'}>
          {`ของคนอื่นเป็นอย่างไรนะ?`}
        </Heading3>
        <Body1Content whiteSpace={'pre-line'} textAlign={'center'} mt={8}>
          {`ดูเป้าหมายที่เพื่อนร่วมวิกฤตนี้ได้ตั้งไว้
          เผื่อจะได้ไอเดียที่เว็บไซต์
          http://bsri.swu.ac.th/myhero`}
        </Body1Content>
      </Flex>
      <Box pt={5} />
      <Box px={6}>
        <HashLink to={'/#smartgoal'} target={'_blank'}>
          <Button colorScheme="primary" width={'100%'} borderRadius={'100px'}>
            อ่าน SMART Goal ทั้งหมด
          </Button>
        </HashLink>
        <Link to={'/'} target={'_blank'}>
          <Button
            mt={4}
            variant="outline"
            color={'text.heading'}
            borderColor={'gray.300'}
            width={'100%'}
            borderRadius={'100px'}
            fontWeight={'normal'}
          >
            เข้าชมเว็บไซต์
          </Button>
        </Link>
      </Box>
      <Box pt={10} />
      <Flex flexDirection={'column'} alignItems={'center'} px={6}>
        <Heading3
          whiteSpace={'pre-line'}
          textAlign={'center'}
          color={'text.heading'}
        >{`สร้างพลังใจด้วยหลัก
        Psycap โดยผู้เชี่ยวชาญ`}</Heading3>
        <Heading5 mt={2} color={'text.heading'}>
          Psycap คืออะไร
        </Heading5>
        <Body1Content textAlign={'center'} mt={2}>
          เราจะใช้ประโยชน์จากหลักนี้ได้อย่างไรในช่วงวิกฤต ของชีวิต
        </Body1Content>
        <AspectRatio mt={6} width={'100%'}>
          <VideoPlayer
            src={'https://www.youtube.com/watch?v=8B11a6s0e04'}
            width={'100%'}
            height={'100%'}
          />
        </AspectRatio>
        <Text
          mt={4}
          textAlign={'center'}
          whiteSpace={'pre-line'}
        >{`โดย อ.ดร. ก่อเกียรติ์ มหาวีรชาติกุล
        ผู้เชี่ยวชาญด้าน Psycap`}</Text>
      </Flex>
      <Box pt={8} />
      <Flex flexDirection={'column'} alignItems={'center'} px={6}>
        <Heading5 whiteSpace={'pre-line'} mt={2}>
          กิจกรรมนี้จัดทำโดย
        </Heading5>
        <Flex justifyContent={'space-evenly'} width={'100%'} mt={4}>
          <Image src={prefixUrl('/images/thai-health-logo.svg')} height={'80px'} width={'auto'} />
          <Image src={prefixUrl('/images/swu-logo.svg')} height={'80px'} width={'auto'} />
        </Flex>
      </Flex>
      <Box pt={6} />
      <Box px={6}>
        <Heading5 mt={2}>
          {`สำนักงานกองทุนสนับสนุน
        การสร้างเสริมสุขภาพ (สสส.)
        และ สถาบันวิจัยพฤติกรรมศาสตร์ มศว`}
        </Heading5>
        <Body1 mt={9}>
          {`ปรึกษาเรื่องสุขภาพใจ
          ติดต่อได้ที่สายด่วนสุขภาพจิต `}
          <Text as={'span'} fontSize={'2xl'} fontWeight={'semibold'}>
            1323
          </Text>
        </Body1>
      </Box>
      <Flex flexDirection={'column'} alignItems={'center'} bg={'hsl(227, 86%, 16%)'} mt={8} py={5}>
        <Body1 color={'white'}>{`© 2021 All rights reserved`}</Body1>
        <Body1 color={'white'}>{`Designed by Skooldio`}</Body1>
      </Flex>
    </Box>
  );
};

export default ThankYou;
