export const smartGoals = [
  {
    message: 'มีรายได้เหลือเพียงพอเก็บออม ดูแลครอบครัวให้อยู่สุขสบาย',
    name: 'พี',
    yearOfBirth: '2539',
    workCategory: 'ธุรกิจขนส่ง',
  },
  {
    message: 'เรียนคอร์สออนไลน์สร้างทักษะใหม่ เพื่อให้มีรายได้ทางใหม่ มีอาชีพใหม่ที่ไม่ลำบาก',
    name: 'ก้อง',
    yearOfBirth: '2536',
    workCategory: 'ธุรกิจจําหน่ายสินค้า',
  },
  {
    message:
      'หาอาชีพเสริมเพื่อเพิ่มรายได้มาใช้จ่ายในครอบครัวให้มากขึ้น  และเพียงพอต่อรายจ่ายในชีวิตประจำวัน',
    name: 'ออม',
    yearOfBirth: '2531',
    workCategory: 'ธุรกิจการประชุม',
  },
  {
    message: 'สร้างสรรค์ผลงานให้ดีขึ้น เพื่อให้องค์กรเห็นศักยภาพในการทำงานของเรา',
    name: 'โจ้',
    yearOfBirth: '2541',
    workCategory: 'ธุรกิจนําเที่ยว',
  },
  {
    message: 'ทำงานอย่างมีความสุขในทุกๆ วัน',
    name: 'ตาล',
    yearOfBirth: '2525',
    workCategory: 'ธุรกิจนันทนาการ',
  },
];
