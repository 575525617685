import { ReactNode } from 'react';
import { Box, BoxProps, Flex, FlexProps, Icon, Text } from '@chakra-ui/react';

import { HeroWink } from '../Base/Icon';

interface MessageBoxInterface extends FlexProps {
  customMessage?: ReactNode | ReactNode[];
  message?: string;
  isLargeMessage?: boolean;
  subMessage?: string;
  subMessageProps?: BoxProps;
}

const MessageBox = (props: MessageBoxInterface) => {
  const { customMessage, message, isLargeMessage, subMessage, subMessageProps, ...restProps } =
    props;
  return (
    <Flex flexDirection={'column'} alignItems={'center'} width={'100%'} {...restProps}>
      <Box
        width={'100%'}
        bg={'white'}
        borderRadius={'24px'}
        boxShadow={'0px 4px 24px rgba(0, 0, 0, 0.25)'}
        overflow={'hidden'}
      >
        <Box px={4} py={subMessage ? 2 : 6}>
          {customMessage ? (
            customMessage
          ) : (
            <Text
              color={'text.heading'}
              fontSize={isLargeMessage ? '2.125rem' : '2xl'}
              fontFamily={'handWriting'}
              textAlign={'center'}
              whiteSpace={'pre-line'}
            >
              {message}
            </Text>
          )}
        </Box>
        {subMessage && (
          <Box px={4} py={2} {...subMessageProps}>
            <Text
              fontSize={'lg'}
              color={'white'}
              fontWeight={'semibold'}
              textAlign={'center'}
              whiteSpace={'pre-line'}
            >
              {subMessage}
            </Text>
          </Box>
        )}
      </Box>
      <Icon as={HeroWink} width={'72px'} height={'25px'} mt={4} />
    </Flex>
  );
};

export default MessageBox;
