import { useSurveyAnswerContext } from '../../Domains/SurveyAnswer/useSurveyContext';

const ThankYou = (props) => {
  const { personalInformation } = useSurveyAnswerContext();

  return <div>{JSON.stringify(personalInformation, null, 2)}</div>;
};

ThankYou.propTypes = {};

export default ThankYou;
