import { Box, Button, Flex, Text, Textarea } from '@chakra-ui/react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { SUBTITLE_POSITION, SurveyFormLayout } from '../../../Components/Layout';
import { BackgroundStep3 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

type supportiveValueType = {
  supportive: string;
};

const SupportiveForm = () => {
  const { currentStep, goToNextPage, goToPreviousPage, addInspireInformation } =
    useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const {
    register,
    handleSubmit,
    watch,
    formState: { isSubmitting, errors, isDirty, isValid },
  } = useForm<supportiveValueType>({ mode: 'onChange', reValidateMode: 'onChange' });

  const handleFormSubmit: SubmitHandler<supportiveValueType> = (data: any) => {
    addInspireInformation(data);
    goToNextPage();
  };

  return (
    <SurveyFormLayout
      title={`เพื่อให้กำลังใจคนที่กำลังเจอสถานการณ์คล้ายๆ กับคุณ`}
      subtitle={'ขอหนึ่งประโยค'}
      subtitlePosition={SUBTITLE_POSITION.TOP}
      navbarProps={{
        title: 'สร้างแรงบันดาลใจ',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep3 />}
    >
      <form onSubmit={handleSubmit(handleFormSubmit)} style={{ height: '100%' }}>
        <Flex flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
          <Box>
            <Textarea
              placeholder={'เช่น สู้ๆ นะครับ ขอให้คุณเข้มแข็งและผ่านทุกอย่าง ไปด้วยดี'}
              focusBorderColor={'primary.500'}
              maxLength={150}
              rows={9}
              {...register('supportive', {
                required: 'กรุณากรอกกำลังใจให้กับคนที่เจอสถานการณ์เดียวกันอยู่',
              })}
            />
            <Flex justifyContent={'flex-end'} mt={3}>
              <Text fontSize={'sm'} color={'gray.500'}>
                {`${watch().supportive?.length ?? 0} / 150`}
              </Text>
            </Flex>
            {errors.supportive && (
              <Text color={'red.500'} fontSize={'xs'} mt={2}>
                {errors.supportive.message}
              </Text>
            )}
          </Box>

          <Button
            mt={6}
            colorScheme={'primary'}
            isLoading={isSubmitting}
            type="submit"
            width={'100%'}
            borderRadius={'100px'}
            disabled={!isDirty || !isValid}
          >
            ถัดไป
          </Button>
        </Flex>
      </form>
    </SurveyFormLayout>
  );
};

export default SupportiveForm;
