import * as React from 'react';

import { GlobalStyles, theme } from './Components/Theme';
import { Route, BrowserRouter as Router, Switch } from 'react-router-dom';

import About from './Routes/About';
import { ChakraProvider } from '@chakra-ui/react';
import Home from './Routes/Home';
import { LandingNavbar } from './Components/Navbar';
import { MobileBoxLayout } from './Components/Layout';
import Playground from './Routes/Playground';
import Survey from './Routes/Survey';
import { SurveyAnswerProvider } from './Domains/SurveyAnswer/useSurveyContext';
import ThankYou from './Routes/ThankYou';
import Survey30Days from './Routes/Survey30Days';
import { SurveyAudioProvider } from './Domains/SurveyAudio/useSuveyAudioContext';

const LandingRoute = () => (
  <Switch>
    <Route path="/about">
      <About />
    </Route>
    <Route path="/">
      <Home />
    </Route>
  </Switch>
);

const App = () => (
  <ChakraProvider theme={theme}>
    <GlobalStyles />
    <SurveyAudioProvider>
      <SurveyAnswerProvider>
        <Router basename={process.env.REACT_APP_BASE_NAME}>
          {/* A <Switch> looks through its children <Route>s and
            renders the first one that matches the current URL. */}
          <Switch>
            <Route path="/survey">
              <MobileBoxLayout>
                <Survey />
              </MobileBoxLayout>
            </Route>
            <Route path="/survey-30-days">
              <MobileBoxLayout>
                <Survey30Days />
              </MobileBoxLayout>
            </Route>
            <Route path="/thank-you">
              <MobileBoxLayout isAnimated={false}>
                <ThankYou />
              </MobileBoxLayout>
            </Route>
            <Route path="/playground">
              <Playground />
            </Route>
            <Route path="/">
              <LandingNavbar />
              <LandingRoute />
            </Route>
          </Switch>
        </Router>
      </SurveyAnswerProvider>
    </SurveyAudioProvider>
  </ChakraProvider>
);

export default App;
