import { Flex, FlexProps, Text, TextProps } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import styled from '@emotion/styled';

export interface RippleBoxInterface extends FlexProps {
  message?: string | number;
  textStyles?: TextProps;
}

// Reference Animation: https://codepen.io/chrisunderdown/pen/JeXNoz
const ripple = keyframes`
  from {
    opacity: 1;
    transform: scale3d(0.75,0.75,1);
  }
  to {
    opacity: 0;
    transform: scale3d(1.5,1.5,1);
  }
`;

const RippleBoxWrapper = styled(Flex)`
  position: relative;
  z-index: 1;

  &::after {
    content: '';
    position: absolute;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    border: 8px solid hsla(34, 97%, 52%, 0.5);
    animation: ${ripple} 2s cubic-bezier(0.65, 0, 0.34, 1) 0s infinite;
  }

  &::before {
    content: '';
    position: absolute;
    border-radius: 100%;
    height: 100%;
    width: 100%;
    border: 8px solid hsla(34, 97%, 52%, 0.3);
    animation: ${ripple} 2s cubic-bezier(0.65, 0, 0.34, 1) 0.5s infinite;
  }
`;

const RippleBox = (props: RippleBoxInterface) => {
  const { message = 'เริ่มเลย', textStyles, ...restProps } = props;
  return (
    <RippleBoxWrapper>
      <Flex
        width={'130px'}
        height={'130px'}
        boxShadow={'0 0 20px 0 rgba(165, 69, 69, 0.25)'}
        borderRadius={'100%'}
        justifyContent={'center'}
        alignItems={'center'}
        bg={'primary.500'}
        cursor={'pointer'}
        zIndex={1}
        {...restProps}
      >
        <Text color={'white'} fontWeight={'bold'} fontSize={'3xl'} {...textStyles}>
          {message}
        </Text>
      </Flex>
    </RippleBoxWrapper>
  );
};

export default RippleBox;
