import { useMemo, useState } from 'react';
import {
  Box,
  Button,
  Flex,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Text,
} from '@chakra-ui/react';

import { SurveyFormLayout, SUBTITLE_POSITION } from '../../../Components/Layout';
import RippleBox from '../../../Components/RippleBox';
import { BackgroundStep4 } from '../../../Components/SurveyBackgroundImage';
import { useSurveyAnswerContext } from '../../../Domains/SurveyAnswer/useSurveyContext';
import { SmartGoalValueType } from './SmartGoalForm';
import { useSurveyAudioContext } from '../../../Domains/SurveyAudio/useSuveyAudioContext';

const MeasurementChanceForm = () => {
  const [measureChance, setMeasureChance] = useState(50);
  const {
    currentStep,
    goToNextPage,
    goToPreviousPage,
    addSmartGoalInformation,
    smartGoalInformation,
  } = useSurveyAnswerContext();
  const { handleMuteSound, handleUnMuteSound, isMuted } = useSurveyAudioContext();

  const smartGoalValue = smartGoalInformation as SmartGoalValueType;
  const smartGoal100Chars = useMemo(() => {
    let _smartGoal: string = smartGoalValue.smartGoal;
    if (_smartGoal.length <= 100) {
      return _smartGoal;
    }
    return `${_smartGoal.substring(0, 100)}...`;
  }, [smartGoalValue]);

  const handleFormSubmit = (data: any) => {
    addSmartGoalInformation({ measureChance });
    goToNextPage();
  };

  const getChangeMessage = () => {
    if (measureChance <= 25) {
      return 'ยังมีหวัง';
    } else if (measureChance <= 50) {
      return 'มีโอกาสเป็นไปได้';
    } else if (measureChance <= 75) {
      return 'มีโอกาสเป็นไปได้ค่อนข้างมาก';
    } else {
      return 'มีโอกาสเป็นไปได้สูง';
    }
  };

  return (
    <SurveyFormLayout
      title={'มีแนวโน้มที่จะสำเร็จ'}
      subtitle={`เป้าหมายของฉัน
      “${smartGoal100Chars}”`}
      subtitlePosition={SUBTITLE_POSITION.TOP}
      navbarProps={{
        title: 'SMART Goal',
        currentStep: currentStep,
        onBackClick: goToPreviousPage,
        onMuteSound: handleMuteSound,
        onUnMuteSound: handleUnMuteSound,
        isMuted,
      }}
      imageBackground={<BackgroundStep4 />}
    >
      <Flex flexDirection={'column'} justifyContent={'space-between'} height={'100%'}>
        <Flex flexDirection={'column'} justifyContent={'center'} alignItems={'center'} mt={5}>
          <RippleBox
            message={`${measureChance}%`}
            flex={'0 0 auto'}
            textStyles={{ fontSize: '4xl' }}
          />
          <Text fontSize={'lg'} fontWeight={'bold'} mt={10}>
            {getChangeMessage()}
          </Text>
          <Box width={'100%'} px={6} mt={6}>
            <Slider
              aria-label="slider-ex-4"
              defaultValue={50}
              borderRadius={'10px'}
              value={measureChance}
              onChange={(value) => setMeasureChance(value)}
            >
              <SliderTrack bg={'gray.100'}>
                <SliderFilledTrack bg="transparent" />
              </SliderTrack>
              <SliderThumb boxSize={6} bg={'primary.500'} _focus={{ border: 'none ' }}>
                <Box color={'orange.500'} />
              </SliderThumb>
            </Slider>
          </Box>
        </Flex>

        <Button
          mt={6}
          colorScheme={'primary'}
          width={'100%'}
          borderRadius={'100px'}
          onClick={handleFormSubmit}
        >
          ถัดไป
        </Button>
      </Flex>
    </SurveyFormLayout>
  );
};

export default MeasurementChanceForm;
